export const clients = [
  [
    46,
    "KOUNOUZ TOURISM(E)",
    1,
    2,
    "KOUNOUZ TOURISM(E)",
    null,
    null,
    "21673221361",
    null,
    "MIRTS28AA",
    "rabi3006@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ TOURISM(E)",
    "KOUNOUZ TOURISM(E)",
    "",
    0,
    0,
    null,
    "APMIR (216)73221361 - KONOUZ TOURISM - A//E-BOOKING@BRAVO.T",
    6559.77,
    2768.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    47,
    "KOUNOUZ TOURISM B2C.",
    0,
    2,
    "KOUNOUZ TOURISM B2C.",
    null,
    null,
    "21673221361",
    null,
    "MIRTS313F;TUNTU0561;TUNSV0101;TUNTU0215;TUNTU024B;TUNTU025B",
    "",
    null,
    0,
    2,
    0,
    "KOUNOUZ TOURISM B2C.",
    "KOUNOUZ TOURISM B2C.",
    "",
    0,
    0,
    "KZ1861;KZ438",
    "APMIR (216)73221361 - KONOUZ TOURISM - A",
    1605690.68,
    174510.45,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    48,
    "MARWA VOYAGES",
    1,
    1,
    "MARWA VOYAGES",
    null,
    null,
    "50756682",
    "mohamed najjar",
    "MIRTS3116;MIRTS2109",
    "mohamednajjar108@gmail.com;Mohamed.hachfi@gmail.com",
    null,
    0,
    2,
    0,
    "MARWA VOYAGES",
    "MARWA VOYAGES",
    "",
    0,
    0,
    "KZ100",
    "APMIR (216)73611036 - MARWA VOYAGES - A//MIR (216)73611036 - MARWA VOYAGES - A",
    2042679.28,
    2037601.079,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    49,
    "CAP AFRICA VOYAGES",
    1,
    1,
    "CAP AFRICA VOYAGES",
    null,
    null,
    "2800079",
    "21673695580",
    "MIRTS3145",
    "contact@cap-africa.com;capafrica15@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "CAP AFRICA VOYAGES",
    "CAP AFRICA VOYAGES",
    "",
    0,
    0,
    "77",
    "AP2800079//MIR (216)73695580 - CAP AFRICA VOYAGES - A",
    890592.05,
    882968.833,
    0,
    0,
    17000.0,
    0,
    0,
  ],
  [
    51,
    "SELIM VOYAGES LITIGE",
    1,
    1,
    "SELIM VOYAGES LITIGE",
    null,
    null,
    "21673691444",
    null,
    "MIRTS3136",
    "",
    null,
    0,
    2,
    0,
    "SELIM VOYAGES LITIGE",
    "SELIM VOYAGES LITIGE",
    "",
    0,
    0,
    "63",
    "APMIR (216)73691444 - SELIM VOYAGES - A",
    0,
    27676.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    54,
    "WISSAL TRAVEL",
    1,
    1,
    "WISSAL TRAVEL",
    null,
    null,
    "54840126",
    "55464320 wissal",
    "MIRTS314G",
    "wissal.travelandevents@yahoo.com;mohamedaminekhammari@gmail.com ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "WISSAL TRAVEL",
    "WISSAL TRAVEL",
    "",
    0,
    0,
    "KZ464",
    "",
    100114.07,
    106812.5,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    55,
    "BHA TRAVEL",
    1,
    1,
    "BHA TRAVEL",
    null,
    null,
    "21673276502",
    null,
    "MIRTS313Y",
    "CONTACT@BHATRAVEL.COM.T",
    null,
    0,
    2,
    0,
    "BHA TRAVEL",
    "BHA TRAVEL",
    "",
    0,
    0,
    "KZ435",
    "APMIR (216)73276502 - BHA TRAVEL - A//E-CONTACT@BHATRAVEL.COM.T",
    138678.061,
    131317.079,
    0,
    0,
    7320.0,
    0,
    0,
  ],
  [
    56,
    "EMOTIO",
    1,
    1,
    "EMOTIO",
    null,
    null,
    null,
    null,
    "TUNTS319X",
    "neila.bensalem@emotiontravel.com.tn;bsn2009@hotmail.fr",
    null,
    0,
    2,
    0,
    "EMOTIO",
    "EMOTIO",
    "",
    0,
    0,
    "emo018",
    "",
    22932.0,
    21000.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    57,
    "ALL IN TRAVEL TUNISIA ",
    1,
    1,
    "ALL IN TRAVEL TUNISIA",
    null,
    null,
    "55183534",
    "21671444240",
    "TUNTS31AM",
    "contact@allin.t",
    null,
    0,
    2,
    0,
    "ALL IN TRAVEL TUNISIA ",
    "ALL IN TRAVEL TUNISIA ",
    "",
    0,
    0,
    "KZ1857",
    "APTUN (216)71444240 - ALL IN TRAVEL TUNISIA - A",
    319895.5,
    350565.6,
    0,
    0,
    51920.0,
    0,
    0,
  ],
  [
    59,
    "GABOUDIA VOYAGES LITIGE",
    1,
    1,
    "GABOUDIA VOYAGES LITIGE",
    null,
    null,
    "21698277528",
    null,
    "SFATS3109",
    "gaboudia.voyage@gmail.com;mohamedaminekhammari@gmail.com ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "GABOUDIA VOYAGES LITIGE",
    "GABOUDIA VOYAGES LITIGE",
    "",
    0,
    0,
    "KZ454",
    "APSFA 216 98277528 - GABOUDIA VOYAGES - A//23317755",
    613431.841,
    610941.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    65,
    "MIMA TRAVEL & EVENTS",
    1,
    1,
    "MIMA TRAVEL & EVENTS",
    "1152893Z",
    null,
    "53101978 akram",
    "21673502333",
    "MIRTS313U",
    "mima.tn@topnet.t",
    null,
    0,
    2,
    0,
    "MIMA TRAVEL & EVENTS",
    "MIMA TRAVEL & EVENTS",
    "",
    0,
    0,
    "KZ461",
    "APMIR (216)73502333 - MIMA TRAVEL & EVENTS - A//29478423",
    192722.013,
    200779.0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    68,
    "MIR TRAVEL TUNISIE  ",
    1,
    1,
    "MIR TRAVEL TUNISIE",
    null,
    null,
    null,
    null,
    "PARA1222F;PARMM210L;MIRTS313S;PARA12102;PARA13403",
    "reservation@mir-travel.tn;marketing@mir-travel.t",
    null,
    0,
    2,
    0,
    "MIR TRAVEL TUNISIE  ",
    "MIR TRAVEL TUNISIE  ",
    "",
    0,
    0,
    "KZ635",
    "",
    99367.29,
    93181.71,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    70,
    "ASSIYAHI FOR TOURISM",
    1,
    2,
    "ASSIYAHI FOR TOURISM",
    null,
    null,
    null,
    null,
    "TIPLY2150",
    "mohamedaminekhammari@gmail.com;y.oueslati@assafinah-tourism.com",
    null,
    0,
    2,
    0,
    "ASSIYAHI FOR TOURISM",
    "ASSIYAHI FOR TOURISM",
    "",
    0,
    0,
    "ASSIYAHI018",
    "",
    820.0,
    820.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    78,
    "TAWBA VOYAGES",
    1,
    1,
    "TAWBA VOYAGES",
    null,
    null,
    "21673693557",
    null,
    "MIRTS311R",
    "limamhamadi1950@hotmail.fr;tawbarached@gmail.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "TAWBA VOYAGES",
    "TAWBA VOYAGES",
    "",
    0,
    0,
    "TAWBA018",
    "APMIR (216)73693557 - TAWBA VOYAGES - A",
    11557.0,
    15416.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    82,
    "RAHMA VOYAGES",
    1,
    1,
    "RAHMA VOYAGES",
    null,
    null,
    "21672224092",
    null,
    "TUNTS312A;TUNTS3128;TUNTS3100",
    "rahma-voyages@gnet.tn ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "RAHMA VOYAGES",
    "RAHMA VOYAGES",
    "",
    0,
    0,
    "KZ189",
    "APTUN (216)72224092 - RAHMA VOYAGES - A//23 844 693",
    196698.534,
    190035.5,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    84,
    "NASSAMAT EL MADINA",
    1,
    2,
    "NASSAMAT EL MADINA",
    null,
    null,
    "21673200295",
    null,
    "MIRTS3115",
    "nassamet.elmadina@gmail.com;finance@ekounouz.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "NASSAMAT EL MADINA",
    "NASSAMAT EL MADINA",
    "",
    0,
    0,
    "KZ436",
    "APMIR (216)73200295 - NASSAMAT EL MADINA - A",
    42507.0,
    38909.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    91,
    "M TRAVEL",
    1,
    1,
    "M TRAVEL",
    null,
    null,
    "53977803 mohamed",
    "21671960301",
    "MIRTS3120;QSOTU0201",
    "reservation@m-travel.tn;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "M TRAVEL",
    "M TRAVEL",
    "",
    0,
    0,
    "KZ434",
    "APTUN (216)71960301 - M H TRAVEL - A",
    1018129.335,
    1015863.748,
    0,
    0,
    25000.0,
    0,
    0,
  ],
  [
    93,
    "KRESTAL FOR TOURISM",
    1,
    0,
    "KRESTAL FOR TOURISM",
    null,
    null,
    null,
    null,
    "TIPLY2677;TIPLY2137;TIPLY2138;TIPLY2850;TIPLY2780;CAIEG23I8;TIPLY2668;TIPLY2477",
    "KRESTALTRAVEL@GMAIL.COM;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "KRESTAL FOR TOURISM",
    "KRESTAL FOR TOURISM",
    "",
    0,
    0,
    "KRES100",
    "APTIP +218/0213335070-0913455517 - KRESTAL FOR TOURISM AND  TOURS - A//E-KRESTALTRAVEL@GMAIL.COM",
    1606836.93,
    1573744.8,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    95,
    "THAPSUS VOYAGES",
    1,
    1,
    "THAPSUS VOYAGES",
    null,
    null,
    "98366800 marwa",
    null,
    "MIRTS312C",
    "commercial@thapsusvoyages.com;financier@thapsusvoyages.com ;tourisme@thapsusvoyages.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "THAPSUS VOYAGES",
    "THAPSUS VOYAGES",
    "",
    0,
    0,
    "KZ468",
    "",
    215234.76,
    221324.56,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    105,
    "NEWSUN TRAVEL",
    1,
    1,
    "NEWSUN TRAVEL",
    null,
    null,
    "21673275940",
    null,
    "MIRTS3131",
    "",
    null,
    0,
    2,
    0,
    "NEWSUN TRAVEL",
    "NEWSUN TRAVEL",
    "",
    0,
    0,
    "kz456",
    "APMIR (216)73275940 - TUNISIAN NEWSUN TRAVEL - A",
    6535.0,
    7007.1,
    0,
    0,
    3000.0,
    0,
    0,
  ],
  [
    108,
    "CARAMEL TRAVEL MEDI TRAVEL",
    1,
    1,
    "CARAMEL TRAVEL MEDI TRAVEL",
    null,
    null,
    "21673202750",
    null,
    "MIRTS310P",
    "aminedardour2008@yahoo.fr;info.carameltravel@gmail.com;mohamedaminekhammari@gmail.com;carameltravelservice@gmail.com,comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "CARAMEL TRAVEL MEDI TRAVEL",
    "CARAMEL TRAVEL MEDI TRAVEL",
    "",
    0,
    0,
    "KZ430",
    "APMIR (216)73202750 - CARAMEL TRAVEL - A",
    86120.34,
    81747.31,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    132,
    "YARA TRAVEL AND SERVICES ",
    1,
    0,
    "YARA TRAVEL AND SERVICES",
    null,
    null,
    "73246060",
    null,
    "MIRTS311S",
    "compta@yaratravel.tn;jamila.gaaid@gmail.com ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "YARA TRAVEL AND SERVICES ",
    "YARA TRAVEL AND SERVICES ",
    "",
    0,
    0,
    "KZ439",
    "APMIR 73246060 - YARA TRAVEL AND SERVICES - A",
    427287.83,
    428754.7,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    133,
    "JEDS22254|ALAHRAM TRAVEL & TOURISM AGENCY",
    1,
    0,
    "ALAHRAM TRAVEL & TOURISM AGENCY",
    "",
    "",
    "0126681213",
    "",
    "JEDS22254",
    "alahram-agency@gnet.t",
    "",
    0,
    2,
    0,
    "ALAHRAM TRAVEL & TOURISM AGENCY",
    "ALAHRAM TRAVEL & TOURISM AGENCY",
    "",
    0,
    0,
    "ALAHRAM018",
    "APJED 012 6681213 - ALAHRAM TRAVEL & TOURISM AGENCY - A",
    1.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    137,
    "MAJDI GUEZLA",
    1,
    1,
    "MAJDI GUEZLA",
    null,
    null,
    "58543660 majdi",
    null,
    "TIPLY2646;BENLY2852;TIPLY2547;TIPLY2611;TIPLY2403;TIPLY2216;BENLY2208;TIPLY2468;TIPLY2608;TUNTS319C;QZTLY2144;TIPLY2494;TIPLY2836;TIPLY2237;DJETS3105;TIPLY2556;TIPLY2159;TIPLY2449;QZTLY2114;QZTLY2223;QZTLY2245;QZTLY2424;TIPLY2198;TIPLY2114;BENLY2684;TIPLY2819;TIPLY2204;;TIPLY2443;TIPLY2235;tunts319c;TIPLY2671;QZTLY2525;PARTU08MB;TIPLY2553;TIPLY2447;TUNTS310L;TIPLY2337;MRALY2580;TUNTU08MB;TIPLY2239;MIRTS3104;QZTLY2178 ;TIPLY2122 ;TIPLY2122;TIPLY25A3;TIPLY2122  ;QZTLY2424 ;TIPLY2652;QZTLY25X2;QZTLY25L7;TOBLY2530;BENLY2381;QZTLY2178;QZTLY26B7;QZTLY25A9;TUNTS310S;TUNTS31A2;TIPLY2714;QZTLY26E4;TIPLY25K8",
    "ALKHBAIMAJDI@GMAIL.COM;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "MAJDI GUEZLA",
    "MAJDI GUEZLA",
    "",
    0,
    0,
    "45",
    "",
    4548015.467,
    3958049.805,
    0,
    0,
    80000.0,
    0,
    0,
  ],
  [
    141,
    "ENFIDHA VOYAGES",
    1,
    2,
    "ENFIDHA VOYAGES",
    null,
    null,
    null,
    null,
    "MIRTS3111",
    "commercialenfidhavoyages@gmail.com ;info@ekounouz.com;univers.eventstunisie@gmail.com",
    null,
    0,
    2,
    0,
    "ENFIDHA VOYAGES",
    "ENFIDHA VOYAGES",
    "",
    0,
    0,
    "ENFIDHA018",
    "",
    0,
    350.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    143,
    "TUNISIE LOISIRS",
    1,
    2,
    "TUNISIE LOISIRS",
    null,
    null,
    "21673200500",
    null,
    "MIRTS312",
    "tunisie-loisirs@gnet.t",
    null,
    0,
    2,
    0,
    "TUNISIE LOISIRS",
    "TUNISIE LOISIRS",
    "",
    0,
    0,
    "LOISIRS018",
    "APMIR (216)73200500 - TUNISIE LOISIRS - A",
    4093.5,
    4091.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    145,
    "BAWABET ALJABAL",
    1,
    2,
    "BAWABET ALJABAL",
    null,
    null,
    null,
    null,
    "TIPLY2555",
    "bawabet-aljabal@topnet.t",
    null,
    0,
    2,
    0,
    "BAWABET ALJABAL",
    "BAWABET ALJABAL",
    "",
    0,
    0,
    "BAWABET018",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    147,
    "MIRTS3141|ELYSEE VOYAGES",
    1,
    1,
    "ELYSEE VOYAGES",
    "",
    "",
    "21673476565",
    "",
    "MIRTS3141",
    "ELYSEEVOYAGES2019@GMAIL.COM;mohamedaminekhammari@gmail.com;info@elyseevoyages.t",
    "",
    0,
    2,
    0,
    "ELYSEE VOYAGES",
    "ELYSEE VOYAGES",
    "",
    0,
    0,
    "KZ460;KZ437",
    "APMIR (216)73476565 - ELYSEE VOYAGES - A",
    168625.99,
    176904.14,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    148,
    "WORLD SAFARI TRAVEL ",
    1,
    1,
    "WORLD SAFARI TRAVEL",
    null,
    null,
    "21673366383",
    null,
    "MIRTS312Q;GAFTS2102;TIPLY2384;TUNTS3181;TIPLY2450;tiply2293;OKQ5PD;MIRTS314M;TIPLY25B9",
    "",
    null,
    0,
    2,
    0,
    "WORLD SAFARI TRAVEL ",
    "WORLD SAFARI TRAVEL ",
    "",
    0,
    0,
    "SAFARI018;kzwordsafari",
    "APMIR +216 73 366 383 - WORLD SAFARI TRAVEL - A",
    1591750.2,
    1596252.1,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    149,
    "AICHA TRAVEL ",
    1,
    1,
    "AICHA TRAVEL",
    null,
    null,
    null,
    null,
    "KZ462",
    "aichavoyage@gmail.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "AICHA TRAVEL ",
    "AICHA TRAVEL ",
    "",
    0,
    0,
    "KZ462",
    "",
    23747.49,
    28437.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    150,
    "KOUNOUZ BIZERTE",
    1,
    1,
    "KOUNOUZ BIZERTE",
    null,
    null,
    null,
    null,
    "78",
    "kounouzbizerte@gmail.com;kounouz.sonia@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ BIZERTE",
    "KOUNOUZ BIZERTE",
    "",
    0,
    0,
    "KZ634",
    "",
    935186.28,
    827728.085,
    0,
    0,
    25000.0,
    0,
    0,
  ],
  [
    151,
    "ATTIA TRAVEL",
    1,
    1,
    "ATTIA TRAVEL",
    null,
    null,
    "98257275 radhia attia directrice",
    null,
    "KZ480;KZ4801",
    "radhia-attia@hotmail.fr;resa@attiatravel.com.tn;direction@attiatravel.com.tn;compta@attiatravel.com.tn;contact@attiatravel.com.t",
    null,
    0,
    2,
    0,
    "ATTIA TRAVEL",
    "ATTIA TRAVEL",
    "",
    0,
    0,
    "KZ480;KZ4801",
    "",
    1238740.939,
    1243671.244,
    0,
    0,
    40000.0,
    0,
    0,
  ],
  [
    152,
    "BEST TIME TRAVEL",
    1,
    1,
    "BEST TIME TRAVEL",
    null,
    null,
    "58401008 mourad directeur ",
    "58401007",
    "KZ432;MIRTS312I",
    "acc@btt.tn;acc-pay@btt.tn;info@btt.tn ;control@btt.tn;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "BEST TIME TRAVEL",
    "BEST TIME TRAVEL",
    "",
    0,
    0,
    "KZ432",
    "",
    312054.444,
    324802.91,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    154,
    "BRAVO TOURS",
    1,
    1,
    "BRAVO TOURS",
    null,
    null,
    "58390 398 wafa agent ",
    null,
    "KZ412",
    "",
    null,
    0,
    2,
    0,
    "BRAVO TOURS",
    "BRAVO TOURS",
    "",
    0,
    0,
    "KZ412",
    "",
    19458.62,
    22124.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    156,
    "CIGOGNES VOYAGES LITIGE",
    1,
    1,
    "CIGOGNES VOYAGES LITIGE",
    null,
    null,
    "54104829 maryem directrice",
    null,
    "54",
    "",
    null,
    0,
    2,
    0,
    "CIGOGNES VOYAGES LITIGE",
    "CIGOGNES VOYAGES LITIGE",
    "",
    0,
    0,
    "54",
    "",
    16846.24,
    24591.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    157,
    "DHIYAFA TOURISM ",
    1,
    1,
    "DHIYAFA TOURISM",
    null,
    null,
    null,
    null,
    "KZ1802",
    "info@dheyafa.com;comptabilite.dheyafa@gmail.com",
    null,
    0,
    2,
    0,
    "DHIYAFA TOURISM ",
    "DHIYAFA TOURISM ",
    "",
    0,
    0,
    "KZ1802",
    "",
    662816.83,
    668813.87,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    158,
    "DIRECTIONS TUNIS ",
    1,
    2,
    "DIRECTIONS TUNIS",
    null,
    null,
    "24 899100 ahmed laroussi",
    null,
    "KZ419",
    "emnahomri@gmail.com;laroussiahmed@nahdha.tn ",
    null,
    0,
    2,
    0,
    "DIRECTIONS TUNIS ",
    "DIRECTIONS TUNIS ",
    "",
    0,
    0,
    "KZ419",
    "",
    176821.435,
    187667.74,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    160,
    "HADRAMEUTE",
    1,
    1,
    "HADRAMEUTE",
    null,
    null,
    null,
    null,
    "KZ450",
    "hadrumete.travel@planet.tn ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "HADRAMEUTE",
    "HADRAMEUTE",
    "",
    0,
    0,
    "KZ450",
    "",
    27630.26,
    29800.0,
    0,
    0,
    3000.0,
    0,
    0,
  ],
  [
    161,
    "HAPPYNES TRAVEL LITIGE",
    1,
    1,
    "HAPPYNES TRAVEL LITIGE",
    null,
    null,
    null,
    null,
    "KZ448",
    "happynes-travel@topnet.t",
    null,
    0,
    2,
    0,
    "HAPPYNES TRAVEL LITIGE",
    "HAPPYNES TRAVEL LITIGE",
    "",
    0,
    0,
    "KZ448",
    "",
    0,
    1836.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    162,
    "HOUR VOYAGES",
    1,
    1,
    "HOUR VOYAGES",
    null,
    null,
    "50469591 khmayes gerant",
    null,
    "KZ410",
    "cabinet.kba@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "HOUR VOYAGES",
    "HOUR VOYAGES",
    "",
    0,
    0,
    "KZ410",
    "",
    31434.1,
    35041.85,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    163,
    "INDAFRI",
    1,
    1,
    "INDAFRI",
    null,
    null,
    "98104444 issam gérant",
    null,
    "KZ443",
    "contact@indafri.com ,fly@indafri.com;mohamedaminekhammari@gmail.com ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "INDAFRI",
    "INDAFRI",
    "",
    0,
    0,
    "KZ443",
    "",
    255758.53,
    250193.425,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    164,
    "INDIGO ",
    1,
    1,
    "INDIGO",
    null,
    null,
    null,
    null,
    "KZ421",
    "",
    null,
    0,
    2,
    0,
    "INDIGO ",
    "INDIGO ",
    "",
    0,
    0,
    "KZ421",
    "",
    77621.42,
    78495.8,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    166,
    "KOUNOUZ TUNIS B2C.",
    0,
    2,
    "KOUNOUZ TUNIS B2C.",
    null,
    null,
    null,
    null,
    "kz900;TIPLY2115",
    "",
    null,
    0,
    2,
    0,
    "KOUNOUZ TUNIS B2C.",
    "KOUNOUZ TUNIS B2C.",
    "",
    0,
    0,
    "kz900",
    "",
    1247216.27,
    41200.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    167,
    "KOUNOUZ SFAX B2C",
    0,
    1,
    "KOUNOUZ SFAX B2C",
    null,
    null,
    null,
    null,
    "48",
    "kounouz.sfax@gmail.com;amel.kounouz1@gmail.com;Mohamed.hachfi@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ SFAX B2C",
    "KOUNOUZ SFAX B2C",
    "",
    0,
    0,
    "48",
    "",
    161248.27,
    93768.59,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    168,
    "KOUNOUZ GABES",
    1,
    1,
    "KOUNOUZ GABES",
    null,
    null,
    null,
    null,
    "KZ440",
    "GABES@EKOUNOUZ.COM;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ GABES",
    "KOUNOUZ GABES",
    "",
    0,
    0,
    "KZ440",
    "",
    204249.31,
    208907.833,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    169,
    "KOUNOUZ MONASTIR B2C.",
    0,
    2,
    "KOUNOUZ MONASTIR B2C.",
    null,
    null,
    null,
    null,
    "KZ446",
    "monastir@ekounouz.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ MONASTIR B2C.",
    "KOUNOUZ MONASTIR B2C.",
    "",
    0,
    0,
    "KZ446",
    "",
    344004.78,
    20772.1,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    170,
    "LA VAGUE VOYAGES",
    1,
    1,
    "LA VAGUE VOYAGES",
    null,
    null,
    null,
    null,
    "kzvague",
    "LA.VAGUE.VOYAGE@GMAIL.COM;mohamedaminekhammari@gmail.com ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "LA VAGUE VOYAGES",
    "LA VAGUE VOYAGES",
    "",
    0,
    0,
    "kzvague",
    "",
    106349.22,
    100125.63,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    171,
    "LAYANA",
    1,
    1,
    "LAYANA",
    null,
    null,
    null,
    null,
    "KZ616",
    "layana-layana@topnet.tn;contact@layana-tours.tn;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "LAYANA",
    "LAYANA",
    "",
    0,
    0,
    "KZ616",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    172,
    "LINK TRAVEL",
    1,
    1,
    "LINK TRAVEL",
    null,
    null,
    null,
    null,
    "KZ442",
    "",
    null,
    0,
    2,
    0,
    "LINK TRAVEL",
    "LINK TRAVEL",
    "",
    0,
    0,
    "KZ442",
    "",
    21399.13,
    23620.72,
    0,
    0,
    2000.0,
    0,
    0,
  ],
  [
    173,
    "LUCKY TOURS",
    1,
    1,
    "LUCKY TOURS",
    null,
    null,
    "24115610 karim jaziri gérant",
    null,
    "kz408",
    "",
    null,
    0,
    2,
    0,
    "LUCKY TOURS",
    "LUCKY TOURS",
    "",
    0,
    0,
    "KZ408",
    "",
    33347.45,
    33311.96,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    174,
    "MAHDIA VOYAGES",
    1,
    1,
    "MAHDIA VOYAGES",
    null,
    null,
    "24241717 essia chraga ",
    null,
    "MIRTS210F;SFATU0201;MIRTS311A;QSOTS310J;QSOTS310J ",
    "mahdia.voyages@yahoo.fr;mohamedaminekhammari@gmail.com ;elyessbakkar22@gmail.com",
    null,
    0,
    2,
    0,
    "MAHDIA VOYAGES",
    "MAHDIA VOYAGES",
    "",
    0,
    0,
    "64",
    "",
    2018398.104,
    2017242.01,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    176,
    "MANARA TRAVEL",
    1,
    1,
    "MANARA TRAVEL",
    null,
    null,
    "97331740 yassin gérant",
    null,
    "KZ433",
    "booking.mte@gnet.tn;mohamedaminekhammari@gmail.com ;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "MANARA TRAVEL",
    "MANARA TRAVEL",
    "",
    0,
    0,
    "KZ469",
    "",
    74844.9,
    78109.373,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    178,
    "MOUVEMENT NAHDA",
    1,
    2,
    "MOUVEMENT NAHDA",
    null,
    null,
    null,
    null,
    "KZ418",
    "laroussiahmed@nahdha.tn ",
    null,
    0,
    2,
    0,
    "MOUVEMENT NAHDA",
    "MOUVEMENT NAHDA",
    "",
    0,
    0,
    "KZ418",
    "",
    0,
    -8513.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    181,
    "PLAZA TRAVEL",
    1,
    1,
    "PLAZA TRAVEL",
    null,
    null,
    "94811599 soumaya ",
    null,
    "kz459",
    "CONTACT@PLAZATRAVEL.COM.TN;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "PLAZA TRAVEL",
    "PLAZA TRAVEL",
    "",
    0,
    0,
    "KZ459",
    "",
    192342.76,
    202370.29,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    182,
    "PREMIERE  CLASSE",
    1,
    1,
    "PREMIERE  CLASSE",
    null,
    null,
    "28200195 mondher ",
    null,
    "61",
    "CLASSE1@TOPNET.TN;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "PREMIERE  CLASSE",
    "PREMIERE  CLASSE",
    "",
    0,
    0,
    "61",
    "",
    23887.45,
    22700.09,
    0,
    0,
    3000.0,
    0,
    0,
  ],
  [
    183,
    "RAHALET EVENTS",
    1,
    1,
    "RAHALET EVENTS",
    null,
    null,
    "96 672081 wahib ",
    null,
    "kz457",
    "WAHIB.BELAID@OUTLOOK.FR;mohamedaminekhammari@gmail.com;resa.rahaletandevents@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "RAHALET EVENTS",
    "RAHALET EVENTS",
    "",
    0,
    0,
    "kz457",
    "",
    22172.2,
    23305.0,
    0,
    0,
    3000.0,
    0,
    0,
  ],
  [
    184,
    "SAFE TOURS",
    1,
    1,
    "SAFE TOURS",
    null,
    null,
    "22389042 med gassouma",
    null,
    "KZ444",
    "safetours.direction@gmail.com",
    null,
    0,
    2,
    0,
    "SAFE TOURS",
    "SAFE TOURS",
    "",
    0,
    0,
    "KZ444",
    "",
    213485.05,
    209936.93,
    0,
    0,
    4000.0,
    0,
    0,
  ],
  [
    185,
    "SAVA TRAVEL",
    1,
    1,
    "SAVA TRAVEL",
    null,
    null,
    null,
    null,
    "SFATS310Y ",
    "mohamedaminekhammari@gmail.com;savatraveltunisia1@gmail.com ",
    null,
    0,
    2,
    0,
    "SAVA TRAVEL",
    "SAVA TRAVEL",
    "",
    0,
    0,
    "KZ449",
    "",
    120799.1,
    133792.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    186,
    "TASNIM",
    1,
    1,
    "TASNIM",
    null,
    null,
    "54062873 walid",
    null,
    "TUNTS31DM;MIRTS315H;TUNTS31EJ;GAFTS3100",
    "web.tasnimevoyages@gmail.com;mohamedaminekhammari@gmail.com  ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "TASNIM",
    "TASNIM",
    "",
    0,
    0,
    "KZ501",
    "",
    515239.92,
    480633.12,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    187,
    "UNIVERS EVENTS",
    1,
    1,
    "UNIVERS EVENTS",
    null,
    null,
    null,
    null,
    "kz1801",
    "ezzeddinjdir@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "UNIVERS EVENTS",
    "UNIVERS EVENTS",
    "",
    0,
    0,
    "kz1801",
    "",
    8180.56,
    10347.35,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    188,
    "WEB TRAVEL",
    1,
    1,
    "WEB TRAVEL",
    null,
    null,
    "98603683 sami",
    null,
    "87;MIRTS313D",
    "webtravel.tn@gmail.com;mohamedaminekhammari@gmail.com  ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "WEB TRAVEL",
    "WEB TRAVEL",
    "",
    0,
    0,
    "87",
    "",
    543186.228,
    531564.43,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    192,
    "DIWEN TRAVEL",
    1,
    0,
    "DIWEN TRAVEL",
    "",
    "",
    "",
    "",
    "SFATS310J",
    "reservation@diwantravels.com;mohamedaminekhammari@gmail.com",
    "",
    0,
    2,
    0,
    "DIWEN TRAVEL",
    "DIWEN TRAVEL",
    "",
    0,
    0,
    "KZ1850",
    "",
    1099.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    193,
    "EL OUNI VOYAGE",
    1,
    1,
    "EL OUNI VOYAGE",
    null,
    null,
    null,
    null,
    "KZ1851",
    "elouni.voyages@gmail.com",
    null,
    0,
    2,
    0,
    "EL OUNI VOYAGE",
    "EL OUNI VOYAGE",
    "",
    0,
    0,
    "KZ1851",
    "",
    1107.62,
    1102.62,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    194,
    "EVEC TOUR",
    1,
    1,
    "EVEC TOUR",
    null,
    null,
    null,
    null,
    "KZ1852",
    "evec-tour@topnet.t",
    null,
    0,
    2,
    0,
    "EVEC TOUR",
    "EVEC TOUR",
    "",
    0,
    0,
    "KZ1852",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    195,
    "HAMDI JDIDI",
    1,
    1,
    "HAMDI JDIDI",
    null,
    null,
    null,
    null,
    "KZ1853",
    "hamdi-jdidi@topnet.t",
    null,
    0,
    2,
    0,
    "HAMDI JDIDI",
    "HAMDI JDIDI",
    "",
    0,
    0,
    "KZ1853",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    196,
    "MAGHREB TRAVEL SERVICES",
    1,
    1,
    "MAGHREB TRAVEL SERVICES",
    null,
    null,
    null,
    null,
    "KZ1854;KZ445",
    "dir.maghrebtravelservices@topnet.t",
    null,
    0,
    2,
    0,
    "MAGHREB TRAVEL SERVICES",
    "MAGHREB TRAVEL SERVICES",
    "",
    0,
    0,
    "KZ1854",
    "",
    2015.48,
    11773.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    197,
    "NOR AFRIC",
    1,
    2,
    "NOR AFRIC",
    "",
    "",
    "",
    "",
    "KZ1855",
    "nor-afric@topnet.t",
    "",
    0,
    2,
    0,
    "NOR AFRIC",
    "NOR AFRIC",
    "",
    0,
    0,
    "KZ1855",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    198,
    "CORNICHE TRAVEL",
    1,
    1,
    "CORNICHE TRAVEL",
    null,
    null,
    "21725322",
    "ahmed azzouz ",
    "KZ5056",
    "resa.cornichetravel@cts.t",
    null,
    0,
    2,
    0,
    "CORNICHE TRAVEL",
    "CORNICHE TRAVEL",
    "",
    0,
    0,
    "KZ482",
    "",
    40592.54,
    40758.09,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    201,
    "JAWHARA TRAVEL",
    1,
    0,
    "JAWHARA TRAVEL",
    "",
    "",
    "",
    "",
    "KZ1859",
    "jawhara-travel@topnet.t",
    "",
    0,
    2,
    0,
    "JAWHARA TRAVEL",
    "JAWHARA TRAVEL",
    "",
    0,
    0,
    "KZ1859",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    203,
    "SELYANOS VOYAGES",
    1,
    1,
    "SELYANOS VOYAGES",
    null,
    null,
    null,
    null,
    "KZ1862",
    "silianos78@gmail.com ",
    null,
    0,
    2,
    0,
    "SELYANOS VOYAGES",
    "SELYANOS VOYAGES",
    "",
    0,
    0,
    "KZ1862",
    "",
    0,
    1030.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    204,
    "INSTANT VOYAGE",
    1,
    1,
    "INSTANT VOYAGE",
    null,
    null,
    null,
    null,
    "kz1803",
    "info@ekounouz.com;l.instantvoyage@gmail.com    ",
    null,
    0,
    2,
    0,
    "INSTANT VOYAGE",
    "INSTANT VOYAGE",
    "",
    0,
    0,
    "KZ1803",
    "",
    272264.37,
    292385.55,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    206,
    "ECONOMIE TRAVEL LITIGE",
    1,
    1,
    "ECONOMIE TRAVEL LITIGE",
    null,
    null,
    "53503646",
    null,
    "KZ1866;kz479",
    "",
    null,
    0,
    2,
    0,
    "ECONOMIE TRAVEL LITIGE",
    "ECONOMIE TRAVEL LITIGE",
    "",
    0,
    0,
    "kz479",
    "",
    50349.42,
    48198.94,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    207,
    "STV",
    1,
    0,
    "STV",
    null,
    null,
    "71904707",
    null,
    "KZ1867;TUNTS316S;MIRTS311W;TUNTS31BX",
    "compta.kh@st-tunisia.com",
    "71904743",
    0,
    2,
    0,
    "STV",
    "STV",
    "",
    0,
    0,
    "KZ1810",
    "",
    173032.76,
    164775.26,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    210,
    "JAZIRA TRAVEL ",
    1,
    1,
    "JAZIRA TRAVEL",
    null,
    "SOUSSE",
    null,
    null,
    "KZ1870",
    "fekihdh@kergmail.com",
    null,
    0,
    2,
    0,
    "JAZIRA TRAVEL ",
    "JAZIRA TRAVEL ",
    "",
    0,
    0,
    "KZ481",
    "",
    10985.2,
    13946.9,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    212,
    "PERSONNEL NAHDHA ",
    1,
    2,
    "PERSONNEL NAHDHA",
    null,
    null,
    null,
    null,
    "KZ1890",
    "laroussiahmed@nahdha.tn ",
    null,
    0,
    2,
    0,
    "PERSONNEL NAHDHA ",
    "PERSONNEL NAHDHA ",
    "",
    0,
    0,
    "PERSONNEL018",
    "",
    27772.35,
    30294.54,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    213,
    "NINA TRAVEL",
    1,
    1,
    "NINA TRAVEL",
    null,
    null,
    "23992392 hana ",
    "21671962111",
    "TUNTS311M",
    "ninaatravel@gmail.com",
    null,
    0,
    2,
    0,
    "NINA TRAVEL",
    "NINA TRAVEL",
    "",
    0,
    0,
    "KZ467",
    "APTUN (216)71962111 - NINA TRAVEL - A",
    330077.07,
    328983.04,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    223,
    "TAKWA VOYAGES ",
    1,
    1,
    "TAKWA VOYAGES",
    null,
    null,
    "21673482211",
    null,
    "MIRTS313J",
    "TAKWA.VOYAGES@GMAIL.COM;takwa.voyages1@gmail.com ",
    null,
    0,
    2,
    0,
    "TAKWA VOYAGES ",
    "TAKWA VOYAGES ",
    "",
    0,
    0,
    "KZ1820",
    "APMIR (216)73482211 - TAKWA VOYAGES - A//E-TAKWA.VOYAGES@GMAIL.COM",
    453771.78,
    501088.937,
    0,
    0,
    60000.0,
    0,
    0,
  ],
  [
    374,
    "KOUNOUZ GHOMRASE",
    1,
    1,
    "KOUNOUZ GHOMRASE",
    null,
    null,
    null,
    null,
    "KZ1899",
    "ALJAN_MAHMOUD@HOTMAIL.COM",
    null,
    0,
    2,
    0,
    "KOUNOUZ GHOMRASE",
    "KOUNOUZ GHOMRASE",
    "",
    0,
    0,
    "KZ451",
    "",
    970.26,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    375,
    "AMIR BOUMERDES",
    1,
    2,
    "AMIR BOUMERDES",
    null,
    null,
    null,
    null,
    "KZ1888",
    "info@ekounouz.com",
    null,
    0,
    2,
    0,
    "AMIR BOUMERDES",
    "AMIR BOUMERDES",
    "",
    0,
    0,
    "KZ1888",
    "",
    8852.5,
    8330.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    388,
    "TRUST INCENTIVE HOUSE ",
    1,
    1,
    "TRUST INCENTIVE HOUSE",
    null,
    null,
    "20402398 noomen ",
    null,
    "KZ458",
    "RESA@TRUST.TN;finance@trust.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "TRUST INCENTIVE HOUSE ",
    "TRUST INCENTIVE HOUSE ",
    "",
    0,
    0,
    "KZ458",
    "",
    192022.11,
    201612.04,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    455,
    "TDS DJERBA",
    1,
    1,
    "TDS DJERBA",
    null,
    null,
    null,
    null,
    "KZ1806",
    "billetterie@ekounouz.com;BOOKING@ALLINVOYAGE.COM",
    null,
    0,
    2,
    0,
    "TDS DJERBA",
    "TDS DJERBA",
    "",
    0,
    0,
    "dts01",
    "",
    4167.0,
    4041.8,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    458,
    "BABYLONE",
    1,
    1,
    "BABYLONE",
    null,
    null,
    null,
    null,
    "KZ1833",
    "dg@babylonevents-tn.com",
    null,
    0,
    2,
    0,
    "BABYLONE",
    "BABYLONE",
    "",
    0,
    0,
    "KZ1833",
    "",
    15181.08,
    15142.419,
    0,
    0,
    3000.0,
    0,
    0,
  ],
  [
    462,
    "SERVICE GROUPES B2C.",
    0,
    0,
    "SERVICE GROUPES B2C.",
    null,
    null,
    null,
    null,
    "TUNTU0302",
    "",
    null,
    0,
    2,
    0,
    "SERVICE GROUPES B2C.",
    "SERVICE GROUPES B2C.",
    "",
    0,
    0,
    null,
    "APTUN TBA - SERVICE GROUPES - A",
    -20304.0,
    556.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    479,
    "KHEZAMA TRAVEL LITIGE",
    1,
    1,
    "KHEZAMA TRAVEL LITIGE",
    null,
    null,
    null,
    null,
    "KZ636",
    "",
    null,
    0,
    2,
    0,
    "KHEZAMA TRAVEL LITIGE",
    "KHEZAMA TRAVEL LITIGE",
    "",
    0,
    0,
    "KZ636",
    "",
    69700.8,
    70286.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    488,
    "KOUNOUZ ARIANA",
    1,
    1,
    "KOUNOUZ ARIANA",
    null,
    null,
    null,
    null,
    "KZ1822",
    "ariana@ekounouz.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ ARIANA",
    "KOUNOUZ ARIANA",
    "",
    0,
    0,
    "kz1822",
    "",
    641960.68,
    637604.71,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    490,
    "RACHED KERRIJI",
    1,
    2,
    "RACHED KERRIJI",
    null,
    null,
    null,
    null,
    "KZ2011",
    "laroussiahmed@nahdha.tn ",
    null,
    0,
    2,
    0,
    "RACHED KERRIJI",
    "RACHED KERRIJI",
    "",
    0,
    0,
    "RACHED018",
    "",
    0,
    4850.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    491,
    "ALBATROS ",
    1,
    1,
    "ALBATROS",
    null,
    null,
    null,
    null,
    "KZ2121",
    "contact@albatrostravel.tn;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "ALBATROS ",
    "ALBATROS ",
    "",
    0,
    0,
    "ALBATROS018",
    "",
    1233.5,
    1035.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    494,
    "PIVOINE TRAVEL",
    1,
    1,
    "PIVOINE TRAVEL",
    null,
    null,
    null,
    null,
    "KZ5482",
    "pivoine-travel@topnet.t",
    null,
    -319.0,
    2,
    0,
    "PIVOINE TRAVEL",
    "PIVOINE TRAVEL",
    "",
    0,
    0,
    "KZ8956",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    519,
    "KMAR TRAVEL",
    1,
    1,
    "KMAR TRAVEL",
    null,
    null,
    "29001452 ahmed",
    null,
    "KZ1808",
    "sales@kmartravel.com;direction@kmartravel.com",
    null,
    0,
    2,
    0,
    "KMAR TRAVEL",
    "KMAR TRAVEL",
    "",
    0,
    0,
    "KZ1808",
    "",
    205092.95,
    203856.814,
    0,
    0,
    5400.0,
    0,
    0,
  ],
  [
    520,
    "KOUNOUZ NABEUL",
    1,
    1,
    "KOUNOUZ NABEUL",
    null,
    null,
    null,
    null,
    "4556",
    ";nabil.adhoum@wta.t",
    null,
    0,
    2,
    0,
    "KOUNOUZ NABEUL",
    "KOUNOUZ NABEUL",
    "",
    0,
    0,
    "KZ120",
    "",
    0,
    2192.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    526,
    "ZAMZEM VOYAGE",
    1,
    1,
    "ZAMZEM VOYAGE",
    null,
    null,
    null,
    null,
    "KZZAMZEM",
    "zamzem-voyage@topnet.t",
    null,
    0,
    2,
    0,
    "ZAMZEM VOYAGE",
    "ZAMZEM VOYAGE",
    null,
    0,
    0,
    "KZZAMZEM",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    527,
    "ELYOSR TRAVEL ",
    1,
    0,
    "ELYOSR TRAVEL",
    null,
    null,
    null,
    null,
    "KZELYOSR",
    "elyosr-travel@topnet.t",
    null,
    0,
    2,
    0,
    "ELYOSR TRAVEL ",
    "ELYOSR TRAVEL ",
    null,
    0,
    0,
    "KZELYOSR",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    528,
    "CRYTAL VOYAGE ",
    1,
    0,
    "CRYTAL VOYAGE",
    null,
    null,
    null,
    null,
    "KZCRYSTAL;MIRTS312L",
    "crytal-voyage@topnet.t",
    null,
    0,
    2,
    0,
    "CRYTAL VOYAGE ",
    "CRYTAL VOYAGE ",
    null,
    0,
    0,
    "KZCRYSTAL",
    null,
    10510.0,
    10529.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    529,
    "BELLAMI TRAVEL",
    1,
    2,
    "BELLAMI TRAVEL",
    null,
    null,
    null,
    null,
    "KZBELAMI",
    "bellami-travel@topnet.t",
    null,
    0,
    2,
    0,
    "BELLAMI TRAVEL",
    "BELLAMI TRAVEL",
    null,
    0,
    0,
    "KZBELAMI",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    530,
    "MIR TRAVEL FRANCE ",
    1,
    2,
    "MIR TRAVEL FRANCE",
    null,
    null,
    null,
    null,
    "KZ635FR",
    "h.khedija@yahoo.fr ",
    null,
    0,
    2,
    0,
    "MIR TRAVEL FRANCE ",
    "MIR TRAVEL FRANCE ",
    null,
    0,
    0,
    "KZ635FR",
    null,
    9629.0,
    21826.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    531,
    "ALL IN TRAVEL B2B ",
    1,
    1,
    "ALL IN TRAVEL B2B",
    null,
    null,
    null,
    null,
    "KZ1857B2B",
    "contact@allin.t",
    null,
    0,
    2,
    0,
    "ALL IN TRAVEL B2B ",
    "ALL IN TRAVEL B2B ",
    null,
    0,
    0,
    "KZ1857B2B",
    null,
    117836.8,
    143574.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    532,
    "EMOTION B2B ",
    1,
    1,
    "EMOTION B2B",
    null,
    null,
    null,
    null,
    "emo018b2b",
    "neila.bensalem@emotiontravel.com.tn;bsn2009@hotmail.fr",
    null,
    0,
    2,
    0,
    "EMOTION B2B ",
    "EMOTION B2B ",
    null,
    0,
    0,
    "emo018b2b",
    null,
    20417.7,
    50180.722,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    533,
    "SAFER ALSALAM TOURS AND TOURISM",
    1,
    1,
    "SAFER ALSALAM TOURS AND TOURISM",
    null,
    null,
    "0233158527218917777835",
    null,
    "TIPLY2642",
    "safer-tourism@gnet.t",
    null,
    0,
    2,
    0,
    "SAFER ALSALAM TOURS AND TOURISM",
    "SAFER ALSALAM TOURS AND TOURISM",
    null,
    0,
    0,
    null,
    "APTIP 0233158527/+218917777835 - SAFER ALSALAM TOURS AND TOURISM - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    534,
    "HIMA TOURS",
    1,
    1,
    "HIMA TOURS",
    "1495759/Y",
    "08 rue Moheddine Klibi, 2092",
    null,
    "58 842 761 badii",
    "HIMA01",
    "omarbessem8@gmail.com;mohamedaminekhammari@gmailcom;atefomarch@gmail.com",
    null,
    0,
    2,
    0,
    "HIMA TOURS",
    "HIMA TOURS",
    null,
    0,
    0,
    "HIMA01",
    null,
    660920.42,
    654218.36,
    0,
    0,
    0,
    0,
    0,
    1,
  ],
  [
    536,
    "AMBER TRAVEL LITIGE",
    1,
    1,
    "AMBER TRAVEL LITIGE",
    null,
    "Kairoua",
    null,
    " 52 210 002",
    "AMBER018",
    "info@amber-travel.com.tn;nader@amber-travel.com.tn;tunis@ekounouz.com",
    null,
    0,
    2,
    0,
    "AMBER TRAVEL LITIGE",
    "AMBER TRAVEL LITIGE",
    null,
    0,
    0,
    "AMBER018",
    null,
    37107.76,
    37107.76,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    537,
    "MULTICAR",
    1,
    1,
    "MULTICAR",
    "MULTICAR",
    "Route Touristique Hammam Sousse. En face Salon de Thé Belvédère",
    "97 286 603",
    "73 364 310",
    "MULTICAR",
    "contact@multicartunisia.com ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "MULTICAR",
    "MULTICAR",
    null,
    0,
    0,
    "MULTICAR",
    null,
    4362.81,
    4362.81,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    539,
    "TREND OLOT TUNISIE",
    1,
    1,
    "TREND OLOT TUNISIE",
    "TREND OLOT TUNISIE",
    null,
    null,
    null,
    "trend",
    "tunis@ekounouz.com ",
    null,
    0,
    2,
    0,
    "TREND OLOT TUNISIE",
    "TREND OLOT TUNISIE",
    null,
    0,
    0,
    "trend",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    540,
    " VIKA TRAVEL ",
    1,
    1,
    "VIKA TRAVEL",
    " VIKA TRAVEL ",
    null,
    null,
    null,
    "VIKA;TUNTS318C",
    "koub.med@gmail.com;jaafar.kouba@gmail.com;mohamedaminekhammari@gmail.com;cgm.audit@gmail.com",
    null,
    0,
    2,
    0,
    " VIKA TRAVEL ",
    " VIKA TRAVEL ",
    null,
    0,
    0,
    "VIKA",
    null,
    361904.045,
    356820.045,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    599,
    "QLDR9210X|SMILE TOURISME ET VOYAGES",
    0,
    0,
    "SMILE TOURISME ET VOYAGES",
    null,
    null,
    "21325323613",
    null,
    "QLDR9210X",
    "SMILEBILLET@GMAIL.COM",
    null,
    0,
    3,
    0,
    "SMILE TOURISME ET VOYAGES",
    "SMILE TOURISME ET VOYAGES",
    null,
    0,
    0,
    null,
    "APQLD 213 25 32 36 13 - SMILE TOURISME ET VOYAGES - A//E-SMILEBILLET@GMAIL.COM",
    5393310.0,
    1.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    600,
    "IBTISMA VOYAGE",
    0,
    0,
    "IBTISMA VOYAGE",
    "F454",
    null,
    null,
    null,
    "IV",
    null,
    null,
    0,
    3,
    1,
    "IBTISMA VOYAGE",
    "IBTISMA VOYAGE",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    601,
    "KOUNOUZ MEDNINE",
    1,
    1,
    "KOUNOUZ MEDNINE",
    null,
    null,
    null,
    null,
    "KZ640",
    "jaouadi.walid@outlook.com;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com;kounouz.mednine2017@gmail.com;kounouz.tataouine@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ MEDNINE",
    "KOUNOUZ MEDNINE",
    null,
    0,
    0,
    "KZ640",
    null,
    65750.67,
    65646.39,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    602,
    "ALKI-PLAST",
    1,
    1,
    "ALKI-PLAST",
    "ZONE",
    "ZONE INDUSTRIELLE ENFIDHA",
    "73380429",
    "73380429",
    "ALKI",
    "ALKIPLAST_TN@HOTMAIL.FR",
    null,
    0,
    2,
    0,
    "ALKI-PLAST",
    "ALKI-PLAST",
    null,
    0,
    0,
    "ALKI",
    null,
    8026.5,
    8026.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    603,
    "AL MOUSAFER TRAVEL ",
    1,
    1,
    "AL MOUSAFER TRAVEL",
    null,
    null,
    null,
    null,
    "MOUSSAFER;MIRTS311O",
    "almoussafertravel@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "AL MOUSAFER TRAVEL ",
    "AL MOUSAFER TRAVEL ",
    null,
    0,
    0,
    "MOSAFER",
    null,
    584904.76,
    579275.17,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    605,
    "3ABOOKING",
    1,
    1,
    "3ABOOKING",
    "3a",
    " Rue ibn Khaldoun – 8050  Hammamet ",
    "20226967",
    "72283374",
    "3A",
    "3ABOOKING@gnet.tn;tunis@ekounouz.com ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "3ABOOKING",
    "3ABOOKING",
    null,
    0,
    0,
    "3A",
    null,
    3087.4,
    3100.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    606,
    "SMART EVENTS",
    1,
    1,
    "SMART EVENTS",
    null,
    null,
    null,
    "22 017 065",
    "smart;TUNTS314P;TUNTS3124;TUN1A0980 ;TUNTS3174;TUNTS2170",
    "oumayma.benslema@gmail.com;tunis@ekounouz.com;monosskef@yahoo.fr ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "SMART EVENTS",
    "SMART EVENTS",
    null,
    0,
    0,
    "smart",
    null,
    44855.49,
    44619.6,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    607,
    "O.W.S",
    1,
    1,
    "O.W.S",
    "O.W.S",
    null,
    null,
    null,
    "ows",
    "s.benmohamed@onewayserv.com;tunis@ekounouz.com;monosskef@yahoo.fr  ",
    null,
    0,
    2,
    0,
    "O.W.S",
    "O.W.S",
    null,
    0,
    0,
    "ows",
    null,
    8213.5,
    8214.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    608,
    "ROSSEL TRAVEL",
    1,
    1,
    "ROSSEL TRAVEL",
    "ROSSEL TRAVEL",
    null,
    null,
    null,
    "rossel",
    "marwarosseltravel@gmail.com;contact@rosseltravelevents.com",
    null,
    0,
    2,
    0,
    "ROSSEL TRAVEL",
    "ROSSEL TRAVEL",
    null,
    0,
    0,
    "rossel",
    null,
    7078.26,
    7078.04,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    609,
    "TUNTS312Y|VOGUE VOYAGES",
    1,
    0,
    "VOGUE VOYAGES",
    null,
    null,
    "21672291058",
    null,
    "TUNTS312Y",
    "vogue-voyages@gnet.tn;voguevoyages1@gmail.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "VOGUE VOYAGES",
    "VOGUE VOYAGES",
    null,
    0,
    0,
    null,
    "APTUN (216)72291058 - VOGUE VOYAGES - A",
    5675.0,
    5557.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    610,
    "KOUNOUZ RGUEB",
    1,
    1,
    "KOUNOUZ RGUEB",
    null,
    null,
    null,
    null,
    "kounouz bouzid ",
    "kadrilotfi79@gmail.com;kounouz.sidibou@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ RGUEB",
    "KOUNOUZ RGUEB",
    null,
    0,
    0,
    "SIDIBOUZID",
    null,
    128960.96,
    129529.95,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    611,
    "HOLIC TRAVEL",
    1,
    2,
    "HOLIC TRAVEL",
    null,
    null,
    null,
    null,
    "kz150",
    "holic-travel@topnet.tn ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "HOLIC TRAVEL",
    "HOLIC TRAVEL",
    null,
    0,
    0,
    "kz150",
    null,
    2053.5,
    2054.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    612,
    "KOUNOUZ LA MANOUBA",
    1,
    1,
    "KOUNOUZ LA MANOUBA",
    null,
    null,
    null,
    null,
    "kz1254",
    "kounouz.tebourba@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ LA MANOUBA",
    "KOUNOUZ LA MANOUBA",
    null,
    0,
    0,
    "kzmanouba",
    null,
    147374.79,
    148675.73,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    613,
    "UMRA KOUNOUZ 2018",
    1,
    2,
    "UMRA KOUNOUZ 2018",
    "123",
    "Italy approach, building skin first floor 4000 Sousse, Tunisia",
    null,
    null,
    "KZOMRAH",
    "finance@ekounouz.com",
    null,
    0,
    2,
    0,
    "UMRA KOUNOUZ 2018",
    "UMRA KOUNOUZ 2018",
    null,
    0,
    0,
    "KZOMRAH",
    null,
    5583729.0,
    8277.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    614,
    "EL MANSOUR TRAVEL",
    1,
    1,
    "EL MANSOUR TRAVEL",
    "El Mansour Travel",
    null,
    null,
    null,
    "mansour;MIRTS313E;TUNAF0101",
    "sales@elmansourtravel.com;recouvrement@elmansoutravel.com",
    null,
    0,
    2,
    0,
    "EL MANSOUR TRAVEL",
    "EL MANSOUR TRAVEL",
    null,
    0,
    0,
    "mansour",
    null,
    508618.82,
    502060.472,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    616,
    "ITS",
    1,
    1,
    "ITS",
    null,
    null,
    null,
    null,
    "its",
    "APPRO2@GAALICHE.COM;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "ITS",
    "ITS",
    null,
    0,
    0,
    "its",
    null,
    14951.58,
    14971.9,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    668,
    "Essaaf d'or",
    0,
    0,
    "Essaaf d'or",
    null,
    null,
    null,
    null,
    "OGXR92103",
    null,
    null,
    0,
    4,
    0,
    "Essaaf d'or",
    "Essaaf d'or",
    null,
    0,
    0,
    null,
    null,
    11013014.0,
    4024532.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    670,
    "BIG HOLIDAY-TUNISA",
    1,
    1,
    "BIG HOLIDAY-TUNISA",
    "Big Holidays",
    "Agc SOUSSE AV Y.Arafet. resid. Nour Sahloul",
    null,
    "73821400",
    "big",
    "imed@bigholidays.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "BIG HOLIDAY-TUNISA",
    "BIG HOLIDAY-TUNISA",
    null,
    0,
    0,
    "big",
    null,
    21110.05,
    20980.054,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    673,
    " BAYA TRAVEL",
    1,
    1,
    "BAYA TRAVEL",
    " BAYA TRAVEL",
    null,
    " 29661467",
    null,
    "TUNTS318R",
    "info@bayatravel.tn;BENJEMAAHAJERBTA@GMAIL.COM",
    null,
    0,
    2,
    0,
    " BAYA TRAVEL",
    " BAYA TRAVEL",
    null,
    0,
    0,
    "baya;beya",
    null,
    23286.02,
    23286.02,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    675,
    "MARASSEM",
    1,
    1,
    "MARASSEM",
    "marasem",
    null,
    null,
    null,
    "marasem",
    "Marassem.travel@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "MARASSEM",
    "MARASSEM",
    null,
    0,
    0,
    "marasem;marassem",
    null,
    30267.0,
    29212.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    705,
    "KOUNOUZ BEJA",
    1,
    1,
    "KOUNOUZ BEJA",
    "kounouz  beja",
    "beja",
    " 97 951 188",
    null,
    "kzbeja",
    "marwa.omrani50@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ BEJA",
    "KOUNOUZ BEJA",
    null,
    0,
    0,
    "kzbeja",
    null,
    46537.8,
    44248.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    706,
    "MAYASEEN TRAVEL & SERVICE",
    0,
    1,
    "MAYASEEN TRAVEL & SERVICE",
    null,
    null,
    "21675656100",
    null,
    "DJETS310X",
    null,
    null,
    40000.0,
    6,
    0,
    "MAYASEEN TRAVEL & SERVICE",
    "MAYASEEN TRAVEL & SERVICE",
    null,
    0,
    0,
    null,
    "APDJE +216 75 656 100 - MAYASEEN TRAVEL & SERVICE - A",
    596469.1,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    707,
    "ULYSSE VOYAGES",
    0,
    1,
    "ULYSSE VOYAGES",
    null,
    null,
    "21675621909",
    null,
    "DJETS310R",
    "mayaseencaisse@gmail.com",
    null,
    0,
    6,
    0,
    "ULYSSE VOYAGES",
    "ULYSSE VOYAGES",
    null,
    0,
    0,
    null,
    "APDJE (216)75621909 - ULYSSE VOYAGES - A//E-RESA@ULYSSE-TOURS.COM",
    164756.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    715,
    "TVA OUARGLA",
    0,
    1,
    "TVA OUARGLA",
    null,
    null,
    "029701113",
    null,
    "OGXR92101",
    "",
    null,
    0,
    4,
    1,
    "TVA OUARGLA",
    "TVA OUARGLA",
    null,
    0,
    0,
    "OGXR92101",
    "APOGX 029701113 - TVA OUARGLA - A//E-KAYI.ETB@YAHOO.COM//E-ILYES.SMAIDI@HOPITAL-OUARGLA.COM//E-RAMAZANKEMENT@GMAIL.COM",
    21615.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    716,
    "LASSIM TRAVEL LITIGE",
    1,
    1,
    "LASSIM TRAVEL LITIGE",
    " lassim travel",
    null,
    null,
    null,
    "lassim",
    "lassimetravel@gmail.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "LASSIM TRAVEL LITIGE",
    "LASSIM TRAVEL LITIGE",
    null,
    0,
    0,
    "LASSIM",
    null,
    79981.47,
    80895.064,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    717,
    "PLASMA SERVICES",
    0,
    0,
    "PLASMA SERVICES",
    null,
    "ouargla",
    "0661459882 / 0662798199",
    null,
    "MIR002",
    "Abdoutour30rh@yahoo.com",
    null,
    0,
    4,
    0,
    "PLASMA SERVICES",
    "PLASMA SERVICES",
    null,
    0,
    0,
    "cadtec",
    null,
    395720.0,
    58381.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    718,
    "Antir services",
    0,
    0,
    "Antir services",
    null,
    null,
    "0661102934",
    null,
    "003",
    null,
    null,
    0,
    4,
    0,
    "Antir services",
    "Antir services",
    null,
    0,
    0,
    "ag001",
    null,
    397510.0,
    395010.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    720,
    "Boukhris Abdelhadi",
    0,
    0,
    "Boukhris Abdelhadi",
    null,
    "Ouargla",
    "0660745858/0661383147",
    null,
    "emir04",
    "abdouogx@gmail.com",
    null,
    0,
    4,
    0,
    "Boukhris Abdelhadi",
    "Boukhris Abdelhadi",
    null,
    0,
    0,
    null,
    null,
    11654.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    721,
    "KAYI INSAAT",
    0,
    0,
    "KAYI INSAAT",
    null,
    "07KM ROUTE DE MENEA",
    "0560159925/0560549200",
    null,
    "MIR005",
    "ilyes.smaidi@hopital-ouargla.com",
    null,
    0,
    4,
    0,
    "KAYI INSAAT",
    "KAYI INSAAT",
    null,
    0,
    0,
    null,
    null,
    1153911.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    722,
    "KAYI INSAAT",
    0,
    0,
    "KAYI INSAAT",
    null,
    "07KM ROUTE DE MENEA",
    "0560159925/0560549200",
    null,
    "MIR004",
    "ilyes.smaidi@hopital-ouargla.com",
    null,
    0,
    4,
    1,
    "KAYI INSAAT",
    "KAYI INSAAT",
    null,
    0,
    0,
    "kayi",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    723,
    "Chicha Aéroport",
    0,
    0,
    "Chicha Aéroport",
    null,
    "Aéroport OGX",
    "0660654037",
    null,
    "mir006",
    "mehiri.messaoud@airalgerie.dz",
    null,
    0,
    4,
    0,
    "Chicha Aéroport",
    "Chicha Aéroport",
    null,
    0,
    0,
    null,
    null,
    17580.0,
    14940.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    724,
    "Kamel Aéroport",
    0,
    0,
    "Kamel Aéroport",
    null,
    "Aéroport OGX",
    "0662399772",
    null,
    "mir007",
    null,
    null,
    0,
    4,
    0,
    "Kamel Aéroport",
    "Kamel Aéroport",
    null,
    0,
    0,
    null,
    null,
    52551.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    725,
    "Pezzeria el Bahdja",
    0,
    0,
    "Pezzeria el Bahdja",
    null,
    "460 lgts ouargla",
    "0552908005",
    null,
    "mir008",
    null,
    null,
    0,
    4,
    0,
    "Pezzeria el Bahdja",
    "Pezzeria el Bahdja",
    null,
    0,
    0,
    null,
    null,
    28116.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    726,
    "Belaid hemza",
    0,
    0,
    "Belaid hemza",
    null,
    "ouargla",
    "0696576700",
    null,
    "mir009",
    null,
    null,
    0,
    4,
    0,
    "Belaid hemza",
    "Belaid hemza",
    null,
    0,
    0,
    null,
    null,
    11892.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    727,
    "Cosider Ouvrages d'art, SPA",
    0,
    0,
    "Cosider Ouvrages d'art, SPA",
    null,
    "ouargla",
    "0660849259 Abdelhafid",
    null,
    "mir010",
    "sadoun.hafid@gmail.com",
    null,
    0,
    4,
    0,
    "Cosider Ouvrages d'art, SPA",
    "Cosider Ouvrages d'art, SPA",
    null,
    0,
    0,
    null,
    null,
    23586.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    728,
    "Flash Chemicals",
    0,
    0,
    "Flash Chemicals",
    null,
    "Hassi Messaoud",
    "0660366157",
    null,
    "mir011",
    "flashchemicals.fci@gmail.com",
    null,
    0,
    4,
    0,
    "Flash Chemicals",
    "Flash Chemicals",
    null,
    0,
    0,
    null,
    null,
    17838.0,
    4646.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    729,
    "Bouchareb Djelloul",
    0,
    0,
    "Bouchareb Djelloul",
    null,
    "Mekhadma ouargla",
    "0660514243/0550525645",
    null,
    "MIR012",
    null,
    null,
    0,
    4,
    0,
    "Bouchareb Djelloul",
    "Bouchareb Djelloul",
    null,
    0,
    0,
    null,
    null,
    5946.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    730,
    "Ets Ethar (Mefatih)",
    0,
    0,
    "Ets Ethar (Mefatih)",
    null,
    "mekhadma ouargla",
    "0661383853/0671636333",
    null,
    "mir0013",
    "ets.ethar@gmail.com",
    null,
    0,
    4,
    0,
    "Ets Ethar (Mefatih)",
    "Ets Ethar (Mefatih)",
    null,
    0,
    0,
    null,
    null,
    5827.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    804,
    "Gheraissa lakhdar",
    0,
    0,
    "Gheraissa lakhdar",
    null,
    "ouargla",
    "0660454048",
    null,
    "MIR014",
    "lakgraissa@yahoo.com",
    null,
    0,
    4,
    0,
    "Gheraissa lakhdar",
    "Gheraissa lakhdar",
    null,
    0,
    0,
    null,
    null,
    15950.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    863,
    "Hamdi Selma",
    0,
    0,
    "Hamdi Selma",
    null,
    "ouargla",
    "0670331515",
    null,
    "022",
    "selma-hamdi@hotmail.com",
    null,
    0,
    4,
    0,
    "Hamdi Selma",
    "Hamdi Selma",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    864,
    "Hamdi Selma",
    0,
    0,
    "Hamdi Selma",
    null,
    "ouargla",
    "0670331515",
    null,
    "MIR022",
    "selma-hamdi@hotmail.com",
    null,
    0,
    4,
    1,
    "Hamdi Selma",
    "Hamdi Selma",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    865,
    "Hamdi Selma",
    0,
    0,
    "Hamdi Selma",
    null,
    "ouargla",
    "0670331515",
    null,
    "MIR023",
    "selma-hamdi@hotmail.com",
    null,
    0,
    4,
    1,
    "Hamdi Selma",
    "Hamdi Selma",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1110,
    "OGXR92103|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "",
    null,
    null,
    0,
    4,
    0,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    4656675.0,
    150483.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1111,
    "OGXR92101|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "OGXR92101",
    null,
    null,
    0,
    4,
    0,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    27842.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1112,
    "AJYAL EL KHIR",
    1,
    1,
    "AJYAL EL KHIR",
    null,
    null,
    null,
    null,
    "TIPLY2851;TIPLY2526",
    "ajialtkt@yahoo.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "AJYAL EL KHIR",
    "AJYAL EL KHIR",
    null,
    0,
    0,
    "ajyal",
    null,
    13383.5,
    13383.2,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1113,
    "VLT",
    1,
    1,
    "VLT",
    null,
    null,
    null,
    null,
    "vlt;TUNTS316X",
    "selmine.tarchoun@vlt.travel;a.dhaou@vlt.travel;a.adhaou@vlt.travel",
    null,
    0,
    2,
    0,
    "VLT",
    "VLT",
    null,
    0,
    0,
    "vlt",
    null,
    4353.5,
    4288.788,
    0,
    0,
    8000.0,
    0,
    0,
  ],
  [
    1115,
    "MAGNI TOURS",
    1,
    1,
    "MAGNI TOURS",
    null,
    null,
    null,
    null,
    "MAGNI",
    "DIRECTION.MAGNITOURS@GMAIL.COM,tunis@ekounouz.com;monosskef@yahoo.fr ;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "MAGNI TOURS",
    "MAGNI TOURS",
    null,
    0,
    0,
    "MAGNI",
    null,
    15968.66,
    16470.13,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    1116,
    "MED TOURS",
    1,
    0,
    "MED TOURS",
    null,
    null,
    "21671253522",
    null,
    "TUNTS314R",
    "contact@medtour.com.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "MED TOURS",
    "MED TOURS",
    null,
    0,
    0,
    null,
    "APTUN (216)71253522 - MED TOURS - A",
    761.5,
    761.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1119,
    "DJETS310T|MAYASEEN TRAVEL SERVICE",
    0,
    0,
    "MAYASEEN TRAVEL SERVICE",
    null,
    null,
    "21658838313",
    null,
    "DJETS310T",
    null,
    null,
    0,
    6,
    0,
    "MAYASEEN TRAVEL SERVICE",
    "MAYASEEN TRAVEL SERVICE",
    null,
    0,
    0,
    null,
    "APDJE (216)58838313 - MAYASEEN TRAVEL SERVICE - A//TUN0021623707577-M",
    86524.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1120,
    "YOSR TOURS ",
    1,
    1,
    "YOSR TOURS",
    null,
    "HAMAM LIFE TUNIS ",
    "29529553",
    "riadh salhi chef d'agence ",
    "TUNTS31BF;TUNUG0583;TUNTU08BB;TUNTS312T",
    " cyosrtours@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "YOSR TOURS ",
    "YOSR TOURS ",
    null,
    0,
    0,
    null,
    null,
    371967.8,
    373825.3,
    0,
    0,
    30000.0,
    0,
    0,
  ],
  [
    1121,
    "DJETU0201|TUNISAIR AVENUE HABIB BOURGUIBA",
    0,
    0,
    "TUNISAIR AVENUE HABIB BOURGUIBA",
    null,
    null,
    "0021675650159",
    null,
    "DJETU0201",
    null,
    null,
    0,
    6,
    0,
    "TUNISAIR AVENUE HABIB BOURGUIBA",
    "TUNISAIR AVENUE HABIB BOURGUIBA",
    null,
    0,
    0,
    null,
    "APDJE 00216 75 650159 - TUNISAIR AVENUE HABIB BOURGUIBA - A",
    1609.2,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1124,
    "MERY MOON SERVICES LITIGE",
    1,
    1,
    "MERY MOON SERVICES LITIGE",
    "MMS",
    null,
    "99201469",
    null,
    "MMS",
    "",
    null,
    0,
    2,
    0,
    "MERY MOON SERVICES LITIGE",
    "MERY MOON SERVICES LITIGE",
    null,
    0,
    0,
    "MMS",
    null,
    11748.1,
    11748.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1125,
    "DJEUG001A|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "DJEUG001A",
    null,
    null,
    0,
    6,
    0,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    872.4,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1132,
    "DOUI VOYAGES",
    0,
    0,
    "DOUI VOYAGES",
    null,
    null,
    "21327603145",
    null,
    "CFKR92104",
    "DOUIVOYAGE@GMAIL.COM",
    null,
    0,
    1,
    0,
    "DOUI VOYAGES",
    "DOUI VOYAGES",
    null,
    0,
    0,
    null,
    "APALG 213 27 60 31 45 - DOUI VOYAGES - A//E-DOUIVOYAGE@GMAIL.COM",
    488269.0,
    0,
    0,
    0,
    0,
    100000.0,
    0,
  ],
  [
    1133,
    "ZEM ZEM VOYAGES",
    0,
    0,
    "ZEM ZEM VOYAGES",
    null,
    null,
    "021850330",
    null,
    "ALGR9215Q",
    null,
    null,
    0,
    1,
    0,
    "ZEM ZEM VOYAGES",
    "ZEM ZEM VOYAGES",
    null,
    0,
    0,
    null,
    "APALG 021850330-021850493 - ZEM ZEM VOYAGES - A",
    1014788.0,
    23972.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1134,
    "FUN VOYAGE",
    0,
    0,
    "FUN VOYAGE",
    null,
    null,
    "021470929",
    null,
    "ALGR9210G",
    "FEETFEETFEET1965@YAHOO.FR",
    null,
    0,
    1,
    0,
    "FUN VOYAGE",
    "FUN VOYAGE",
    null,
    0,
    0,
    null,
    "APALG 021470929 - FUN VOYAGE - A//E-FEETFEETFEET1965@YAHOO.FR",
    377229.0,
    83016.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1135,
    "CENTURY 21 TRAVEL AGENCY",
    0,
    0,
    "CENTURY 21 TRAVEL AGENCY",
    null,
    null,
    "21324914204",
    null,
    "ALGR921EG",
    "CENTURY21DZ@GMAIL.COM",
    null,
    0,
    1,
    0,
    "CENTURY 21 TRAVEL AGENCY",
    "CENTURY 21 TRAVEL AGENCY",
    null,
    0,
    0,
    null,
    "APALG 213 24 91 42 04 - CENTURY 21 TRAVEL AGENCY - A//E-CENTURY21DZ@GMAIL.COM",
    36025.0,
    25000.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1136,
    "YAALA VOYAGES",
    0,
    0,
    "YAALA VOYAGES",
    null,
    null,
    "021284997",
    null,
    "ALGR9217U",
    "YAALAVOYAGES@YAHOO.FR",
    null,
    0,
    1,
    0,
    "YAALA VOYAGES",
    "YAALA VOYAGES",
    null,
    0,
    0,
    null,
    "APALG 021284997 - YAALA VOYAGES - A//ALG 021284997 - YAALA VOYAGES - A//E-YAALAVOYAGES@YAHOO.FR",
    102420.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1137,
    "CHERCHELL TOURS",
    0,
    0,
    "CHERCHELL TOURS",
    null,
    null,
    "21321975832",
    null,
    "ALGR9213H",
    "CHERCHELLTOURS@HOTMAIL.FR",
    null,
    0,
    1,
    0,
    "CHERCHELL TOURS",
    "CHERCHELL TOURS",
    null,
    0,
    0,
    null,
    "APALG 213 21 975832 - CHERCHELL TOURS - A//E-CHERCHELLTOURS@HOTMAIL.FR",
    109045.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1138,
    "MIMOSA VOYAGES",
    0,
    0,
    "MIMOSA VOYAGES",
    null,
    null,
    "21345217391",
    null,
    "MQVR92109",
    null,
    null,
    0,
    1,
    0,
    "MIMOSA VOYAGES",
    "MIMOSA VOYAGES",
    null,
    0,
    0,
    null,
    "APORN 213 45 21 73 91 - MIMOSA VOYAGES - A//_MIMOSAVOYAGES@GMAIL.COM",
    64940.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1139,
    "CHENOUA TOURS",
    0,
    0,
    "CHENOUA TOURS",
    null,
    null,
    "024470567",
    null,
    "ALGR92142",
    "CHENOUA_TOURS@YAHOO.FR",
    null,
    0,
    1,
    0,
    "CHENOUA TOURS",
    "CHENOUA TOURS",
    null,
    0,
    0,
    null,
    "APALG 024470567 - CHENOUA TOURS - A//E-CHENOUA_TOURS@YAHOO.FR",
    17500.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1140,
    "BOUZEGZA TOURISME ET VOYAGES",
    0,
    0,
    "BOUZEGZA TOURISME ET VOYAGES",
    null,
    null,
    "21324817003",
    null,
    "ALGR9215V",
    null,
    null,
    0,
    1,
    0,
    "BOUZEGZA TOURISME ET VOYAGES",
    "BOUZEGZA TOURISME ET VOYAGES",
    null,
    0,
    0,
    null,
    "APALG 213 24 81 70 03 - BOUZEGZA TOURISME ET VOYAGES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1162,
    "LAFAYETTE",
    1,
    1,
    "LAFAYETTE",
    "1405501",
    null,
    null,
    null,
    "",
    "direction.lafayettevoyages@gnet.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "LAFAYETTE",
    "LAFAYETTE",
    null,
    0,
    0,
    "LAFAYETTE",
    null,
    75484.87,
    75484.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1163,
    "TUNUG0583|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "TUNUG0583",
    null,
    null,
    0,
    6,
    0,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    5457.8,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1164,
    "HAJJ2018",
    1,
    0,
    "HAJJ2018",
    "Hajj2018",
    null,
    null,
    null,
    "haj2018",
    "",
    null,
    0,
    2,
    0,
    "HAJJ2018",
    "HAJJ2018",
    null,
    0,
    0,
    "haj2018",
    null,
    260404.75,
    2381.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1165,
    "INSTA TARVEL SERVICE",
    1,
    1,
    "INSTA TARVEL SERVICE",
    "INSTA TARVEL SERVICE",
    null,
    "50517364",
    null,
    "INSTA",
    "instatravel@houdahotelstunisia.com;Resa.instatravel@monhootel.com ",
    null,
    0,
    2,
    0,
    "INSTA TARVEL SERVICE",
    "INSTA TARVEL SERVICE",
    null,
    0,
    0,
    "INSTA",
    null,
    248092.13,
    243336.799,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1182,
    "HAMDI LAAMIRI LITIGE",
    1,
    1,
    "HAMDI LAAMIRI LITIGE",
    "hamdi",
    null,
    null,
    null,
    "hamdi",
    "Laamiri.hamdi@gmail.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "HAMDI LAAMIRI LITIGE",
    "HAMDI LAAMIRI LITIGE",
    null,
    0,
    0,
    "hamdi",
    null,
    39931.26,
    40990.3,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1189,
    "ALL IN EVENT",
    1,
    1,
    "ALL IN EVENT",
    null,
    null,
    null,
    null,
    "allinvo",
    "contact@allin.t",
    null,
    0,
    2,
    0,
    "ALL IN EVENT",
    "ALL IN EVENT",
    null,
    0,
    0,
    "allinvo",
    null,
    45958.35,
    45092.0,
    0,
    0,
    21920.0,
    0,
    0,
  ],
  [
    1190,
    "MV -2 -GABES supp",
    0,
    0,
    "MV -2 -GABES supp",
    "sss",
    null,
    "21675222968",
    null,
    "",
    null,
    null,
    0,
    7,
    0,
    "MV -2 -GABES supp",
    "MV -2 -GABES supp",
    null,
    0,
    0,
    null,
    "APGAE (216)75222968 - MADINAH VOYAGES 2 - A//22496486",
    203091.8,
    4054.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1191,
    "MV-TUNISDDDDD",
    0,
    0,
    "MV-TUNISDDDDD",
    null,
    null,
    "21671335444",
    null,
    "",
    null,
    null,
    0,
    7,
    1,
    "MV-TUNISDDDDD",
    "MV-TUNISDDDDD",
    null,
    0,
    0,
    null,
    "APTUN (216)71335444 - MADINAH VOYAGES - A//SUN BEASH",
    -24335.2,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1192,
    "MV-TUNIS",
    0,
    0,
    "MV-TUNIS",
    null,
    null,
    "21671335444",
    null,
    "TUNTS315M;TUNTS313K;TUNTU0252",
    null,
    null,
    0,
    7,
    0,
    "MV-TUNIS",
    "MV-TUNIS",
    null,
    0,
    0,
    null,
    "APTUN (216)71335444 - MADINAH VOYAGES 5 - A//HEND",
    674449.02,
    11583.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1193,
    "MV -SFAX",
    0,
    0,
    "MV -SFAX",
    null,
    null,
    "21674417117",
    null,
    "SFATS310G",
    null,
    null,
    0,
    7,
    0,
    "MV -SFAX",
    "MV -SFAX",
    null,
    0,
    0,
    null,
    "APSFA (216)74417117 - MADINAH VOYAGES 3 - A",
    84722.0,
    2313.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1194,
    "MV BIZERT",
    0,
    0,
    "MV BIZERT",
    null,
    null,
    "211671335444",
    null,
    "TUNTS315U",
    null,
    null,
    0,
    7,
    0,
    "MV BIZERT",
    "MV BIZERT",
    null,
    0,
    0,
    null,
    "APTUN (2116)71335444 - MADINAH VOYAGES 6 - A//23633603",
    58948.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1195,
    "Umrah",
    0,
    0,
    "Umrah",
    null,
    null,
    null,
    null,
    "TUNSV0101",
    null,
    null,
    0,
    7,
    0,
    "Umrah",
    "Umrah",
    null,
    0,
    0,
    null,
    null,
    4424861.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1198,
    "OFFICE ID  = TUNSV0101",
    0,
    0,
    "OFFICE ID  = TUNSV0101",
    null,
    null,
    null,
    null,
    "TUNSV0101",
    null,
    null,
    0,
    7,
    1,
    "OFFICE ID  = TUNSV0101",
    "OFFICE ID  = TUNSV0101",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1199,
    "Tunisair TUNTU0252",
    0,
    0,
    "Tunisair TUNTU0252",
    null,
    null,
    null,
    null,
    "",
    null,
    null,
    0,
    7,
    0,
    "Tunisair TUNTU0252",
    "Tunisair TUNTU0252",
    null,
    0,
    0,
    null,
    null,
    5688.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1202,
    "OFFICE ID-TUNTU0252",
    0,
    0,
    "OFFICE ID-TUNTU0252",
    null,
    null,
    null,
    null,
    "TUNTU0252",
    null,
    null,
    0,
    7,
    1,
    "OFFICE ID-TUNTU0252",
    "OFFICE ID-TUNTU0252",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1203,
    "VOGUE VOYAGES",
    0,
    1,
    "VOGUE VOYAGES",
    null,
    null,
    "21672291058",
    null,
    "TUNTS312Y",
    null,
    null,
    0,
    7,
    0,
    "VOGUE VOYAGES",
    "VOGUE VOYAGES",
    null,
    0,
    0,
    "vogue",
    "APTUN (216)72291058 - VOGUE VOYAGES - A",
    71890.17,
    1212.0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    1204,
    "VOYAGES D OR SARL",
    0,
    0,
    "VOYAGES D OR SARL",
    null,
    null,
    "21673313122",
    null,
    "MIRTS311V",
    null,
    null,
    0,
    7,
    0,
    "VOYAGES D OR SARL",
    "VOYAGES D OR SARL",
    null,
    0,
    0,
    null,
    "APMIR +216 73 313 122 - VOYAGES D OR SARL - A",
    1086.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1205,
    "OFFICE ID -TUNTU0251",
    0,
    0,
    "OFFICE ID -TUNTU0251",
    null,
    null,
    null,
    null,
    "TUNTU0251",
    null,
    null,
    0,
    7,
    1,
    "OFFICE ID -TUNTU0251",
    "OFFICE ID -TUNTU0251",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1206,
    "OASIS VOYAGES",
    0,
    0,
    "OASIS VOYAGES",
    null,
    null,
    "21675290985",
    null,
    "GAETS3103",
    null,
    null,
    0,
    7,
    0,
    "OASIS VOYAGES",
    "OASIS VOYAGES",
    null,
    0,
    0,
    null,
    "APGAE (216)75290985 - OASIS VOYAGES - A",
    128340.9,
    3304.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1207,
    "Cityvol Voyages",
    0,
    0,
    "Cityvol Voyages",
    null,
    null,
    null,
    null,
    "GAETS3102",
    null,
    null,
    0,
    7,
    0,
    "Cityvol Voyages",
    "Cityvol Voyages",
    null,
    0,
    0,
    null,
    null,
    861.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1211,
    "COMETE TOURS",
    0,
    0,
    "COMETE TOURS",
    null,
    null,
    "21671862202",
    null,
    "TUNTS2162",
    null,
    null,
    0,
    7,
    0,
    "COMETE TOURS",
    "COMETE TOURS",
    null,
    0,
    0,
    null,
    "APTUN (216)71862202 - COMETE TOURS - A",
    5640.2,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1212,
    "SUN BEACH TRAVEL",
    0,
    0,
    "SUN BEACH TRAVEL",
    null,
    null,
    "21671302150",
    null,
    "TUNTS310G",
    null,
    null,
    0,
    7,
    0,
    "SUN BEACH TRAVEL",
    "SUN BEACH TRAVEL",
    null,
    0,
    0,
    null,
    "APTUN (216)71302150 - SUN BEACH TRAVEL - A",
    23284.7,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1213,
    "TUNTS311F|VITA TRAVEL AGENCY",
    0,
    0,
    "VITA TRAVEL AGENCY",
    null,
    null,
    "21671348073",
    null,
    "TUNTS311F",
    null,
    null,
    0,
    7,
    0,
    "VITA TRAVEL AGENCY",
    "VITA TRAVEL AGENCY",
    null,
    0,
    0,
    "7777",
    "APTUN (216)71348073 - VITA TRAVEL AGENCY - A",
    19125.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1214,
    "AL MOUTAWEIF",
    0,
    0,
    "AL MOUTAWEIF",
    null,
    null,
    "22245244450",
    null,
    "NKC262848",
    null,
    null,
    0,
    7,
    0,
    "AL MOUTAWEIF",
    "AL MOUTAWEIF",
    null,
    0,
    0,
    null,
    "APNKC +222 45244450 - AL MOUTAWEIF - A",
    35913.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1215,
    "DIWAN TRAVEL",
    0,
    0,
    "DIWAN TRAVEL",
    null,
    null,
    "21674408080",
    null,
    "SFATS310J",
    null,
    null,
    0,
    7,
    0,
    "DIWAN TRAVEL",
    "DIWAN TRAVEL",
    null,
    0,
    0,
    null,
    "APSFA (216)74408080 - DIWAN TRAVEL - A",
    15257.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1227,
    "SERVICE GROUPES",
    0,
    0,
    "SERVICE GROUPES",
    null,
    null,
    null,
    null,
    "TUNTU0302",
    null,
    null,
    0,
    7,
    0,
    "SERVICE GROUPES",
    "SERVICE GROUPES",
    null,
    0,
    0,
    null,
    null,
    -17447.63,
    -920.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1228,
    "Pricing Department",
    0,
    0,
    "Pricing Department",
    null,
    null,
    null,
    null,
    "TUNTU024I",
    null,
    null,
    0,
    7,
    0,
    "Pricing Department",
    "Pricing Department",
    null,
    0,
    0,
    null,
    null,
    2976.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1231,
    "OFFICE ID -TUNTU024I",
    0,
    0,
    "OFFICE ID -TUNTU024I",
    null,
    null,
    null,
    null,
    "TUNTU024I",
    null,
    null,
    0,
    7,
    1,
    "OFFICE ID -TUNTU024I",
    "OFFICE ID -TUNTU024I",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1232,
    "MONDE DES VOYAGES",
    0,
    0,
    "MONDE DES VOYAGES",
    null,
    null,
    "21675321208",
    null,
    "GAETS2100",
    null,
    null,
    0,
    7,
    0,
    "MONDE DES VOYAGES",
    "MONDE DES VOYAGES",
    null,
    0,
    0,
    null,
    "APGAE +216 75 321 208 - MONDE DES VOYAGES - A//E MONDE.VOYAGES@YAHOO.COM//TUN0021650623395-M",
    9744.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1233,
    "VO IST 29/09/2018",
    1,
    0,
    "VO IST 29/09/2018",
    null,
    null,
    null,
    null,
    "2908",
    null,
    null,
    0,
    2,
    0,
    "VO IST 29/09/2018",
    "VO IST 29/09/2018",
    null,
    0,
    0,
    null,
    null,
    22264.3,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1234,
    "YES TRAVEL ",
    1,
    1,
    "YES TRAVEL",
    "yestravel",
    null,
    null,
    null,
    "yestravel",
    "SABRINE@YES-TRAVEL.COM; compta@yes-travel.com.tn;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "YES TRAVEL ",
    "YES TRAVEL ",
    null,
    0,
    0,
    "YESTRAVEL",
    null,
    413841.43,
    419271.56,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    1235,
    "NADER VOYAGE",
    1,
    0,
    "NADER VOYAGE",
    "naderVoyage ",
    "SOUSSE ",
    "23 921 268",
    null,
    ";nader",
    "nadervoyages@gmail.com",
    null,
    0,
    2,
    0,
    "NADER VOYAGE",
    "NADER VOYAGE",
    null,
    0,
    0,
    "nader",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1236,
    "APN KOUNOUZ",
    0,
    0,
    "APN KOUNOUZ",
    null,
    null,
    null,
    null,
    "TUNSV0201",
    null,
    null,
    0,
    7,
    0,
    "APN KOUNOUZ",
    "APN KOUNOUZ",
    null,
    0,
    0,
    null,
    null,
    30183.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1238,
    "KOUNOUZ BEN AROUS",
    1,
    1,
    "KOUNOUZ BEN AROUS",
    null,
    null,
    null,
    null,
    "kzbenarous",
    "kounouzbenarous@gmail.com;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com;anislajir@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ BEN AROUS",
    "KOUNOUZ BEN AROUS",
    null,
    0,
    0,
    "kzbenarous",
    null,
    57614.56,
    57129.5,
    0,
    0,
    10000.0,
    0,
    0,
    1,
  ],
  [
    1239,
    "ASSAFINAH",
    1,
    1,
    "ASSAFINAH",
    "11111",
    "Tunis ",
    "97524681",
    null,
    "assafinahtt;TUNTS319I",
    "assafinah.tourism.travel@gmail.com;Ticketing@assafinah-tourism.com;sales@assafinah-tourism.com",
    null,
    0,
    2,
    0,
    "ASSAFINAH",
    "ASSAFINAH",
    null,
    0,
    0,
    null,
    null,
    109156.24,
    107370.24,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1240,
    "ROUMOUZ",
    1,
    1,
    "ROUMOUZ",
    "5784033",
    "Lybie",
    null,
    null,
    "roumouz",
    "ahmed@romoz.ly;asmahaji25@gmail.com",
    null,
    0,
    2,
    0,
    "ROUMOUZ",
    "ROUMOUZ",
    null,
    0,
    0,
    "roumouz",
    null,
    24137.98,
    21880.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1241,
    "IGH LITIGE",
    1,
    1,
    "IGH LITIGE",
    "111111",
    "TUNIS ",
    "0021698789893",
    "71960202",
    "IGH",
    "",
    null,
    0,
    2,
    0,
    "IGH LITIGE",
    "IGH LITIGE",
    null,
    0,
    0,
    "IGH",
    null,
    21697.14,
    22729.95,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1242,
    "BENETTO",
    1,
    1,
    "BENETTO",
    null,
    null,
    null,
    null,
    "benetto",
    "contact@bhatravel.com.tn ",
    null,
    0,
    2,
    0,
    "BENETTO",
    "BENETTO",
    null,
    0,
    0,
    "benetto",
    null,
    41334.83,
    22002.25,
    0,
    0,
    12600.0,
    0,
    0,
  ],
  [
    1243,
    " BACEM TRAVEL AGENCY",
    1,
    1,
    "BACEM TRAVEL AGENCY",
    "BACEM",
    null,
    null,
    "97 454 417",
    "BACEM",
    "medbm16@gmail.com",
    null,
    0,
    2,
    0,
    " BACEM TRAVEL AGENCY",
    " BACEM TRAVEL AGENCY",
    null,
    0,
    0,
    "BACEM",
    null,
    321.72,
    321.72,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    1244,
    "MHIRI TRAVEL LTIGE",
    1,
    1,
    "MHIRI TRAVEL LTIGE",
    null,
    "sfax bab bhar ",
    "55656777",
    null,
    "SFATS310Z",
    "",
    null,
    0,
    2,
    0,
    "MHIRI TRAVEL LTIGE",
    "MHIRI TRAVEL LTIGE",
    null,
    0,
    0,
    "MHIRI2018",
    null,
    10865.08,
    10867.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1245,
    "THE",
    1,
    1,
    "THE",
    "1266151V",
    "Immeuble Jaziri Av 14 Janv H.sousse ",
    "50694421",
    "73324304",
    "THE",
    "event@the-tn.com;comptabilite@the-tn.com",
    null,
    0,
    2,
    0,
    "THE",
    "THE",
    null,
    0,
    0,
    "THE ;THE",
    null,
    35306.66,
    35306.03,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    1249,
    "Zoom voyages",
    1,
    1,
    "Zoom voyages",
    "1573118f",
    null,
    "94 755 116",
    null,
    "zoomvoyage2018",
    "direction@zoomvoyage.com",
    null,
    0,
    2,
    0,
    "Zoom voyages",
    "Zoom voyages",
    null,
    0,
    0,
    "zoom",
    null,
    98228.49,
    92356.01,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    1252,
    "DOMINOS TRAVEL",
    1,
    1,
    "DOMINOS TRAVEL",
    "1376536w",
    null,
    null,
    null,
    "diminostravel;KZ0235;amel.kounouz1@gmail.com",
    "commercial@dominostravel.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "DOMINOS TRAVEL",
    "DOMINOS TRAVEL",
    null,
    0,
    0,
    "diminostravel",
    null,
    4271.85,
    4271.85,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1253,
    "ALAA VOYAGE LITIGE",
    1,
    1,
    "ALAA VOYAGE LITIGE",
    null,
    "immeuble yomn sangoor",
    "55973014",
    null,
    "MIRTS312J",
    "",
    null,
    0,
    2,
    0,
    "ALAA VOYAGE LITIGE",
    "ALAA VOYAGE LITIGE",
    null,
    0,
    0,
    "alaa2018",
    null,
    14400.63,
    14401.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1265,
    "AIGLE VOYAGE",
    1,
    0,
    "AIGLE VOYAGE",
    "145628",
    null,
    null,
    "22538111",
    "aiglevoyage",
    "booking@aigle-royal.t",
    null,
    0,
    2,
    0,
    "AIGLE VOYAGE",
    "AIGLE VOYAGE",
    null,
    0,
    0,
    "aiglevoyage",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1272,
    "Scapade Travel",
    0,
    0,
    "Scapade Travel",
    null,
    null,
    "21624139000",
    null,
    "TUNTS313L",
    "COMMERCIAL@S-CAPADE.COM;scapadetravel@gmail.com",
    null,
    0,
    7,
    0,
    "Scapade Travel",
    "Scapade Travel",
    null,
    0,
    0,
    null,
    "APTUN (216)24139000 - S-CAPADE TRAVEL AGENCY - A//E-COMMERCIAL@S-CAPADE.COM",
    2932.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1273,
    "GO-MAKKAH",
    0,
    0,
    "GO-MAKKAH",
    null,
    null,
    null,
    null,
    "GAETS3109",
    null,
    null,
    0,
    7,
    0,
    "GO-MAKKAH",
    "GO-MAKKAH",
    null,
    0,
    0,
    null,
    null,
    5067.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1276,
    "DHOYOUF MAKKA",
    1,
    0,
    "DHOYOUF MAKKA",
    null,
    "4054 sousse sahloul",
    "56270271",
    null,
    "dhouyoufmakka",
    "dhoyouf.makka@gmail.com",
    null,
    0,
    2,
    0,
    "DHOYOUF MAKKA",
    "DHOYOUF MAKKA",
    null,
    0,
    0,
    "dhouyoufmakka",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1277,
    "BITONYA SERVICES ",
    1,
    0,
    "BITONYA SERVICES",
    "abhtfe",
    null,
    "916932096",
    null,
    "bitonyaservices",
    "mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "BITONYA SERVICES ",
    "BITONYA SERVICES ",
    null,
    0,
    0,
    "bitonyaservices",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1278,
    "DIDA VOYAGE",
    1,
    0,
    "DIDA VOYAGE",
    "ajdcce",
    "annaba",
    "58313942",
    null,
    "didavoyages",
    "info@dida-voyages.com;info@dida-voyages.com",
    null,
    0,
    2,
    0,
    "DIDA VOYAGE",
    "DIDA VOYAGE",
    null,
    0,
    0,
    "didavoyages",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1279,
    "FLASH BOOKING",
    1,
    0,
    "FLASH BOOKING",
    "kdjhdhd",
    "tunisia",
    "58313942",
    null,
    "flashbooking",
    "mohamedaminekhammari@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "FLASH BOOKING",
    "FLASH BOOKING",
    null,
    0,
    0,
    "flashbooking",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1280,
    "KOUNOUZ HAY TADHAMO",
    1,
    1,
    "KOUNOUZ HAY TADHAMO",
    "gghfgfc",
    "tunis",
    "98792354",
    null,
    "kounouzhaytadha",
    "Benhassinekhalil1988@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ HAY TADHAMO",
    "KOUNOUZ HAY TADHAMO",
    null,
    0,
    0,
    "kounouzhaytadha",
    null,
    35576.5,
    33537.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1281,
    "test",
    0,
    0,
    "test",
    null,
    null,
    null,
    null,
    "test01",
    null,
    null,
    0,
    7,
    1,
    "test",
    "test",
    null,
    0,
    0,
    "test01",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1283,
    "CTM VOYAGE",
    1,
    1,
    "CTM VOYAGE",
    "KEFHDH",
    "c.produit@ctmvoyages.com",
    "0021625994470",
    null,
    "ctm",
    "gm@ctmvoyages.com; c.produit@ctmvoyages.com;finance@ctmvoyages.com",
    null,
    0,
    2,
    0,
    "CTM VOYAGE",
    "CTM VOYAGE",
    null,
    0,
    0,
    "ctm",
    null,
    294838.91,
    312476.59,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1284,
    "ATYPIQUE TRAVEL",
    1,
    0,
    "ATYPIQUE TRAVEL",
    "khdtv",
    "tunis",
    "29354438",
    null,
    "atypiquetravel",
    "youssef.zbidi@atypique.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "ATYPIQUE TRAVEL",
    "ATYPIQUE TRAVEL",
    null,
    0,
    0,
    "atypiquetravel",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1291,
    "VO IST 29/10/2018",
    1,
    0,
    "VO IST 29/10/2018",
    null,
    null,
    "58313916",
    null,
    "HELMI",
    "",
    null,
    0,
    2,
    0,
    "VO IST 29/10/2018",
    "VO IST 29/10/2018",
    null,
    0,
    0,
    "HELMI",
    null,
    29090.5,
    4509.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1292,
    "SYRINE TOURS",
    1,
    0,
    "SYRINE TOURS",
    "fhde",
    null,
    "22320320",
    null,
    "syrine2018",
    "mehdu@syrinetours.com.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "SYRINE TOURS",
    "SYRINE TOURS",
    null,
    0,
    0,
    "syrine2018",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1307,
    "VO IST 10/11/2018",
    1,
    0,
    "VO IST 10/11/2018",
    "djfhhy",
    null,
    "58313916",
    "helmi",
    "helmi",
    "",
    null,
    0,
    2,
    0,
    "VO IST 10/11/2018",
    "VO IST 10/11/2018",
    null,
    0,
    0,
    null,
    null,
    10577.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1438,
    "arak travel",
    0,
    0,
    "arak travel",
    null,
    null,
    null,
    null,
    "arak",
    null,
    null,
    0,
    7,
    0,
    "arak travel",
    "arak travel",
    null,
    0,
    0,
    "arak",
    null,
    27168.25,
    1777.2,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1439,
    "ben djemaa mohamed ali",
    0,
    0,
    "ben djemaa mohamed ali",
    null,
    null,
    null,
    null,
    "dalidjemaa ",
    null,
    null,
    0,
    7,
    0,
    "ben djemaa mohamed ali",
    "ben djemaa mohamed ali",
    null,
    0,
    0,
    "dalidjemaa ",
    null,
    1542.0,
    298.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1440,
    " Flowers Tours supp",
    0,
    0,
    "Flowers Tours supp",
    null,
    null,
    null,
    null,
    "Flowers",
    null,
    null,
    0,
    7,
    1,
    " Flowers Tours supp",
    " Flowers Tours supp",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1441,
    "MV SOUSSE",
    0,
    0,
    "MV SOUSSE",
    null,
    null,
    null,
    null,
    "mvsousse",
    null,
    null,
    0,
    7,
    0,
    "MV SOUSSE",
    "MV SOUSSE",
    null,
    0,
    0,
    "mvsousse",
    null,
    3368.0,
    700.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1442,
    "MV-Gabes",
    0,
    0,
    "MV-Gabes",
    null,
    null,
    "21675222968",
    null,
    "GAETS3100",
    null,
    null,
    0,
    7,
    0,
    "MV-Gabes",
    "MV-Gabes",
    null,
    0,
    0,
    "mvgabes",
    null,
    38849.5,
    14422.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1443,
    "safwa travel et services",
    0,
    1,
    "safwa travel et services",
    null,
    null,
    null,
    null,
    ";SAFWA",
    null,
    null,
    0,
    7,
    0,
    "safwa travel et services",
    "safwa travel et services",
    null,
    0,
    0,
    "safwa",
    null,
    49773.59,
    8987.0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    1444,
    "sun travel & resorts",
    0,
    0,
    "sun travel & resorts",
    null,
    null,
    null,
    null,
    "suntravel",
    null,
    null,
    0,
    7,
    0,
    "sun travel & resorts",
    "sun travel & resorts",
    null,
    0,
    0,
    "suntravel",
    null,
    78078.21,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1445,
    "world tunisia holidays",
    0,
    1,
    "world tunisia holidays",
    null,
    null,
    null,
    null,
    "worldtunisia",
    null,
    null,
    0,
    7,
    0,
    "world tunisia holidays",
    "world tunisia holidays",
    null,
    0,
    0,
    "worldtunisia",
    null,
    0,
    0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    1446,
    "شركة الكفاءة العالية",
    0,
    0,
    "شركة الكفاءة العالية",
    null,
    null,
    null,
    null,
    "med02",
    null,
    null,
    0,
    7,
    0,
    "شركة الكفاءة العالية",
    "شركة الكفاءة العالية",
    null,
    0,
    0,
    "med02",
    null,
    21949.0,
    21920.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1447,
    "ACTIVE TRAVEL",
    1,
    1,
    "ACTIVE TRAVEL",
    null,
    "lac 1 tunis",
    "24400920",
    null,
    "TUNTS314E",
    "saber.benfathallah@activetravel.com.tn ;mohamedaminekhammari@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "ACTIVE TRAVEL",
    "ACTIVE TRAVEL",
    null,
    0,
    0,
    "active",
    null,
    978030.7,
    981626.17,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1448,
    "KOUNOUZ JENDOUBA",
    1,
    1,
    "KOUNOUZ JENDOUBA",
    "D?CHHJJD",
    "kounouz.jendouba@gmail.com",
    "58514545",
    null,
    "kounouzjandouba",
    "kounouz.jendouba@gmail.com;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ JENDOUBA",
    "KOUNOUZ JENDOUBA",
    null,
    0,
    0,
    "kounouzjandouba;KZ548647",
    null,
    170835.67,
    166359.335,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    1449,
    "KOUNOUZ DJERBA",
    1,
    1,
    "KOUNOUZ DJERBA",
    "DJDHDG",
    null,
    null,
    null,
    "kounouzjerba",
    "kounouz.djerba@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ DJERBA",
    "KOUNOUZ DJERBA",
    null,
    0,
    0,
    "kounouzjerba;djerba",
    null,
    172120.16,
    162800.53,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    1450,
    "WTA ",
    1,
    1,
    "WTA",
    "11337294R",
    "BEB BHAR TUNIS",
    "21799210",
    null,
    "WTA",
    "nabil.adhoum@wta.tn;monosskef@yahoo.fr;nabil.adhoum@gmail.com;Cdaconsultingpro@gmail.com",
    null,
    0,
    2,
    0,
    "WTA ",
    "WTA ",
    null,
    0,
    0,
    "WTA",
    null,
    19148.83,
    19144.09,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1451,
    "ADAMAYA LITIGE",
    1,
    1,
    "ADAMAYA LITIGE",
    "1524660B/A/M/000",
    "ARIANA",
    "21324282",
    null,
    "adamaya;DAMAYA",
    "adamaya10082017@gmail.com",
    null,
    0,
    2,
    0,
    "ADAMAYA LITIGE",
    "ADAMAYA LITIGE",
    null,
    0,
    0,
    "adamaya",
    null,
    35142.82,
    20394.61,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1452,
    "MADINAH VOYAGES",
    0,
    0,
    "MADINAH VOYAGES",
    null,
    null,
    "21671337604",
    null,
    "TUNTS28AO;MIRTS3105;TUNRJ0100",
    "CONTACT.ARAKTRAVEL@GMAIL.COM//H",
    null,
    0,
    7,
    0,
    "MADINAH VOYAGES",
    "MADINAH VOYAGES",
    null,
    0,
    0,
    null,
    "APTUN +216 71 337 604 - MADINAH VOYAGES - A//E-CONTACT.ARAKTRAVEL@GMAIL.COM//H-+216 20 367276",
    93382.63,
    1444.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    1453,
    "TRAVEL PEDIA B2B ",
    1,
    1,
    "TRAVEL PEDIA B2B",
    "aetgdb",
    null,
    "21830204 ",
    null,
    "GFATS3104 ",
    "tourism@travelpedia.com.tn;manager@travelpedia.com.tn;kounouz.tunis@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "TRAVEL PEDIA B2B ",
    "TRAVEL PEDIA B2B ",
    null,
    0,
    0,
    "GFATS3104 ",
    null,
    32048.0,
    32048.0,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    1454,
    "TRAVEL PEDIA B2C",
    1,
    1,
    "TRAVEL PEDIA B2C",
    "JDBDH",
    null,
    "21830204 ",
    null,
    "GFATS3104;GAFTS3104;TUNTS31AI",
    "manager@travelpedia.com.tn;compta@travelpedia.com.tn;comptataysir@gmail.com;aero.c@topnet.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "TRAVEL PEDIA B2C",
    "TRAVEL PEDIA B2C",
    null,
    0,
    0,
    "GFATS3104",
    null,
    125862.7,
    123545.2,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    1455,
    "CATALEYA",
    1,
    1,
    "CATALEYA",
    "1137732/S",
    null,
    "52998838",
    "53388838",
    "catalya",
    "Bechirhajjar@hotmail.fr;contact@catalyatravel.com",
    null,
    0,
    2,
    0,
    "CATALEYA",
    "CATALEYA",
    null,
    0,
    0,
    "catalya",
    null,
    94023.2,
    90658.45,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    2384,
    "TUNUG0581|MAYASEEN TRAVEL & SERVICE",
    0,
    0,
    "MAYASEEN TRAVEL & SERVICE",
    null,
    null,
    "21675656100",
    null,
    "TUNUG0581",
    "MAYASEENTRAVELZARZIS@GMAIL.COM",
    null,
    0,
    6,
    0,
    "MAYASEEN TRAVEL & SERVICE",
    "MAYASEEN TRAVEL & SERVICE",
    null,
    0,
    0,
    null,
    "APDJE +216 75 656 100 - MAYASEEN TRAVEL & SERVICE - A//E-MAYASEENTRAVELZARZIS@GMAIL.COM",
    2604.4,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2385,
    "UMRAH 2019",
    1,
    0,
    "UMRAH 2019",
    null,
    null,
    null,
    null,
    "UMRAH2019",
    "finance@ekounouz.com",
    null,
    0,
    2,
    0,
    "UMRAH 2019",
    "UMRAH 2019",
    null,
    0,
    0,
    "UMRAH2019",
    null,
    7708956.46,
    2019.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2386,
    "AGSIMET",
    1,
    1,
    "AGSIMET",
    "ahayxb",
    null,
    "98403523",
    null,
    "Agsimet2018",
    "agisme2017@gmail.com",
    null,
    0,
    2,
    0,
    "AGSIMET",
    "AGSIMET",
    null,
    0,
    0,
    "Agsimet2018",
    null,
    0,
    0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    2388,
    "TIPLY2420|ALKAFA ALAALIA",
    0,
    0,
    "ALKAFA ALAALIA",
    null,
    null,
    "2182149176390926164779",
    null,
    "TIPLY2420",
    null,
    null,
    0,
    7,
    0,
    "ALKAFA ALAALIA",
    "ALKAFA ALAALIA",
    null,
    0,
    0,
    null,
    "APTIP +21821 4917639/0926164779 - ALKAFA ALAALIA - A//M-0922101074",
    17078.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2390,
    "TEKAYA",
    1,
    1,
    "TEKAYA",
    "1597122",
    "SOUSSE ",
    null,
    null,
    "takaya",
    "tekaya.travel.holiday@gmail.com;amel.kounouz1@gmail.com;resa1.tekayatravelholiday@gmail.comm",
    null,
    0,
    2,
    0,
    "TEKAYA",
    "TEKAYA",
    null,
    0,
    0,
    "takaya",
    null,
    58763.59,
    56838.408,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    2391,
    "TUNTS311V|ALWAYS TRAVEL &SERVICES",
    0,
    0,
    "ALWAYS TRAVEL &SERVICES",
    null,
    null,
    "21671829232",
    null,
    "TUNTS311V",
    null,
    null,
    0,
    7,
    0,
    "ALWAYS TRAVEL &SERVICES",
    "ALWAYS TRAVEL &SERVICES",
    null,
    0,
    0,
    null,
    "APTUN (216)71829232 - ALWAYS TRAVEL &SERVICES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2392,
    "3",
    0,
    0,
    "3",
    null,
    null,
    "55977662",
    "21671335444",
    "",
    null,
    null,
    0,
    7,
    1,
    "3",
    "3",
    null,
    0,
    0,
    null,
    "AP55977662//TUN +216 71 335 444 - MADINAH VOYAGES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2393,
    "DJETU0002|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "DJETU0002",
    null,
    null,
    0,
    6,
    0,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    1896.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2394,
    "BUISNEESE CLASSE",
    1,
    1,
    "BUISNEESE CLASSE",
    "1071342W",
    null,
    null,
    null,
    "bclasse",
    "reservation@businessclass-travel.com;direction@businessclass-travel.com",
    null,
    0,
    2,
    0,
    "BUISNEESE CLASSE",
    "BUISNEESE CLASSE",
    null,
    0,
    0,
    "bclasse",
    null,
    35920.15,
    35068.51,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    2395,
    "THALASSA TRAVEL LITIGE ",
    1,
    1,
    "THALASSA TRAVEL LITIGE ",
    null,
    "MAKNI CENTRE EL MENZAH 9",
    "28191111",
    "71874065",
    "TUNTS315A;TUNTS315C ;TUNTS315C",
    "sales.thalassa@gmail.com ;mohamedaminekhammari@gmail.com;thalassa.booking@topnet.tn;comptataysir@gmail.com ",
    null,
    0,
    2,
    0,
    "THALASSA TRAVEL LITIGE ",
    "THALASSA TRAVEL LITIGE ",
    null,
    0,
    0,
    "TUNTS315A",
    null,
    348183.8,
    342000.1,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2397,
    "voyage ist 20-12 siège   kmar travel  ",
    1,
    0,
    "voyage ist 20-12 siège   kmar travel",
    "cdvr",
    null,
    null,
    null,
    "compta",
    "",
    null,
    0,
    2,
    1,
    "voyage ist 20-12 siège   kmar travel  ",
    "voyage ist 20-12 siège   kmar travel  ",
    null,
    0,
    0,
    "compta",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2398,
    "ETSW",
    1,
    1,
    "ETSW",
    "1320575a",
    null,
    "25319334",
    null,
    "ETSW",
    "salwa@etsw-ltd.com;media@etsw-ltd.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "ETSW",
    "ETSW",
    null,
    0,
    0,
    "ETSW",
    null,
    11144.63,
    10763.4,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2400,
    "VO IST 29/08/2018",
    1,
    0,
    "VO IST 29/08/2018",
    "anis daf",
    null,
    null,
    null,
    "anisdaf",
    null,
    null,
    0,
    2,
    0,
    "VO IST 29/08/2018",
    "VO IST 29/08/2018",
    null,
    0,
    0,
    "anisdaf",
    null,
    19706.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2402,
    "KOUNOUZ EL KEF",
    1,
    1,
    "KOUNOUZ EL KEF",
    null,
    null,
    null,
    null,
    "kounouzkef",
    "commercial.essoussi@gmail.com, ramzi.qatar@hotmail.com, infokounouzkef@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ EL KEF",
    "KOUNOUZ EL KEF",
    null,
    0,
    0,
    "kounouzkef",
    null,
    115780.89,
    115332.609,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    2404,
    "TUNTS3187|BOUTAR VOYAGES",
    0,
    0,
    "BOUTAR VOYAGES",
    null,
    null,
    "21671240044",
    null,
    "TUNTS3187",
    null,
    null,
    0,
    7,
    0,
    "BOUTAR VOYAGES",
    "BOUTAR VOYAGES",
    null,
    0,
    0,
    null,
    "APTUN (216)71240044 - BOUTAR VOYAGES - A",
    3308.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2405,
    "TURQUOISE TRAVEL xxxxx",
    0,
    1,
    "TURQUOISE TRAVEL xxxxx",
    null,
    null,
    null,
    null,
    "",
    null,
    null,
    10000.0,
    7,
    0,
    "TURQUOISE TRAVEL xxxxx",
    "TURQUOISE TRAVEL xxxxx",
    null,
    0,
    0,
    null,
    null,
    4473.83,
    2683.94,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    2407,
    "2MA TRAVEL ",
    1,
    1,
    "2MA TRAVEL",
    "1601074/W",
    null,
    null,
    null,
    "dmatravel",
    "2ma.travel3@gmail.com",
    null,
    0,
    2,
    0,
    "2MA TRAVEL ",
    "2MA TRAVEL ",
    null,
    0,
    0,
    "dmatravel",
    null,
    371067.03,
    363448.19,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2408,
    "KOUNOUZ TOZEUR",
    1,
    1,
    "KOUNOUZ TOZEUR",
    null,
    null,
    null,
    null,
    "kz82314",
    "kounouz.travel.tozeur@gmail.com;BELHENI.MOADH@gmail.com;maher_zgallai@yahoo.fr",
    null,
    0,
    2,
    0,
    "KOUNOUZ TOZEUR",
    "KOUNOUZ TOZEUR",
    null,
    0,
    0,
    "kz82314",
    null,
    80718.72,
    73355.45,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2409,
    "ALKAFEA ALALIYA ",
    0,
    0,
    "ALKAFEA ALALIYA",
    null,
    null,
    null,
    null,
    "ALKAFEAALALIYA ",
    null,
    null,
    0,
    7,
    1,
    "ALKAFEA ALALIYA ",
    "ALKAFEA ALALIYA ",
    null,
    0,
    0,
    "ALKAFEAALALIYA ",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2410,
    "SB,TRAVEL",
    0,
    0,
    "SB,TRAVEL",
    null,
    null,
    null,
    null,
    "SBTRAVEL",
    null,
    null,
    0,
    7,
    0,
    "SB,TRAVEL",
    "SB,TRAVEL",
    null,
    0,
    0,
    "SBTRAVEL",
    null,
    141654.82,
    23275.644,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2411,
    "PROSPECTIVE TRAVEL",
    1,
    1,
    "PROSPECTIVE TRAVEL",
    "12547",
    "cité el kadhra",
    "55123917",
    null,
    "kz1230",
    "prespectivesservices@hotmail.fr",
    null,
    0,
    2,
    0,
    "PROSPECTIVE TRAVEL",
    "PROSPECTIVE TRAVEL",
    null,
    0,
    0,
    "kz1230",
    null,
    0,
    0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    2412,
    "GAFTS3104|TRAVELPEDIA",
    1,
    0,
    "TRAVELPEDIA",
    null,
    null,
    "21678826200",
    null,
    "GAFTS3104",
    null,
    null,
    0,
    2,
    1,
    "TRAVELPEDIA",
    "TRAVELPEDIA",
    null,
    0,
    0,
    null,
    "APGAF +216 78 826 200 - TRAVELPEDIA - A//GAF +216 78 826 200 - TRAVELPEDIA - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2414,
    "IRIS TRAVEL",
    1,
    1,
    "IRIS TRAVEL",
    null,
    "03 RUE CHOTT MARIEM MONT PLAISIR",
    "51807781",
    "71906709",
    "TUNTS3184",
    "sanatligue@gmail.com ;mohamedaminekhammari@gmail.com",
    "71906714",
    0,
    2,
    0,
    "IRIS TRAVEL",
    "IRIS TRAVEL",
    null,
    0,
    0,
    null,
    null,
    313720.09,
    315031.39,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    2416,
    "GAETS3104|FLOWERS TOURS",
    0,
    0,
    "FLOWERS TOURS",
    null,
    null,
    "21675221880",
    null,
    "GAETS3104",
    null,
    null,
    0,
    7,
    0,
    "FLOWERS TOURS",
    "FLOWERS TOURS",
    null,
    0,
    0,
    "Flowers",
    "APGAE (216)75221880 - FLOWERS TOURS - A//GAE 0021621426669",
    397.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2417,
    "VO IST DEC KMAR TRAVEL 2018",
    1,
    0,
    "VO IST DEC KMAR TRAVEL 2018",
    null,
    null,
    null,
    null,
    "123544",
    null,
    null,
    0,
    2,
    0,
    "VO IST DEC KMAR TRAVEL 2018",
    "VO IST DEC KMAR TRAVEL 2018",
    null,
    0,
    0,
    null,
    null,
    41925.0,
    41925.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2418,
    "TUNTS314R|MED TOURS",
    0,
    0,
    "MED TOURS",
    null,
    null,
    "21671253522",
    null,
    "TUNTS314R",
    null,
    null,
    0,
    7,
    0,
    "MED TOURS",
    "MED TOURS",
    null,
    0,
    0,
    null,
    "APTUN (216)71253522 - MED TOURS - A",
    28841.5,
    1550.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2419,
    "VO IST 03/02/2019",
    1,
    0,
    "VO IST 03/02/2019",
    null,
    null,
    null,
    null,
    "1022587",
    "",
    null,
    null,
    2,
    0,
    "VO IST 03/02/2019",
    "VO IST 03/02/2019",
    null,
    0,
    0,
    null,
    null,
    24850.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2422,
    "ENERGIE VOYAGES",
    1,
    1,
    "ENERGIE VOYAGES",
    "1556972/Q",
    null,
    null,
    null,
    "energies;SFATS311E",
    "CONTACT@ENERGIE-VOYAGES.COM.TN;Mohamed.hachfi@gmail.com",
    null,
    0,
    2,
    0,
    "ENERGIE VOYAGES",
    "ENERGIE VOYAGES",
    null,
    0,
    0,
    "energies",
    null,
    49274.18,
    47974.05,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    2423,
    "BLACK AND WHITE  TRAVEL AND EVENTS  ",
    1,
    1,
    "BLACK AND WHITE  TRAVEL AND EVENTS",
    null,
    null,
    null,
    null,
    "tourstravel;TUNTS315O;kz1232",
    "info@777tours.travel;info@bwhitetravel.com;info@bwhitetravel.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "BLACK AND WHITE  TRAVEL AND EVENTS  ",
    "BLACK AND WHITE  TRAVEL AND EVENTS  ",
    null,
    0,
    0,
    "tourstravel",
    null,
    20044.5,
    20046.3,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2435,
    "SERVICE GROUPES cc",
    0,
    0,
    "SERVICE GROUPES cc",
    null,
    null,
    null,
    null,
    "TUNTU0302",
    null,
    null,
    0,
    7,
    1,
    "SERVICE GROUPES cc",
    "SERVICE GROUPES cc",
    null,
    0,
    0,
    null,
    "APTUN TBA - SERVICE GROUPES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    2535,
    "TUNTU0302|SERVICE GROUPES",
    0,
    0,
    "SERVICE GROUPES",
    null,
    null,
    "",
    null,
    "TUNTU0302",
    null,
    null,
    0,
    7,
    0,
    "SERVICE GROUPES",
    "SERVICE GROUPES",
    null,
    0,
    0,
    null,
    "APTUN TBA - SERVICE GROUPES - A",
    10.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    3117,
    "2",
    0,
    0,
    "2",
    null,
    null,
    "21671335444",
    null,
    "",
    null,
    null,
    0,
    7,
    1,
    "2",
    "2",
    null,
    0,
    0,
    null,
    "APTUN +216 71 335 444 - MADINAH VOYAGES - A//55977662//740",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4302,
    "RAHMA VOYAGES cc",
    1,
    0,
    "RAHMA VOYAGES cc",
    null,
    null,
    "21672224092",
    null,
    "",
    "rahma-voyages@gnet.t",
    null,
    0,
    2,
    1,
    "RAHMA VOYAGES cc",
    "RAHMA VOYAGES cc",
    null,
    0,
    0,
    null,
    "APTUN (216)72224092 - RAHMA VOYAGES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4303,
    "KOUNOUZ KEBILI",
    1,
    1,
    "KOUNOUZ KEBILI",
    "fegfrg",
    null,
    null,
    null,
    "k.beli;Kkbeli",
    "KOUNOUZKEBILI@GMAIL.COM;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ KEBILI",
    "KOUNOUZ KEBILI",
    null,
    0,
    0,
    "k.beli;KKBELI",
    null,
    38803.88,
    35800.8,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    4304,
    "ARUM VOYAGE LITIGE",
    1,
    1,
    "ARUM VOYAGE LITIGE",
    "160005/c/a/m/000",
    "BIZERT",
    null,
    null,
    "arumvoyage",
    "arumvoyages@gmail.com",
    null,
    0,
    2,
    0,
    "ARUM VOYAGE LITIGE",
    "ARUM VOYAGE LITIGE",
    null,
    0,
    0,
    "arumvoyage",
    null,
    9516.15,
    9516.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4305,
    "KOUNOUZ LAOUINA",
    1,
    1,
    "KOUNOUZ LAOUINA",
    null,
    null,
    null,
    null,
    "KZ201254",
    "neharlog@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ LAOUINA",
    "KOUNOUZ LAOUINA",
    null,
    0,
    0,
    "kounouzlaouina;KZ201254",
    null,
    78983.44,
    71262.39,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4306,
    "KOUNOUZ GAFSA",
    1,
    1,
    "KOUNOUZ GAFSA",
    null,
    null,
    null,
    null,
    "kounouzgafsa",
    "kounouz.gafsa@gmail.com;amel.kounouz1@gmail.com;siwar.bekri@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ GAFSA",
    "KOUNOUZ GAFSA",
    null,
    0,
    0,
    "kounouzgafsa;KZ102368",
    null,
    129868.56,
    131560.0,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    4307,
    "1",
    0,
    0,
    "1",
    null,
    null,
    null,
    "21671335444",
    "",
    null,
    null,
    0,
    7,
    1,
    "1",
    "1",
    null,
    0,
    0,
    null,
    "APTKOK//TUN +216 71 335 444 - MADINAH VOYAGES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4309,
    "MIRTS311U|TURQUOISE VOYAGES",
    0,
    0,
    "TURQUOISE VOYAGES",
    null,
    null,
    "21673906244",
    null,
    "MIRTS311U",
    null,
    null,
    0,
    7,
    0,
    "TURQUOISE VOYAGES",
    "TURQUOISE VOYAGES",
    null,
    0,
    0,
    "TURQUOISE;turquoise",
    "APMIR (216)73906244 - TURQUOISE VOYAGES - A",
    129154.54,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4310,
    "MELIA TRAVEL",
    1,
    1,
    "MELIA TRAVEL",
    null,
    null,
    null,
    null,
    "KZ213250",
    "resa.meliatravel@gmail.com;MELIATRAVELBOOKING@GMAIL.COM",
    null,
    0,
    2,
    0,
    "MELIA TRAVEL",
    "MELIA TRAVEL",
    null,
    0,
    0,
    "KZ213250",
    null,
    74705.2,
    69879.42,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4311,
    "VO IST 16/03/2019",
    1,
    0,
    "VO IST 16/03/2019",
    null,
    null,
    null,
    null,
    "102236",
    null,
    null,
    0,
    2,
    0,
    "VO IST 16/03/2019",
    "VO IST 16/03/2019",
    null,
    0,
    0,
    null,
    null,
    30837.0,
    1558.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4312,
    "VO IST 22/03/2019 ARUM",
    1,
    0,
    "VO IST 22/03/2019 ARUM",
    null,
    null,
    null,
    null,
    "ARUMANN",
    null,
    null,
    0,
    2,
    0,
    "VO IST 22/03/2019 ARUM",
    "VO IST 22/03/2019 ARUM",
    null,
    0,
    0,
    null,
    null,
    22997.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4313,
    "VO IST 23/03/2019",
    1,
    0,
    "VO IST 23/03/2019",
    null,
    null,
    null,
    null,
    "DGFTHYUU",
    null,
    null,
    0,
    2,
    0,
    "VO IST 23/03/2019",
    "VO IST 23/03/2019",
    null,
    0,
    0,
    "FERGT4RY",
    null,
    16387.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4314,
    "VO IST 24/03/2019",
    1,
    0,
    "VO IST 24/03/2019",
    null,
    null,
    null,
    null,
    "REYTRY",
    null,
    null,
    0,
    2,
    0,
    "VO IST 24/03/2019",
    "VO IST 24/03/2019",
    null,
    0,
    0,
    "TRHYUY",
    null,
    27792.0,
    722.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4315,
    "VO IST 26/03/2019 ",
    1,
    0,
    "VO IST 26/03/2019",
    null,
    null,
    null,
    null,
    "GHTR",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/03/2019 ",
    "VO IST 26/03/2019 ",
    null,
    0,
    0,
    "GHYYJY",
    null,
    17998.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4316,
    "KOUNOUZ KAIRAOUE",
    1,
    1,
    "KOUNOUZ KAIRAOUE",
    null,
    null,
    null,
    null,
    "KZHKCDG",
    "kounouz.kairouan@gmail.com;Mohamed.hachfi@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ KAIRAOUE",
    "KOUNOUZ KAIRAOUE",
    null,
    0,
    0,
    "KZHKCDG",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4317,
    "KOUNOUZ BARDO",
    1,
    1,
    "KOUNOUZ BARDO",
    null,
    null,
    null,
    null,
    "KZ2522144",
    "kounouzbardo@gmail.com;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ BARDO",
    "KOUNOUZ BARDO",
    null,
    0,
    0,
    "KZ2522144",
    null,
    44735.63,
    42497.7,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4318,
    "VO IST 14/04/2019 ",
    1,
    0,
    "VO IST 14/04/2019",
    null,
    null,
    null,
    null,
    "1203658",
    null,
    null,
    0,
    2,
    0,
    "VO IST 14/04/2019 ",
    "VO IST 14/04/2019 ",
    null,
    0,
    0,
    "1203658",
    null,
    27866.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4320,
    "MAYAR VOYAGE",
    1,
    1,
    "MAYAR VOYAGE",
    "fhtj",
    null,
    null,
    null,
    "mayarvoyage",
    "manager@mayarvoyages.com",
    null,
    0,
    2,
    0,
    "MAYAR VOYAGE",
    "MAYAR VOYAGE",
    null,
    0,
    0,
    "mayarvoyage",
    null,
    0,
    0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4321,
    "RAWEH VOYAGE",
    1,
    1,
    "RAWEH VOYAGE",
    "1602501/Y/A/M/000",
    null,
    null,
    null,
    "KZ144664",
    "raweh.voyages@gmail.com",
    null,
    0,
    2,
    0,
    "RAWEH VOYAGE",
    "RAWEH VOYAGE",
    null,
    0,
    0,
    "KZ144664",
    null,
    56458.48,
    55672.52,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    4322,
    "KOUNOUZ TATAOUINE",
    1,
    1,
    "KOUNOUZ TATAOUINE",
    "KJIO",
    null,
    null,
    null,
    "KZ12348",
    "kounouz.tataouine@gmail.com;amel.kounouz1@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ TATAOUINE",
    "KOUNOUZ TATAOUINE",
    null,
    0,
    0,
    "KZ12348",
    null,
    258569.75,
    183839.5,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4323,
    "MV.KSOUR ESSEF ",
    0,
    0,
    "MV.KSOUR ESSEF",
    null,
    null,
    null,
    null,
    "47",
    null,
    null,
    0,
    7,
    0,
    "MV.KSOUR ESSEF ",
    "MV.KSOUR ESSEF ",
    null,
    0,
    0,
    "002;mvksour",
    null,
    15865.6,
    4936.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4324,
    "PANO-VACATIO",
    0,
    0,
    "PANO-VACATIO",
    null,
    null,
    null,
    null,
    "001",
    null,
    null,
    0,
    7,
    0,
    "PANO-VACATIO",
    "PANO-VACATIO",
    null,
    0,
    0,
    "001;panovacatio",
    null,
    22194.54,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4325,
    "MV.DJERBA ",
    0,
    0,
    "MV.DJERBA",
    null,
    null,
    null,
    null,
    "MV.DJERBA ",
    null,
    null,
    1000.0,
    7,
    0,
    "MV.DJERBA ",
    "MV.DJERBA ",
    null,
    0,
    0,
    "MVJERBA;mvjerba",
    null,
    54326.02,
    189.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4326,
    "FÉDÉRATION ESCRIME  B2C.",
    0,
    0,
    "FÉDÉRATION ESCRIME  B2C.",
    null,
    null,
    null,
    null,
    "kz77777",
    "monosskef@yahoo.fr ",
    null,
    0,
    2,
    0,
    "FÉDÉRATION ESCRIME  B2C.",
    "FÉDÉRATION ESCRIME  B2C.",
    null,
    0,
    0,
    "kz77777",
    null,
    73724.85,
    597.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4327,
    "SOCIETE TUNIS  B2C.",
    1,
    0,
    "SOCIETE TUNIS  B2C.",
    "frgtg",
    null,
    null,
    null,
    "kz225588",
    "monosskef@yahoo.fr",
    null,
    0,
    2,
    1,
    "SOCIETE TUNIS  B2C.",
    "SOCIETE TUNIS  B2C.",
    null,
    0,
    0,
    "kz225588",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4328,
    "DALI BEN YEDDER B2C.",
    1,
    1,
    "DALI BEN YEDDER B2C.",
    "gnhy",
    null,
    null,
    null,
    "",
    "dalybenyedder@gmail.com",
    null,
    0,
    2,
    0,
    "DALI BEN YEDDER B2C.",
    "DALI BEN YEDDER B2C.",
    null,
    0,
    0,
    null,
    null,
    60869.68,
    3770.4,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4329,
    "REZGUI VOYAGES ET LOISIRS",
    1,
    1,
    "REZGUI VOYAGES ET LOISIRS",
    "644448",
    null,
    null,
    null,
    "kb225",
    "dg@rezguivoyages.com;info@rezguivoyages.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "REZGUI VOYAGES ET LOISIRS",
    "REZGUI VOYAGES ET LOISIRS",
    null,
    0,
    0,
    "kb225",
    null,
    63776.06,
    60116.61,
    0,
    0,
    5000.0,
    0,
    0,
    1,
  ],
  [
    4330,
    "VISA VOYAGE LITIGE",
    1,
    1,
    "VISA VOYAGE LITIGE",
    "5446",
    null,
    null,
    null,
    "kb8794;TUNTS31B9",
    "Tourisme@visa-voyages.com;finance@visa-voyages.com;commercial@visa-voyages.com",
    null,
    0,
    2,
    0,
    "VISA VOYAGE LITIGE",
    "VISA VOYAGE LITIGE",
    null,
    0,
    0,
    "visa voyage",
    null,
    12559.0,
    10000.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4331,
    "VOYAGES PRIVEE ",
    1,
    1,
    "VOYAGES PRIVEE",
    null,
    "13 BIS RUE DES ETATS UNIS TUNIS",
    "71840342                                            ",
    null,
    "TUNTS3141",
    "tunis@ekounouzcom;houdabensalem@voyagesprives.tn;amel.kounouz1@gmail.com;khadija.selmi@explooration.com",
    null,
    0,
    2,
    0,
    "VOYAGES PRIVEE ",
    "VOYAGES PRIVEE ",
    null,
    0,
    0,
    null,
    null,
    113152.2,
    107167.95,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4332,
    "TUNTU08TA|MADINAH VOYAGES",
    0,
    0,
    "MADINAH VOYAGES",
    null,
    null,
    "21671335444",
    null,
    "TUNTU08TA",
    null,
    null,
    0,
    7,
    0,
    "MADINAH VOYAGES",
    "MADINAH VOYAGES",
    null,
    0,
    0,
    null,
    "APTUN +216 71 335 444 - MADINAH VOYAGES - A",
    718.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4333,
    "TOP PRESTIGE TRAVEL",
    1,
    1,
    "TOP PRESTIGE TRAVEL",
    "1122456/p",
    "résidence diwane,les jardins du lac 2",
    "50405541",
    "71968035",
    "TUNTS316Z",
    "topprestigetravel@gmail.com;amel.kounouz1@gmail.com",
    null,
    30000.0,
    2,
    0,
    "TOP PRESTIGE TRAVEL",
    "TOP PRESTIGE TRAVEL",
    null,
    0,
    0,
    null,
    null,
    70264.75,
    70264.75,
    0,
    0,
    30000.0,
    0,
    0,
  ],
  [
    4335,
    "TTI",
    1,
    1,
    "TTI",
    "1600792/R",
    null,
    null,
    null,
    "",
    "booking.tti@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "TTI",
    "TTI",
    null,
    0,
    0,
    "KZTTI",
    null,
    8000.94,
    6707.8,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    4336,
    "FIVE STARS ",
    1,
    1,
    "FIVE STARS",
    "1612681/Z",
    null,
    null,
    null,
    "KZ569453",
    "contact@5starstravel.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "FIVE STARS ",
    "FIVE STARS ",
    null,
    0,
    0,
    "KZ569453",
    null,
    19959.07,
    19862.07,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    4337,
    "ELIT + ",
    1,
    1,
    "ELIT +",
    "2154864",
    null,
    null,
    null,
    "kz12548641",
    "ste.elite@planet.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "ELIT + ",
    "ELIT + ",
    null,
    0,
    0,
    "kz12548641",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4338,
    "SV SAMIA ",
    1,
    0,
    "SV SAMIA",
    null,
    null,
    null,
    null,
    "anis",
    "finance@kounouz.com;comptataysir@gmail.com;zorgatisouhaiel1993@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "SV SAMIA ",
    "SV SAMIA ",
    null,
    0,
    0,
    "anis",
    null,
    1964543.0,
    1966657.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4339,
    "SALES MANSOUR PP",
    1,
    0,
    "SALES MANSOUR PP",
    "15484",
    null,
    null,
    null,
    "SALES",
    "sales@elmansourtravel.com",
    null,
    0,
    2,
    0,
    "SALES MANSOUR PP",
    "SALES MANSOUR PP",
    null,
    0,
    0,
    null,
    null,
    3060.8,
    3018.2,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4340,
    "MAHFOUDH SAYEDI B2C.",
    0,
    2,
    "MAHFOUDH SAYEDI B2C.",
    "0234021",
    null,
    "98605373",
    null,
    "KZ49809",
    "finance@ekounouz.com;comptataysir@gmail.com;saymah2@gmail.com",
    null,
    0,
    2,
    0,
    "MAHFOUDH SAYEDI B2C.",
    "MAHFOUDH SAYEDI B2C.",
    null,
    0,
    0,
    "KZ49809",
    null,
    19092.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4341,
    "Hajj 2010 Negospace Gestio",
    0,
    0,
    "Hajj 2010 Negospace Gestio",
    null,
    null,
    null,
    null,
    "TUNTU0253",
    null,
    null,
    0,
    7,
    0,
    "Hajj 2010 Negospace Gestio",
    "Hajj 2010 Negospace Gestio",
    null,
    0,
    0,
    null,
    null,
    5.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4345,
    "THINK TRAVEL",
    1,
    1,
    "THINK TRAVEL",
    "1612114/W",
    null,
    null,
    null,
    "kz4244562",
    "think-agence@outlook.fr",
    null,
    0,
    2,
    0,
    "THINK TRAVEL",
    "THINK TRAVEL",
    null,
    0,
    0,
    "kz4244562",
    null,
    58783.19,
    62117.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4346,
    "AFRICANA VOYAGES",
    1,
    2,
    "AFRICANA VOYAGES",
    null,
    "54 RUE HABIB MAAZOUN SFAX",
    "74 227 400",
    null,
    "SFATS310H",
    "Jamil@africana-voyages.com ",
    null,
    0,
    2,
    0,
    "AFRICANA VOYAGES",
    "AFRICANA VOYAGES",
    null,
    0,
    0,
    null,
    null,
    12224.0,
    12224.2,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4347,
    "TRAVEL NETWORK",
    1,
    1,
    "TRAVEL NETWORK",
    "511560650",
    "RUE HABIB BOURGIBA LAMTA MOUNASTIR ",
    null,
    null,
    "MIRTS3138",
    "contact_ttn@topnet.tn;momolari2012@gmail.com",
    null,
    0,
    2,
    0,
    "TRAVEL NETWORK",
    "TRAVEL NETWORK",
    null,
    0,
    0,
    "KZ210021",
    null,
    406701.4,
    392785.789,
    0,
    0,
    30000.0,
    0,
    0,
  ],
  [
    4349,
    "GO GO TRAVEL",
    1,
    1,
    "GO GO TRAVEL",
    "159095CAM000",
    "ROUTE TOURISTIQUE KANTAOUI",
    "98400057",
    null,
    "GOGO",
    "contact@gogotravel.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "GO GO TRAVEL",
    "GO GO TRAVEL",
    null,
    0,
    0,
    "GOGO",
    null,
    17502.43,
    17507.85,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    4350,
    "MONASTIR PRIME TRAVEL LITIGE",
    1,
    1,
    "MONASTIR PRIME TRAVEL LITIGE",
    "1524864",
    "avenu mohamed mhall monastir ",
    null,
    null,
    "primetravel;MIRTS3140",
    "monastirtravel@yahoo.com",
    null,
    0,
    2,
    0,
    "MONASTIR PRIME TRAVEL LITIGE",
    "MONASTIR PRIME TRAVEL LITIGE",
    null,
    0,
    0,
    "primetravel",
    null,
    110658.5,
    104180.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4351,
    "DIRECT TOUR ",
    1,
    1,
    "DIRECT TOUR",
    "1622138/K",
    null,
    null,
    null,
    "KZ2154",
    "directour19@gmail.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "DIRECT TOUR ",
    "DIRECT TOUR ",
    null,
    0,
    0,
    "KZ2154",
    null,
    0,
    0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4352,
    "TUNTU0251|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "TUNTU0251",
    null,
    null,
    0,
    7,
    0,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    4008.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4354,
    "VOYAGES DE LUXE",
    1,
    1,
    "VOYAGES DE LUXE",
    null,
    "HABIB BOURGUIBA IMMEUBLE NOUISSER B11 sousse",
    null,
    "73210040",
    "MIRTS312H;MIRTS313",
    "voyagedeluxe@gnet.t",
    null,
    0,
    2,
    0,
    "VOYAGES DE LUXE",
    "VOYAGES DE LUXE",
    null,
    0,
    0,
    "kz115641",
    null,
    275808.61,
    270615.5,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    4355,
    "TOETS310A|OASIS TOURS 2",
    0,
    0,
    "OASIS TOURS 2",
    null,
    null,
    "21620573673",
    null,
    "TOETS310A",
    null,
    null,
    0,
    7,
    0,
    "OASIS TOURS 2",
    "OASIS TOURS 2",
    null,
    0,
    0,
    null,
    "APTOE +216 20 573 673 - OASIS TOURS 2 - A",
    2759.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4357,
    "Haroun Voyages",
    0,
    0,
    "Haroun Voyages",
    null,
    null,
    null,
    null,
    "GAETS310F",
    null,
    null,
    0,
    7,
    0,
    "Haroun Voyages",
    "Haroun Voyages",
    null,
    0,
    0,
    null,
    null,
    10909.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4358,
    "ITA B2C",
    1,
    2,
    "ITA B2C",
    "47714",
    "TUNIS",
    null,
    null,
    "KZ0127",
    "dorsaf.bc@gmail.com",
    null,
    0,
    2,
    0,
    "ITA B2C",
    "ITA B2C",
    null,
    0,
    0,
    "KZ0127",
    null,
    15625.38,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4359,
    "Tunisair",
    0,
    0,
    "Tunisair",
    null,
    null,
    null,
    null,
    "TUNTU0214",
    null,
    null,
    0,
    7,
    0,
    "Tunisair",
    "Tunisair",
    null,
    0,
    0,
    null,
    null,
    711.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4360,
    "HEY TUNISA",
    1,
    1,
    "HEY TUNISA",
    "21254",
    null,
    null,
    null,
    "KZ012547",
    "naouel.belaifa@heytunisia.com;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "HEY TUNISA",
    "HEY TUNISA",
    null,
    0,
    0,
    "KZ012547",
    null,
    0,
    0,
    0,
    0,
    7000.0,
    0,
    0,
  ],
  [
    4361,
    "YATA LITIGE",
    1,
    1,
    "YATA LITIGE",
    "02151021",
    null,
    null,
    null,
    "KZ021254",
    "Contact@yata.tn;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "YATA LITIGE",
    "YATA LITIGE",
    null,
    0,
    0,
    "KZ021254",
    null,
    6413.74,
    6414.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4362,
    "SARAYA TRAVEL",
    1,
    1,
    "SARAYA TRAVEL",
    "1616641H",
    "MONGI SLIM LAOUINA",
    "98176981",
    null,
    "KZCDSG",
    "ahmed@saraya.tn;mohamedaminekhammari@gmail.com;flehahmed@gmail.com;ahlem@saraya.t",
    null,
    0,
    2,
    0,
    "SARAYA TRAVEL",
    "SARAYA TRAVEL",
    null,
    0,
    0,
    "KZCDSG",
    null,
    242865.562,
    231912.1,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4364,
    "VO IST 29/06/2019",
    1,
    0,
    "VO IST 29/06/2019",
    "GFTG",
    null,
    null,
    null,
    "FGDV",
    null,
    null,
    0,
    2,
    0,
    "VO IST 29/06/2019",
    "VO IST 29/06/2019",
    null,
    0,
    0,
    "VFRB",
    null,
    27356.0,
    3060.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4366,
    "AMAZON TOURS",
    1,
    0,
    "AMAZON TOURS",
    null,
    "TRIPOLI",
    null,
    null,
    "TIPLY2293 ;LAQLY2854;QZLLY2158;TIPLY2162;TIPLY2293;SEBLY2113;AMMJO279P;TIPLY2835;TIPLY2483;TIPLY2163;TUNTU0214;TIPLY25B8;TIPLY2851 ",
    "alaa.accounts@amazon.ly;lamia.accounts@amazon.ly",
    null,
    0,
    2,
    0,
    "AMAZON TOURS",
    "AMAZON TOURS",
    null,
    0,
    0,
    "amazo",
    null,
    382515.6,
    373440.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4368,
    "CHARFEDDINE VOYAGE ",
    1,
    1,
    "CHARFEDDINE VOYAGE",
    "DVBSDFVT",
    "SOUSSE ",
    "53220222",
    null,
    "KZZ244",
    "charfeddine.sof@gmail.com",
    null,
    0,
    2,
    0,
    "CHARFEDDINE VOYAGE ",
    "CHARFEDDINE VOYAGE ",
    null,
    0,
    0,
    "KZZ244",
    null,
    25108.77,
    25070.169,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    4372,
    "VO IST 11/07/2019",
    1,
    0,
    "VO IST 11/07/2019",
    null,
    null,
    null,
    null,
    "2548",
    null,
    null,
    0,
    2,
    0,
    "VO IST 11/07/2019",
    "VO IST 11/07/2019",
    null,
    0,
    0,
    null,
    null,
    34169.96,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4373,
    "TIPLY2293|AMAZO",
    1,
    0,
    "AMAZO",
    null,
    null,
    "3362196",
    null,
    "",
    null,
    null,
    0,
    2,
    1,
    "AMAZO",
    "AMAZO",
    null,
    0,
    0,
    null,
    "APTIP 3362196-97 - AMAZON - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4374,
    "VO IST 17/07/2019",
    1,
    0,
    "VO IST 17/07/2019",
    "023645",
    null,
    null,
    null,
    "0.312",
    null,
    null,
    0,
    2,
    0,
    "VO IST 17/07/2019",
    "VO IST 17/07/2019",
    null,
    0,
    0,
    "0.16",
    null,
    37395.72,
    893.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4375,
    "VO IST 25/07/2019",
    1,
    0,
    "VO IST 25/07/2019",
    "SDFRH",
    null,
    null,
    null,
    "FVGRE",
    null,
    null,
    0,
    2,
    0,
    "VO IST 25/07/2019",
    "VO IST 25/07/2019",
    null,
    0,
    0,
    "FRH",
    null,
    15686.25,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4376,
    "GAETS310G|WKAYET VOYAGES",
    0,
    0,
    "WKAYET VOYAGES",
    null,
    null,
    "21675212933",
    null,
    "GAETS310G",
    null,
    null,
    0,
    7,
    0,
    "WKAYET VOYAGES",
    "WKAYET VOYAGES",
    null,
    0,
    0,
    null,
    "APGAE +21675212933 - WKAYET VOYAGES - A",
    768.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4377,
    "HAJJ 2019",
    1,
    0,
    "HAJJ 2019",
    "211698",
    null,
    null,
    null,
    "HAJJ2019",
    null,
    null,
    0,
    2,
    0,
    "HAJJ 2019",
    "HAJJ 2019",
    null,
    0,
    0,
    "HAJJ2019",
    null,
    261547.5,
    4488.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4379,
    "SFATS2106|BAHRY TRAVEL AGENCY",
    0,
    0,
    "BAHRY TRAVEL AGENCY",
    null,
    null,
    "21674227253",
    null,
    "SFATS2106",
    null,
    null,
    0,
    7,
    0,
    "BAHRY TRAVEL AGENCY",
    "BAHRY TRAVEL AGENCY",
    null,
    0,
    0,
    null,
    "APSFA +216 74 227 253 - BAHRY TRAVEL AGENCY - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4380,
    "VO IST 29/07/2019",
    1,
    0,
    "VO IST 29/07/2019",
    "BGGHY",
    null,
    null,
    null,
    "FGRH",
    null,
    null,
    0,
    2,
    0,
    "VO IST 29/07/2019",
    "VO IST 29/07/2019",
    null,
    0,
    0,
    "ZEGHTR",
    null,
    27012.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4381,
    "VO IST 01/08/2019",
    1,
    0,
    "VO IST 01/08/2019",
    "DFBGHTYK",
    null,
    null,
    null,
    "DGTRH",
    null,
    null,
    0,
    2,
    0,
    "VO IST 01/08/2019",
    "VO IST 01/08/2019",
    null,
    0,
    0,
    "DFGHTY",
    null,
    13601.75,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4384,
    "KOUNOUZ SIDI BOUZID",
    1,
    1,
    "KOUNOUZ SIDI BOUZID",
    "/KXOXIDFFJ",
    null,
    null,
    null,
    "kz21348",
    "kadrilotfi79@gmail.com;kounouz.sidibou@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ SIDI BOUZID",
    "KOUNOUZ SIDI BOUZID",
    null,
    0,
    0,
    "kz21348",
    null,
    225149.58,
    215656.2,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    4385,
    "DB TRAVEL",
    1,
    1,
    "DB TRAVEL",
    "1561992/D",
    null,
    null,
    null,
    "KZ0264",
    "d.bahroun@dbtravelagency.net;amel.kounouz1@gmail.com",
    null,
    0,
    2,
    0,
    "DB TRAVEL",
    "DB TRAVEL",
    null,
    0,
    0,
    "KZ0264",
    null,
    49268.4,
    49268.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4386,
    "AL KHALILY TRAVEL ",
    1,
    1,
    "AL KHALILY TRAVEL",
    "1600318/S",
    null,
    null,
    null,
    "KZ02146",
    "resa.alktravel@gmail.com;direct.alktravel@gmail.com",
    null,
    0,
    2,
    0,
    "AL KHALILY TRAVEL ",
    "AL KHALILY TRAVEL ",
    null,
    0,
    0,
    "KZ02146",
    null,
    129119.46,
    121561.81,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    4391,
    "WADI SYPHANUS FOR TRAVEL",
    1,
    0,
    "WADI SYPHANUS FOR TRAVEL",
    null,
    null,
    "218913691949",
    null,
    "",
    null,
    null,
    0,
    2,
    1,
    "WADI SYPHANUS FOR TRAVEL",
    "WADI SYPHANUS FOR TRAVEL",
    null,
    0,
    0,
    null,
    "APTIP +218913691949 - WADI SYPHANUS FOR TRAVEL - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4393,
    "Al WAFED  NOUREDDINE 1 ",
    1,
    1,
    "Al WAFED  NOUREDDINE 1 ",
    "GHJDV",
    null,
    null,
    null,
    "",
    "",
    null,
    0,
    2,
    0,
    "Al WAFED  NOUREDDINE 1 ",
    "Al WAFED  NOUREDDINE 1 ",
    null,
    0,
    0,
    "nourdinwafd",
    null,
    129318.42,
    135618.46,
    0,
    0,
    0,
    5000.0,
    0,
  ],
  [
    4394,
    "AERO.C TRAVEL",
    1,
    1,
    "AERO.C TRAVEL",
    null,
    "TUNIS ",
    "27343936",
    null,
    "TUNTS31D5;SFATS311C;DJETS3111",
    "aero.c@topnet.tn;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "AERO.C TRAVEL",
    "AERO.C TRAVEL",
    null,
    0,
    0,
    "AERO.C",
    null,
    124143.18,
    114417.0,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    4395,
    "EXPLOORATION GAFSA ",
    1,
    1,
    "EXPLOORATION GAFSA",
    "21548",
    null,
    null,
    null,
    "KZ20125000",
    "EXPLOORATION.KOUNOUZ.TRAVEL@GMAIL.COM;BELHENI.MOADH@gmail.com;maher_zgallai@yahoo.fr",
    null,
    0,
    2,
    0,
    "EXPLOORATION GAFSA ",
    "EXPLOORATION GAFSA ",
    null,
    0,
    0,
    "KZ20125000;kzbgrnds",
    null,
    27387.03,
    16635.1,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4397,
    "VO IST 29/08/2019",
    1,
    0,
    "VO IST 29/08/2019",
    "CXBV",
    null,
    null,
    null,
    "FDGHYJ",
    null,
    null,
    0,
    2,
    0,
    "VO IST 29/08/2019",
    "VO IST 29/08/2019",
    null,
    0,
    0,
    "HDFBNTY",
    null,
    16596.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4398,
    "VO IST 26/08/2019",
    1,
    0,
    "VO IST 26/08/2019",
    "XVB",
    null,
    null,
    null,
    "SQCG",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/08/2019",
    "VO IST 26/08/2019",
    null,
    0,
    0,
    "SFEGF",
    null,
    29755.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4399,
    "VO IST 02/09/2019",
    1,
    0,
    "VO IST 02/09/2019",
    "DFHYTJ",
    null,
    null,
    null,
    "FDBHTH",
    null,
    null,
    0,
    2,
    0,
    "VO IST 02/09/2019",
    "VO IST 02/09/2019",
    null,
    0,
    0,
    "DFGHRYJ",
    null,
    28470.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4401,
    "VO IST 19/08/2019 ",
    1,
    0,
    "VO IST 19/08/2019",
    "CVRG",
    "SDFFD",
    "CVG",
    null,
    "FGRGH",
    null,
    null,
    0,
    2,
    0,
    "VO IST 19/08/2019 ",
    "VO IST 19/08/2019 ",
    null,
    0,
    0,
    null,
    null,
    27874.5,
    2094.75,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4402,
    "VO IST 24/08/2019",
    1,
    0,
    "VO IST 24/08/2019",
    "DGBRTH",
    null,
    null,
    null,
    "SFCR",
    null,
    null,
    0,
    2,
    0,
    "VO IST 24/08/2019",
    "VO IST 24/08/2019",
    null,
    0,
    0,
    "SCFC",
    null,
    22049.75,
    2418.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4405,
    "VO IST 07/09/2019",
    1,
    0,
    "VO IST 07/09/2019",
    "QSFER",
    "ASFFG",
    "QSCFE",
    null,
    "SDCEFG",
    null,
    null,
    0,
    2,
    0,
    "VO IST 07/09/2019",
    "VO IST 07/09/2019",
    null,
    0,
    0,
    "SQFVEF",
    null,
    32305.5,
    2673.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    4407,
    "VO IST 05/09/2019",
    1,
    0,
    "VO IST 05/09/2019",
    "SFEF",
    null,
    null,
    null,
    "SFE2F",
    null,
    null,
    0,
    2,
    0,
    "VO IST 05/09/2019",
    "VO IST 05/09/2019",
    null,
    0,
    0,
    "SEGF",
    null,
    29473.0,
    3693.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5398,
    "LAMAR TRAVEL ",
    1,
    1,
    "LAMAR TRAVEL",
    "DVRG",
    "SOUSSE",
    null,
    null,
    "kz0.01145",
    "booking@lamartravel.com.t",
    null,
    0,
    2,
    0,
    "LAMAR TRAVEL ",
    "LAMAR TRAVEL ",
    null,
    0,
    0,
    "kz0.01145",
    null,
    9187.79,
    9188.49,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    5400,
    "VO IST 04/10/2019",
    1,
    0,
    "VO IST 04/10/2019",
    "FBTJY",
    null,
    null,
    null,
    "KZ04102019",
    null,
    null,
    0,
    2,
    0,
    "VO IST 04/10/2019",
    "VO IST 04/10/2019",
    null,
    0,
    0,
    "KZ04102019",
    null,
    11880.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5401,
    "DÉCOMPTE DOUANE B2B",
    1,
    0,
    "DÉCOMPTE DOUANE B2B",
    "VHY",
    null,
    null,
    null,
    "FGREZGGRH",
    null,
    null,
    0,
    2,
    0,
    "DÉCOMPTE DOUANE B2B",
    "DÉCOMPTE DOUANE B2B",
    null,
    0,
    0,
    "FGREZGGRH",
    null,
    23974.13,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5402,
    "WALID MAKDOULI B2C",
    0,
    0,
    "WALID MAKDOULI B2C",
    "41415356",
    null,
    null,
    null,
    "KZ000333",
    null,
    null,
    0,
    2,
    0,
    "WALID MAKDOULI B2C",
    "WALID MAKDOULI B2C",
    null,
    0,
    0,
    "KZ000333",
    null,
    9773.5,
    224.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5403,
    "TAYSIR SOLUTIONS  B2C",
    0,
    0,
    "TAYSIR SOLUTIONS  B2C",
    "XVDFBR",
    null,
    null,
    null,
    "KZ919",
    null,
    null,
    0,
    2,
    0,
    "TAYSIR SOLUTIONS  B2C",
    "TAYSIR SOLUTIONS  B2C",
    null,
    0,
    0,
    "KZ919",
    null,
    15312.93,
    127.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5404,
    "TOPITOP ",
    1,
    1,
    "TOPITOP ",
    "SCRGHT",
    null,
    null,
    null,
    "SDFERTG00",
    "topitovoyage@gmail.com",
    null,
    0,
    2,
    0,
    "TOPITOP ",
    "TOPITOP ",
    null,
    0,
    0,
    "SDFERTG00",
    null,
    5793.44,
    5794.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5406,
    "PURE TRAVEL HAMMAMET ",
    1,
    1,
    "PURE TRAVEL HAMMAMET",
    null,
    "hammaet ",
    "29 361408",
    null,
    "TUNTS317J",
    "ste.pure.travel@topnet.tn;compta@pure-travel.tn;ticketingpuretravel@gmail.com",
    null,
    0,
    2,
    0,
    "PURE TRAVEL HAMMAMET ",
    "PURE TRAVEL HAMMAMET ",
    null,
    0,
    0,
    "PURE",
    null,
    267903.73,
    256329.23,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    5407,
    "TUNTS318U|INTER BUSINESS TRAVEL",
    0,
    0,
    "INTER BUSINESS TRAVEL",
    null,
    null,
    "21624434355",
    null,
    "TUNTS318U",
    null,
    null,
    0,
    7,
    0,
    "INTER BUSINESS TRAVEL",
    "INTER BUSINESS TRAVEL",
    null,
    0,
    0,
    null,
    "APTUN (216)24434355 - INTER BUSINESS TRAVEL - A//TUN +216 71 335 444 - MADINAH VOYAGES - A",
    138057.1,
    8081.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5409,
    "WIFAK TRAVEL",
    1,
    1,
    "WIFAK TRAVEL",
    "FFR4GH",
    null,
    null,
    null,
    "kzwifak",
    "hatem.belkhiriabelkhiria@gmail.com",
    null,
    0,
    2,
    0,
    "WIFAK TRAVEL",
    "WIFAK TRAVEL",
    null,
    0,
    0,
    "kzwifak",
    null,
    28627.105,
    28602.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5410,
    " AXA TOURS",
    1,
    1,
    "AXA TOURS",
    "1546951/e",
    "avenue moncef bey-hammamet",
    "98 602 277 oussema harrabi ",
    "72 249 653",
    "TUNTS318V",
    "info.axatours@gmail.com;sales2.axatours@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    " AXA TOURS",
    " AXA TOURS",
    null,
    0,
    0,
    "KZAXATOURS",
    null,
    96017.27,
    91903.5,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    5411,
    "VO IST 29/10/2019",
    1,
    0,
    "VO IST 29/10/2019",
    "VHHTTH",
    null,
    null,
    null,
    "KZ29102019",
    null,
    null,
    0,
    2,
    0,
    "VO IST 29/10/2019",
    "VO IST 29/10/2019",
    null,
    0,
    0,
    "KZ29102019",
    null,
    11669.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5412,
    "EXPLOORATION MSAKE",
    1,
    1,
    "EXPLOORATION MSAKE",
    null,
    null,
    null,
    null,
    "KZEXPLOMSAKEN11",
    "fouedsalem73@gmail.com;Kounouz.msaken@gmail.com",
    null,
    0,
    2,
    0,
    "EXPLOORATION MSAKE",
    "EXPLOORATION MSAKE",
    null,
    0,
    0,
    "KZEXPLOMSAKEN11;KZ7784540",
    null,
    167798.01,
    160077.61,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    5413,
    "SFATS3119|TULIP TRAVEL AND EVENTS",
    0,
    0,
    "TULIP TRAVEL AND EVENTS",
    null,
    null,
    "21674202315",
    null,
    "SFATS3119",
    null,
    null,
    0,
    7,
    0,
    "TULIP TRAVEL AND EVENTS",
    "TULIP TRAVEL AND EVENTS",
    null,
    0,
    0,
    "1050",
    "APSFA +216 74 202 315 - TULIP TRAVEL AND EVENTS - A",
    176920.1,
    3616.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5414,
    "VO IST 05/11/2019",
    1,
    0,
    "VO IST 05/11/2019",
    ".JBJHVBYT",
    null,
    null,
    null,
    "KZ05112019",
    null,
    null,
    0,
    2,
    0,
    "VO IST 05/11/2019",
    "VO IST 05/11/2019",
    null,
    0,
    0,
    "KZ05112019",
    null,
    5351.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5416,
    "BRIDGE TRAVEL",
    1,
    1,
    "BRIDGE TRAVEL",
    "GRGHRTH",
    "TUNIS JBENYENA",
    "21620333060",
    null,
    "KZEEE222",
    "Direction@bridge-travel.t",
    null,
    0,
    2,
    0,
    "BRIDGE TRAVEL",
    "BRIDGE TRAVEL",
    null,
    0,
    0,
    "KZEEE222",
    null,
    0,
    0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    5417,
    "ICONE VOYAGE",
    1,
    1,
    "ICONE VOYAGE",
    "FREGHT",
    null,
    null,
    null,
    "KZICONE",
    "kounouz.travel.tozeur@gmail.com;Mohamed.hachfi@gmail.com ",
    null,
    0,
    2,
    0,
    "ICONE VOYAGE",
    "ICONE VOYAGE",
    null,
    0,
    0,
    "KZICONE",
    null,
    21961.59,
    21821.15,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    5418,
    "SBM",
    1,
    1,
    "SBM",
    "NSJUJZD",
    null,
    null,
    null,
    "KZAABZHE",
    "belheni.moadh@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "SBM",
    "SBM",
    null,
    0,
    0,
    "KZAABZHE",
    null,
    44320.5,
    42642.1,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    6420,
    "DALY BEN YEDDER B2B ",
    1,
    1,
    "DALY BEN YEDDER B2B",
    "FGR",
    null,
    null,
    null,
    "KZDALYYY;KZ74885",
    "dalybenyedder@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "DALY BEN YEDDER B2B ",
    "DALY BEN YEDDER B2B ",
    null,
    0,
    0,
    "KZDALYYY",
    null,
    47589.58,
    47588.699,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    6421,
    "CREATION VOYAGES",
    1,
    1,
    "CREATION VOYAGES",
    "VDZFGRZG",
    "28, Rue claude Bernard , Belvédère Tunis 1002",
    null,
    "71965997",
    "KZCREATION;KZ47477474;TUNTS316E",
    "hamdi@creation.voyage;amal@creation.voyage",
    null,
    0,
    2,
    0,
    "CREATION VOYAGES",
    "CREATION VOYAGES",
    null,
    0,
    0,
    "KZCREATIO",
    null,
    42925.27,
    39926.0,
    0,
    0,
    7000.0,
    0,
    0,
  ],
  [
    6422,
    "ZIED VOYAGES ",
    1,
    1,
    "ZIED VOYAGES",
    "1038847/B",
    "RUE TAIEB AZZABI HAMMAMET",
    null,
    " 72 26 34 44",
    ";TUNTS314K",
    "compta@ziedvoyages.com;commercial@ziedvoyages.com;mohamedaminekhammari@gmail.com;nouraelkateb@gmail.com",
    null,
    0,
    2,
    0,
    "ZIED VOYAGES ",
    "ZIED VOYAGES ",
    null,
    0,
    0,
    "ziedvoyages;KZKZ12211212",
    null,
    133720.41,
    134690.91,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6424,
    "TUNSV0201|",
    1,
    0,
    "-",
    null,
    null,
    null,
    null,
    "TUNSV0201",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    -21177.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6428,
    "VO IST 30/11/2019",
    1,
    0,
    "VO IST 30/11/2019",
    "FERGRG",
    null,
    null,
    null,
    "KNZVOIST30112019",
    null,
    null,
    0,
    2,
    0,
    "VO IST 30/11/2019",
    "VO IST 30/11/2019",
    null,
    0,
    0,
    null,
    null,
    18237.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6431,
    "TUNTU0214|",
    1,
    0,
    "-",
    null,
    null,
    null,
    null,
    "TUNTU0214",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6433,
    "UMRAH 2020",
    1,
    0,
    "UMRAH 2020",
    "DFREGH",
    null,
    null,
    null,
    "UMRAH2020",
    "finance@ekounouz.com",
    null,
    0,
    2,
    0,
    "UMRAH 2020",
    "UMRAH 2020",
    null,
    0,
    0,
    "UMRAH2020",
    null,
    2922705.5,
    71137.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6434,
    "VO IST 15/12/2019",
    1,
    0,
    "VO IST 15/12/2019",
    "DVRFG",
    null,
    null,
    null,
    "KNZVOIST15122019",
    null,
    null,
    0,
    2,
    0,
    "VO IST 15/12/2019",
    "VO IST 15/12/2019",
    null,
    0,
    0,
    "KNZVOIST15122019",
    null,
    13198.0,
    1435.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6436,
    "VO IST 16/12/2019",
    1,
    0,
    "VO IST 16/12/2019",
    "FRGR",
    null,
    null,
    null,
    "KNZVOIST16122019",
    null,
    null,
    0,
    2,
    0,
    "VO IST 16/12/2019",
    "VO IST 16/12/2019",
    null,
    0,
    0,
    "KNZVOIST16122019",
    null,
    10964.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6444,
    "MHAMDI D ANIMATION TOUISTIQUE supp",
    0,
    0,
    "MHAMDI D ANIMATION TOUISTIQUE supp",
    null,
    null,
    "21677478991",
    null,
    "GAFTS3109",
    null,
    null,
    0,
    7,
    1,
    "MHAMDI D ANIMATION TOUISTIQUE supp",
    "MHAMDI D ANIMATION TOUISTIQUE supp",
    null,
    0,
    0,
    null,
    "APGAF +21677478991 - MHAMDI D ANIMATION TOUISTIQUE - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6445,
    "GAFTS3109|MHAMDI D ANIMATION TOUISTIQUE",
    0,
    0,
    "MHAMDI D ANIMATION TOUISTIQUE",
    null,
    null,
    "21677478991",
    null,
    "GAFTS3109",
    null,
    null,
    0,
    7,
    0,
    "MHAMDI D ANIMATION TOUISTIQUE",
    "MHAMDI D ANIMATION TOUISTIQUE",
    null,
    0,
    0,
    "mhatrv",
    "APGAF +21677478991 - MHAMDI D ANIMATION TOUISTIQUE - A",
    20437.06,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6446,
    "Tunisair Express",
    0,
    0,
    "Tunisair Express",
    null,
    null,
    null,
    null,
    "TUNUG0580",
    null,
    null,
    0,
    7,
    0,
    "Tunisair Express",
    "Tunisair Express",
    null,
    0,
    0,
    null,
    null,
    -3675.4,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6447,
    "SFATS311E|ENERGIE VOYAGES",
    0,
    0,
    "ENERGIE VOYAGES",
    null,
    null,
    "21674202732",
    null,
    "SFATS311E",
    null,
    null,
    0,
    7,
    0,
    "ENERGIE VOYAGES",
    "ENERGIE VOYAGES",
    null,
    0,
    0,
    "energie",
    "APSFA (216)74202732 - ENERGIE VOYAGES - A",
    21475.96,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6448,
    "KOUNOUZ ZAGHOUA",
    1,
    1,
    "KOUNOUZ ZAGHOUA",
    null,
    null,
    null,
    null,
    "KZ755400",
    "kounouz.travel.zagh@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ ZAGHOUA",
    "KOUNOUZ ZAGHOUA",
    null,
    0,
    0,
    "KZ755400",
    null,
    23026.13,
    17817.13,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    6449,
    "VO IST 22/12/2019",
    1,
    0,
    "VO IST 22/12/2019",
    null,
    "TUNIS ",
    "58313914",
    null,
    "VOIST22DEC19",
    null,
    null,
    0,
    2,
    0,
    "VO IST 22/12/2019",
    "VO IST 22/12/2019",
    null,
    0,
    0,
    "VOIST22DEC19",
    null,
    17476.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6450,
    "VO IST 24/12/2019",
    1,
    0,
    "VO IST 24/12/2019",
    null,
    "tunis",
    null,
    null,
    "voist24dec19",
    null,
    null,
    0,
    2,
    0,
    "VO IST 24/12/2019",
    "VO IST 24/12/2019",
    null,
    0,
    0,
    "voist24dec19",
    null,
    17916.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6451,
    "SFATS310C|SHAHRAZED VOYAGES",
    0,
    0,
    "SHAHRAZED VOYAGES",
    null,
    null,
    "21674402408",
    null,
    "SFATS310C",
    null,
    null,
    0,
    7,
    0,
    "SHAHRAZED VOYAGES",
    "SHAHRAZED VOYAGES",
    null,
    0,
    0,
    null,
    "APSFA (216)74402408 - SHAHRAZED VOYAGES - A//27 700 050",
    24588.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6452,
    "VO IST 26/12/2019",
    1,
    0,
    "VO IST 26/12/2019",
    null,
    "tunis",
    null,
    null,
    "voist26dec19",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/12/2019",
    "VO IST 26/12/2019",
    null,
    0,
    0,
    "voist26dec19",
    null,
    18501.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6453,
    "VO IST 26/10/2019",
    1,
    0,
    "VO IST 26/10/2019",
    "FDEZFG",
    null,
    null,
    null,
    "VOIST2610",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/10/2019",
    "VO IST 26/10/2019",
    null,
    0,
    0,
    null,
    null,
    13656.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6454,
    "KOUNOUZ MAHDIA",
    1,
    1,
    "KOUNOUZ MAHDIA",
    "DVGRHG",
    null,
    null,
    null,
    "KZ102114",
    "ghribi.hedi304@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ MAHDIA",
    "KOUNOUZ MAHDIA",
    null,
    0,
    0,
    "KZ102114",
    null,
    11917.98,
    12707.6,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6455,
    "Go Makkah Tunsie",
    0,
    0,
    "Go Makkah Tunsie",
    null,
    null,
    null,
    null,
    "GAETS3105",
    null,
    null,
    0,
    7,
    0,
    "Go Makkah Tunsie",
    "Go Makkah Tunsie",
    null,
    0,
    0,
    null,
    null,
    5767.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6456,
    "KOUNOUZ SILIANA",
    1,
    1,
    "KOUNOUZ SILIANA",
    "HTYJFDG",
    null,
    null,
    null,
    "kz873011",
    "kounouztravelsiliana@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ SILIANA",
    "KOUNOUZ SILIANA",
    null,
    0,
    0,
    "kz873011",
    null,
    57417.05,
    51776.0,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    6457,
    "MAWAZINE EVENTS",
    1,
    1,
    "MAWAZINE EVENTS",
    "GREG",
    null,
    null,
    null,
    "KHMZ001",
    "mawazine.events@gmail.com",
    null,
    null,
    2,
    0,
    "MAWAZINE EVENTS",
    "MAWAZINE EVENTS",
    null,
    0,
    0,
    "KHMZ001",
    null,
    87975.43,
    85379.25,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    6458,
    "zenith travel",
    0,
    0,
    "zenith travel",
    null,
    null,
    null,
    null,
    "66666666",
    null,
    null,
    0,
    7,
    0,
    "zenith travel",
    "zenith travel",
    null,
    0,
    0,
    "66666666",
    null,
    4888.16,
    352.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6459,
    "SFATS210D|COLISEE TOURS",
    0,
    0,
    "COLISEE TOURS",
    null,
    null,
    "2164210299",
    null,
    "SFATS210D",
    null,
    null,
    0,
    7,
    0,
    "COLISEE TOURS",
    "COLISEE TOURS",
    null,
    0,
    0,
    "colisee",
    "APSFA (216)4 210299 - COLISEE TOURS - A",
    33459.06,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6460,
    "swiss travel",
    0,
    0,
    "swiss travel",
    null,
    null,
    null,
    null,
    "0058989",
    null,
    null,
    0,
    7,
    0,
    "swiss travel",
    "swiss travel",
    null,
    0,
    0,
    "0058989",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6461,
    "samaa madinah ",
    0,
    0,
    "samaa madinah",
    null,
    null,
    null,
    null,
    "samaa ",
    null,
    null,
    0,
    7,
    0,
    "samaa madinah ",
    "samaa madinah ",
    null,
    0,
    0,
    "samaa ",
    null,
    417.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6462,
    "raweh voyage",
    0,
    0,
    "raweh voyage",
    null,
    null,
    null,
    null,
    "1051",
    null,
    null,
    0,
    7,
    0,
    "raweh voyage",
    "raweh voyage",
    null,
    0,
    0,
    "1051",
    null,
    7273.8,
    1298.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6463,
    "Moeme",
    0,
    0,
    "Moeme",
    null,
    null,
    null,
    null,
    "moeme",
    null,
    null,
    0,
    7,
    0,
    "Moeme",
    "Moeme",
    null,
    0,
    0,
    "moeme",
    null,
    3871.07,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6464,
    "khotwat almosafer ",
    0,
    0,
    "khotwat almosafer",
    null,
    null,
    null,
    null,
    "khotwatalmosaf",
    null,
    null,
    0,
    7,
    0,
    "khotwat almosafer ",
    "khotwat almosafer ",
    null,
    0,
    0,
    "khotwatalmosaf",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6465,
    "Jouzef Travel",
    0,
    0,
    "Jouzef Travel",
    null,
    null,
    null,
    null,
    "Jouzeftravel",
    null,
    null,
    0,
    7,
    0,
    "Jouzef Travel",
    "Jouzef Travel",
    null,
    0,
    0,
    "Jouzeftravel",
    null,
    2059.76,
    0,
    0,
    0,
    0,
    0,
    0,
    1,
  ],
  [
    6466,
    "COMETETOURS",
    0,
    0,
    "COMETETOURS",
    null,
    null,
    null,
    null,
    "COMETETOURS",
    null,
    null,
    0,
    7,
    0,
    "COMETETOURS",
    "COMETETOURS",
    null,
    0,
    0,
    "COMETETOURS",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6467,
    "MIRTS313F|KONOUZ TOURISM",
    0,
    0,
    "KONOUZ TOURISM",
    null,
    null,
    "21673221361",
    null,
    "MIRTS313F",
    "SAVATRAVELTUNISIA1@GMAIL.COM//M",
    null,
    0,
    7,
    0,
    "KONOUZ TOURISM",
    "KONOUZ TOURISM",
    null,
    0,
    0,
    null,
    "APMIR (216)73221361 - KONOUZ TOURISM - A//MIR (216)73221361 - KONOUZ TOURISM - A//E-SAVATRAVELTUNISIA1@GMAIL.COM//M-+216 58418404",
    1958.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6468,
    "TL",
    1,
    1,
    "TL",
    "CXVDB",
    null,
    null,
    null,
    "KZTL",
    "tln-nour@hotmail.fr",
    null,
    0,
    2,
    0,
    "TL",
    "TL",
    null,
    0,
    0,
    "KZTL",
    null,
    3186.01,
    3186.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6469,
    "HAJER OUERTANI",
    1,
    1,
    "HAJER OUERTANI",
    "FGHT",
    null,
    null,
    null,
    "KZHAJER01",
    "imedghabi1980@gmail.com;HAJER.OMRA@GMAIL.COM",
    null,
    0,
    2,
    0,
    "HAJER OUERTANI",
    "HAJER OUERTANI",
    null,
    0,
    0,
    "KZHAJER01",
    null,
    93000.32,
    91229.5,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    6470,
    "FLORITA TRAVEL  ",
    1,
    1,
    "FLORITA TRAVEL",
    "CVGDF",
    null,
    null,
    null,
    "KZFLORITA",
    "floritatravel@gmail.com",
    null,
    0,
    2,
    0,
    "FLORITA TRAVEL  ",
    "FLORITA TRAVEL  ",
    null,
    0,
    0,
    "KZFLORITA",
    null,
    6010.53,
    4067.87,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    6471,
    "VO IST 04/02/2020",
    1,
    0,
    "VO IST 04/02/2020",
    "dfgreg",
    null,
    null,
    null,
    "kzist04022020",
    "comptataysir@gmzil.com",
    null,
    0,
    2,
    0,
    "VO IST 04/02/2020",
    "VO IST 04/02/2020",
    null,
    0,
    0,
    null,
    null,
    10643.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6472,
    "TUNTS214Z|MEK TOURS (2)",
    0,
    0,
    "MEK TOURS (2)",
    null,
    null,
    "50347988",
    "21602290177",
    "TUNTS214Z",
    null,
    null,
    0,
    7,
    0,
    "MEK TOURS (2)",
    "MEK TOURS (2)",
    null,
    0,
    0,
    null,
    "AP50347988//TUN (216)02 290177 - MEK TOURS (2) - A",
    772.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6473,
    "RAFFLESIA LITIGE",
    1,
    1,
    "RAFFLESIA LITIGE",
    "SFEG",
    null,
    null,
    null,
    "KZRAFLESIA",
    "anissa@rafflesia-events.com;rabi3006@gmail.com ",
    null,
    0,
    2,
    0,
    "RAFFLESIA LITIGE",
    "RAFFLESIA LITIGE",
    null,
    0,
    0,
    "KZRAFLESIA",
    null,
    39776.8,
    49825.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6474,
    "SCAN TRAVEL ",
    1,
    1,
    "SCAN TRAVEL",
    "DCVDGBV",
    "TUNIS",
    "52543491",
    null,
    "KZSCAN100",
    "mouheb.tebib@gmail.com",
    null,
    0,
    2,
    0,
    "SCAN TRAVEL ",
    "SCAN TRAVEL ",
    null,
    0,
    0,
    "KZSCAN100",
    null,
    968.05,
    968.605,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    6475,
    "JSV TOURS",
    1,
    1,
    "JSV TOURS",
    "DCVZDF",
    null,
    null,
    null,
    "KZJSV100",
    "jsvtours1@gmail.com",
    null,
    0,
    2,
    0,
    "JSV TOURS",
    "JSV TOURS",
    null,
    0,
    0,
    "KZJSV100",
    null,
    10026.34,
    8153.95,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    6476,
    "ANDALOUS VOYAGE ",
    1,
    1,
    "ANDALOUS VOYAGE",
    "GHJYTJ",
    null,
    null,
    null,
    "TUNTS31B3",
    "mohamedaminekhammari@gmail.com;chaachoui.heni@yahoo.fr",
    null,
    0,
    2,
    0,
    "ANDALOUS VOYAGE ",
    "ANDALOUS VOYAGE ",
    null,
    0,
    0,
    "EZTR",
    null,
    54122.1,
    50550.8,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6479,
    "PROMIA TRAVEL ",
    1,
    1,
    "PROMIA TRAVEL",
    "CVFVF",
    null,
    null,
    null,
    "KZPRO100",
    "commercial@promiavoyages.com",
    null,
    0,
    2,
    0,
    "PROMIA TRAVEL ",
    "PROMIA TRAVEL ",
    null,
    0,
    0,
    "KZPRO100",
    null,
    39654.48,
    35600.94,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6480,
    "BAYEZID TRAVEL ",
    1,
    1,
    "BAYEZID TRAVEL",
    "FEGR",
    null,
    null,
    null,
    "KZBAYE100",
    "bayezidtravel@gmail.com;nouraelkateb@gmail.com",
    null,
    0,
    2,
    0,
    "BAYEZID TRAVEL ",
    "BAYEZID TRAVEL ",
    null,
    0,
    0,
    "KZBAYE100",
    null,
    17848.94,
    18014.6,
    0,
    0,
    3000.0,
    0,
    0,
  ],
  [
    6481,
    "VO IST 02/02/2020 ",
    1,
    1,
    "VO IST 02/02/2020",
    "FZERFG",
    null,
    null,
    null,
    "VO02022020",
    "COMPTATAYSIR@GMAIL.COM",
    null,
    0,
    2,
    0,
    "VO IST 02/02/2020 ",
    "VO IST 02/02/2020 ",
    null,
    0,
    0,
    "VO02022020",
    null,
    9623.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6482,
    "VO IST 18/01/2020",
    1,
    1,
    "VO IST 18/01/2020",
    null,
    null,
    null,
    null,
    "VOIST18012020",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "VO IST 18/01/2020",
    "VO IST 18/01/2020",
    null,
    0,
    0,
    "VOIST18012020",
    null,
    13890.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6483,
    "MIRTS3122|",
    1,
    0,
    "-",
    null,
    null,
    null,
    null,
    "MIRTS3122",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6484,
    "VO IST 15/03/2020",
    1,
    1,
    "VO IST 15/03/2020",
    null,
    null,
    null,
    null,
    "VO IST 15032020",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "VO IST 15/03/2020",
    "VO IST 15/03/2020",
    null,
    0,
    0,
    "VO IST 15032020",
    null,
    8415.0,
    5133.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6485,
    "TT VOYAGE",
    1,
    0,
    "TT VOYAGE",
    null,
    null,
    null,
    null,
    "",
    "booking.ttvoyages@gmail.com",
    null,
    0,
    2,
    1,
    "TT VOYAGE",
    "TT VOYAGE",
    null,
    0,
    0,
    "KZTTVOYAGES",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6487,
    "ETAT CONSOLIDE BHA BENETTON 2020 LITIGE",
    1,
    1,
    "ETAT CONSOLIDE BHA BENETTON 2020 LITIGE",
    "DSFTHR",
    null,
    null,
    null,
    "aaaaa",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "ETAT CONSOLIDE BHA BENETTON 2020 LITIGE",
    "ETAT CONSOLIDE BHA BENETTON 2020 LITIGE",
    null,
    0,
    0,
    null,
    null,
    0,
    24000.0,
    0,
    0,
    41920.0,
    0,
    0,
  ],
  [
    6488,
    "VO IST 15/02/2020",
    1,
    0,
    "VO IST 15/02/2020",
    null,
    null,
    null,
    null,
    "VOIST150222020",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "VO IST 15/02/2020",
    "VO IST 15/02/2020",
    null,
    0,
    0,
    null,
    null,
    8354.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6489,
    "MIRTS312D|WEB TRAVEL",
    1,
    0,
    "WEB TRAVEL",
    null,
    null,
    "21673611481",
    null,
    "MIRTS312D",
    null,
    null,
    0,
    2,
    1,
    "WEB TRAVEL",
    "WEB TRAVEL",
    null,
    0,
    0,
    null,
    "APMIR (216) 73611481 - WEB TRAVEL - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6490,
    "TUNISIE ACCUEIL",
    1,
    1,
    "TUNISIE ACCUEIL",
    null,
    null,
    null,
    null,
    "",
    "info@tunisieaccueil.com",
    null,
    0,
    2,
    0,
    "TUNISIE ACCUEIL",
    "TUNISIE ACCUEIL",
    null,
    0,
    0,
    "KZTA00",
    null,
    19335.83,
    19337.0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    6491,
    "TT VOYAGES",
    1,
    1,
    "TT VOYAGES",
    "SDFEF",
    null,
    null,
    null,
    "MIRTS3159",
    "booking.ttvoyages@gmail.com;mohamedaminekhammari@gmail.com ",
    null,
    0,
    2,
    0,
    "TT VOYAGES",
    "TT VOYAGES",
    null,
    0,
    0,
    "KZAA10",
    null,
    648633.662,
    628057.858,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    6492,
    "TUNTU0567|HEAD REVENUE MANAGEMENT",
    1,
    0,
    "HEAD REVENUE MANAGEMENT",
    null,
    null,
    "",
    null,
    "TUNTU0567",
    null,
    null,
    0,
    2,
    1,
    "HEAD REVENUE MANAGEMENT",
    "HEAD REVENUE MANAGEMENT",
    null,
    0,
    0,
    null,
    "APTUN TBA - HEAD REVENUE MANAGEMENT - A//MIR (216)73221361 - KONOUZ TOURISM - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6493,
    "BEST TRAVEL SAHLOUL",
    1,
    1,
    "BEST TRAVEL SAHLOUL",
    "HJCU",
    null,
    null,
    null,
    "KZBESTTRAVEL",
    "besttravel1@yahoo.fr",
    null,
    0,
    2,
    0,
    "BEST TRAVEL SAHLOUL",
    "BEST TRAVEL SAHLOUL",
    null,
    0,
    0,
    "KZBESTTRAVEL",
    null,
    0,
    0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    6494,
    "TUN1A0980|HELP DESK",
    1,
    0,
    "HELP DESK",
    null,
    null,
    "21671167660",
    null,
    "TUN1A0980",
    null,
    null,
    0,
    2,
    1,
    "HELP DESK",
    "HELP DESK",
    null,
    0,
    0,
    null,
    "APTUN +216 71 167 660 - AMADEUS TUNISIA - HELP DESK - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6495,
    "COMPASS TRAVEL",
    1,
    0,
    "COMPASS TRAVEL",
    null,
    "23 RUE DE L INDE TUNIS ",
    "21671832222",
    "98 464 464 ",
    "TUNTS313O",
    null,
    null,
    0,
    2,
    0,
    "COMPASS TRAVEL",
    "COMPASS TRAVEL",
    null,
    0,
    0,
    null,
    "APTUN +21671832222 - COMPASS TRAVEL - A//+2165597846",
    7318.0,
    7317.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6496,
    "BOGOTA TRAVEL ",
    1,
    1,
    "BOGOTA TRAVEL ",
    "A/M/000/1669944/L",
    null,
    "23601099",
    null,
    "TUNTS31EA",
    "bogotavoyage@gmail.com",
    null,
    0,
    2,
    0,
    "BOGOTA TRAVEL ",
    "BOGOTA TRAVEL ",
    null,
    0,
    0,
    "KZBOGOTA",
    null,
    72993.76,
    64556.76,
    0,
    0,
    50000.0,
    0,
    0,
  ],
  [
    6497,
    "VOYAGI",
    1,
    1,
    "VOYAGI",
    null,
    null,
    "53323982",
    null,
    "KZVO01",
    "voyagi.sales@gmail.com",
    null,
    0,
    2,
    0,
    "VOYAGI",
    "VOYAGI",
    null,
    0,
    0,
    "KZVO01",
    null,
    541.12,
    541.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    6498,
    "GTS",
    1,
    1,
    "GTS",
    "002121",
    "28 AVENUE D AFRIQUE EL MENZAH 5",
    "21858500",
    null,
    "TUNTS310M",
    "anislaouini@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "GTS",
    "GTS",
    null,
    0,
    0,
    "KZGEN01",
    null,
    166732.64,
    162977.46,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    7498,
    "TURQUOISE VOYAGES",
    1,
    1,
    "TURQUOISE VOYAGES",
    "1121554",
    null,
    null,
    null,
    "MIRTS311U",
    "info.turquoisevoyages@gmail.com;info-turquoisevoyages@hexabyte.t",
    null,
    0,
    2,
    0,
    "TURQUOISE VOYAGES",
    "TURQUOISE VOYAGES",
    null,
    0,
    0,
    "KZTURK01",
    null,
    438707.69,
    211314.76,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    7499,
    "MIRTS311U|TURQUOISE VOYAGES",
    1,
    0,
    "TURQUOISE VOYAGES",
    null,
    null,
    "21673906244",
    null,
    "",
    null,
    null,
    0,
    2,
    1,
    "TURQUOISE VOYAGES",
    "TURQUOISE VOYAGES",
    null,
    0,
    0,
    null,
    "APMIR (216)73906244 - TURQUOISE VOYAGES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    7500,
    "MIRTS312T|KAIROUAN VOYAGES",
    1,
    0,
    "KAIROUAN VOYAGES",
    null,
    null,
    "21677237032",
    null,
    "MIRTS312T",
    null,
    null,
    0,
    2,
    1,
    "KAIROUAN VOYAGES",
    "KAIROUAN VOYAGES",
    null,
    0,
    0,
    null,
    "APMIR (216)77237032 - KAIROUAN VOYAGES - A//MIR (216)77237032 - KAIROUAN VOYAGES - A//21620370390-M",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    7501,
    "FAVORI VOYAGES",
    1,
    1,
    "FAVORI VOYAGES",
    null,
    null,
    "92518209",
    null,
    "MIRTS310I",
    "info@favorivoyages.com.t",
    null,
    0,
    2,
    0,
    "FAVORI VOYAGES",
    "FAVORI VOYAGES",
    null,
    0,
    0,
    "KZFAV01",
    null,
    35958.3,
    33326.3,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    7502,
    "SOUMAYA TRAVEL",
    1,
    1,
    "SOUMAYA TRAVEL",
    null,
    null,
    null,
    null,
    "",
    "mehdi.f@soumayavoyages.tn;soumaya.voyages@gmail.com",
    null,
    0,
    2,
    0,
    "SOUMAYA TRAVEL",
    "SOUMAYA TRAVEL",
    null,
    0,
    0,
    "KZSOUM01;KZSOU01",
    null,
    59877.38,
    59795.6,
    0,
    0,
    2000.0,
    0,
    0,
  ],
  [
    7503,
    "VO IST 21/09/2020",
    1,
    0,
    "VO IST 21/09/2020",
    null,
    null,
    null,
    null,
    "vhugh",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 21/09/2020",
    "VO IST 21/09/2020",
    null,
    0,
    0,
    "vhugh",
    null,
    28297.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    7504,
    "FREEDOM TOURS",
    1,
    1,
    "FREEDOM TOURS",
    "1263990/Q",
    "av hedi nouira tunis ,1002",
    "98707902",
    "71240654",
    "TUNTS311B",
    "abdelwareth@tftours.tn;contact@tftours.t",
    null,
    0,
    2,
    0,
    "FREEDOM TOURS",
    "FREEDOM TOURS",
    null,
    0,
    0,
    null,
    null,
    102881.79,
    94532.39,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    7505,
    "TAYSIR TRAVEL LITIGE",
    1,
    1,
    "TAYSIR TRAVEL LITIGE",
    "GTJU",
    null,
    null,
    null,
    "",
    "taysirtravel01@gmail.com",
    null,
    0,
    2,
    0,
    "TAYSIR TRAVEL LITIGE",
    "TAYSIR TRAVEL LITIGE",
    null,
    0,
    0,
    "KZTY001",
    null,
    10288.1,
    8100.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    7506,
    "TUNISIAN GREEN TOUR",
    1,
    1,
    "TUNISIAN GREEN TOUR",
    null,
    null,
    "21672266335",
    null,
    "TUNTS3123",
    "Asma_ben_essghaier@yahoo.fr;Asma_ben_essghaier@yahoo.fr;Asma_ben_essghaier@yahoo.fr",
    null,
    0,
    2,
    0,
    "TUNISIAN GREEN TOUR",
    "TUNISIAN GREEN TOUR",
    null,
    0,
    0,
    null,
    "APTUN (216)72266335 - TUNISIAN GREEN TOUR - A",
    337142.62,
    331785.0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8503,
    "NOZHA VOYAGES",
    1,
    1,
    "NOZHA VOYAGES",
    null,
    "HAMMEM SOUSSE",
    "98684284",
    null,
    "KZNOZ01",
    "Nozha.traveltunisia@gmail.com",
    null,
    0,
    2,
    0,
    "NOZHA VOYAGES",
    "NOZHA VOYAGES",
    null,
    0,
    0,
    "KZNOZ01",
    null,
    1571.42,
    1571.0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8504,
    "VO IST 16/11/2020",
    1,
    0,
    "VO IST 16/11/2020",
    "VOIST16112020",
    null,
    null,
    null,
    "KZ16112020",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 16/11/2020",
    "VO IST 16/11/2020",
    null,
    0,
    0,
    null,
    null,
    3274.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8505,
    "SPARTA TRAVEL",
    1,
    1,
    "SPARTA TRAVEL",
    null,
    null,
    null,
    null,
    "",
    "resaspartatravels@outlook.com;mohamedaminekhammari@gmail.com;contactspartatravels@gmail.com;resaspartatravels@outlook.com",
    null,
    0,
    2,
    0,
    "SPARTA TRAVEL",
    "SPARTA TRAVEL",
    null,
    0,
    0,
    "KZSPAR01",
    null,
    65218.09,
    56575.964,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8506,
    "VO IST 06/12/2020",
    1,
    0,
    "VO IST 06/12/2020",
    "06122020",
    null,
    null,
    null,
    "VOIST06122020",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "VO IST 06/12/2020",
    "VO IST 06/12/2020",
    null,
    0,
    0,
    "VOIST06122020",
    null,
    16148.88,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8507,
    "MIRTS314M|SAVANA TOURS",
    1,
    0,
    "SAVANA TOURS",
    null,
    null,
    "21673374666",
    null,
    "MIRTS314M",
    "MAZEN.EL.GUESMI@GMAIL.COM",
    null,
    0,
    2,
    1,
    "SAVANA TOURS",
    "SAVANA TOURS",
    null,
    0,
    0,
    null,
    "APMIR +216 73 374 666 - SAVANA TOURS - A//50702913-M//E-MAZEN.EL.GUESMI@GMAIL.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8508,
    "VO IST 13/12/2020",
    1,
    0,
    "VO IST 13/12/2020",
    "qfrgfd",
    null,
    null,
    null,
    "KZVOIST16122020",
    null,
    null,
    0,
    2,
    0,
    "VO IST 13/12/2020",
    "VO IST 13/12/2020",
    null,
    0,
    0,
    "KZVOIST16122020",
    null,
    12688.955,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8509,
    "VO IST 21/12/2020",
    1,
    0,
    "VO IST 21/12/2020",
    "ceff",
    null,
    null,
    null,
    "voist21122020",
    null,
    null,
    0,
    2,
    0,
    "VO IST 21/12/2020",
    "VO IST 21/12/2020",
    null,
    0,
    0,
    "voist21122020",
    null,
    15924.77,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8510,
    "VO IST 23/12/2020",
    1,
    0,
    "VO IST 23/12/2020",
    "defef",
    null,
    null,
    null,
    "voist23122020",
    null,
    null,
    0,
    2,
    0,
    "VO IST 23/12/2020",
    "VO IST 23/12/2020",
    null,
    0,
    0,
    "voist23122020",
    null,
    14801.92,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8511,
    "VO IST 24/12/2020",
    1,
    0,
    "VO IST 24/12/2020",
    null,
    null,
    null,
    null,
    "voist24122020",
    null,
    null,
    0,
    2,
    0,
    "VO IST 24/12/2020",
    "VO IST 24/12/2020",
    null,
    0,
    0,
    "voist24122020",
    null,
    2364.73,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8512,
    "VO IST 26/12/2020",
    1,
    0,
    "VO IST 26/12/2020",
    null,
    null,
    null,
    null,
    "voist26122020",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/12/2020",
    "VO IST 26/12/2020",
    null,
    0,
    0,
    "voist26122020",
    null,
    52874.136,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8513,
    "VO IST 10/01/2021",
    0,
    0,
    "VO IST 10/01/2021",
    "DSVSV",
    null,
    null,
    null,
    "VOIST10012021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 10/01/2021",
    "VO IST 10/01/2021",
    null,
    0,
    0,
    "VOIST10012021",
    null,
    10884.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8514,
    "VO IST 17/01/2021",
    0,
    0,
    "VO IST 17/01/2021",
    "jhhj",
    null,
    null,
    null,
    "kzvoist17012021",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "VO IST 17/01/2021",
    "VO IST 17/01/2021",
    null,
    0,
    0,
    "kzvoist17012021",
    null,
    9432.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8515,
    "VO IST 24/01/2021",
    0,
    0,
    "VO IST 24/01/2021",
    "jbbhbhh",
    null,
    null,
    null,
    "VOIST24122021",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "VO IST 24/01/2021",
    "VO IST 24/01/2021",
    null,
    0,
    0,
    "VOIST24122021",
    null,
    10920.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8516,
    "COLORS TRAVEL",
    1,
    1,
    "COLORS TRAVEL",
    null,
    null,
    null,
    null,
    "KZCOLOR",
    "SALES@COLORSTRAVEL.COM.T",
    null,
    0,
    2,
    0,
    "COLORS TRAVEL",
    "COLORS TRAVEL",
    null,
    0,
    0,
    "KZCOLOR",
    null,
    7922.0,
    7922.0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8517,
    "GAFTS3103|ZAHRA VOYAGES",
    0,
    0,
    "ZAHRA VOYAGES",
    null,
    null,
    "21676200215",
    null,
    "GAFTS3103",
    null,
    null,
    0,
    7,
    0,
    "ZAHRA VOYAGES",
    "ZAHRA VOYAGES",
    null,
    0,
    0,
    null,
    "APGAF +216 76 200 215 - ZAHRA VOYAGES - A",
    889.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8518,
    "VO IST 27/01/2021",
    0,
    0,
    "VO IST 27/01/2021",
    "uhyuyuguy",
    null,
    null,
    null,
    "VOIST31012021",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "VO IST 27/01/2021",
    "VO IST 27/01/2021",
    null,
    0,
    0,
    "VOIST31012021",
    null,
    21475.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8519,
    "AIR AND SEA",
    1,
    1,
    "AIR AND SEA",
    "FEFEF",
    null,
    null,
    null,
    "KZAIR001",
    "contact@airandsea.com.t",
    null,
    0,
    2,
    0,
    "AIR AND SEA",
    "AIR AND SEA",
    null,
    0,
    0,
    "KZAIR001",
    null,
    18523.82,
    17420.45,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8520,
    "TIPLY2419|",
    1,
    0,
    "-",
    null,
    null,
    null,
    null,
    "TIPLY2419",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    4168.75,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8521,
    "ALAKHAWAYN FOR TOURISM",
    0,
    0,
    "ALAKHAWAYN FOR TOURISM",
    null,
    null,
    null,
    null,
    "TIPLY2419",
    null,
    null,
    0,
    8,
    0,
    "ALAKHAWAYN FOR TOURISM",
    "ALAKHAWAYN FOR TOURISM",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8522,
    "ahmed",
    0,
    0,
    "ahmed",
    null,
    null,
    "0914005656",
    null,
    "ahmed01",
    "ahmedalmain@gamil.com",
    null,
    0,
    8,
    0,
    "ahmed",
    "ahmed",
    null,
    0,
    0,
    "ahmed01",
    null,
    0,
    200.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8523,
    "VO IST 08/03/2021",
    0,
    0,
    "VO IST 08/03/2021",
    "sdfvzrg",
    null,
    null,
    null,
    "kzvoist08032021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 08/03/2021",
    "VO IST 08/03/2021",
    null,
    0,
    0,
    "kzvoist08032021",
    null,
    42899.0,
    11466.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8524,
    "TIPLN0110|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "TIPLN0110",
    null,
    null,
    0,
    8,
    0,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8525,
    "VO IST 13/03/2021",
    0,
    0,
    "VO IST 13/03/2021",
    "FGVDTH",
    null,
    null,
    null,
    "VOIST13032021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 13/03/2021",
    "VO IST 13/03/2021",
    null,
    0,
    0,
    "VOIST13032021",
    null,
    20891.5,
    56439.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8526,
    "VO IST 14/03/2021",
    0,
    0,
    "VO IST 14/03/2021",
    "GHFYF",
    null,
    null,
    null,
    "VOIST14032021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 14/03/2021",
    "VO IST 14/03/2021",
    null,
    0,
    0,
    "VOIST14032021",
    null,
    29641.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8527,
    "TIPLY2897|ANNITAQ FOR SERVICE AND TOURISM",
    0,
    0,
    "ANNITAQ FOR SERVICE AND TOURISM",
    null,
    null,
    "2182147818990922255033",
    null,
    "TIPLY2897",
    "INFO@ASTICO.LY//M",
    null,
    0,
    8,
    0,
    "ANNITAQ FOR SERVICE AND TOURISM",
    "ANNITAQ FOR SERVICE AND TOURISM",
    null,
    0,
    0,
    null,
    "APTIP +218 21 4781899/092 2255033 - ANNITAQ FOR SERVICE AND TOURISM - A//E-INFO@ASTICO.LY//M-0921250601",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8528,
    "VO IST 15/03/2021-22",
    0,
    0,
    "VO IST 15/03/2021-22",
    "scef",
    null,
    null,
    null,
    "voist15012021-22",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 15/03/2021-22",
    "VO IST 15/03/2021-22",
    null,
    0,
    0,
    "voist15012021-22",
    null,
    22582.0,
    6832.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8529,
    "VO IST 15/03/2021-21",
    0,
    0,
    "VO IST 15/03/2021-21",
    "sfezfg",
    null,
    null,
    null,
    "voist15012021-21",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 15/03/2021-21",
    "VO IST 15/03/2021-21",
    null,
    0,
    0,
    "voist15012021-21",
    null,
    16385.0,
    959.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8530,
    "TIPLY2419|ALAKHAWAYN FOR TOURISM  SERVICES",
    1,
    0,
    "ALAKHAWAYN FOR TOURISM  SERVICES",
    null,
    null,
    "2180214777549",
    null,
    "TIPLY2419",
    null,
    null,
    0,
    2,
    1,
    "ALAKHAWAYN FOR TOURISM  SERVICES",
    "ALAKHAWAYN FOR TOURISM  SERVICES",
    null,
    0,
    0,
    null,
    "APTIP +218/0214777549-0945590413 - ALAKHAWAYN FOR TOURISM  SERVICES - A//M-0927211011",
    2064.4,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8531,
    "VO IST 17/03/2021",
    0,
    0,
    "VO IST 17/03/2021",
    "dwbg",
    null,
    null,
    null,
    "VOIST17032021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 17/03/2021",
    "VO IST 17/03/2021",
    null,
    0,
    0,
    "VOIST17032021",
    null,
    21504.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8532,
    "VO IST 22/03/2021",
    0,
    0,
    "VO IST 22/03/2021",
    "VRG",
    "DFRGF",
    null,
    null,
    "VOIST22032021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 22/03/2021",
    "VO IST 22/03/2021",
    null,
    0,
    0,
    "VOIST22032021",
    null,
    20741.0,
    7208.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8533,
    "BOOK AND GO TRAVEL",
    1,
    1,
    "BOOK AND GO TRAVEL",
    "fveg",
    "lac",
    "21094094",
    null,
    "kzbook01",
    "gm@bookandgotravel.com.t",
    null,
    0,
    2,
    0,
    "BOOK AND GO TRAVEL",
    "BOOK AND GO TRAVEL",
    null,
    0,
    0,
    "kzbook01",
    null,
    142860.0,
    131225.14,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    8534,
    "TIPLY2419|ALAKHAWAYN FOR TOURISM  SERVICES",
    1,
    0,
    "ALAKHAWAYN FOR TOURISM  SERVICES",
    null,
    null,
    "2180214777549",
    null,
    "TIPLY2419",
    null,
    null,
    0,
    2,
    1,
    "ALAKHAWAYN FOR TOURISM  SERVICES",
    "ALAKHAWAYN FOR TOURISM  SERVICES",
    null,
    0,
    0,
    null,
    "APTIP +218/0214777549-0945590413 - ALAKHAWAYN FOR TOURISM  SERVICES - A//M-0927211011",
    2014.4,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8535,
    "BOUBAKER TUNISAIR",
    1,
    1,
    "BOUBAKER TUNISAIR",
    "FREY",
    null,
    null,
    null,
    "KZ01BOUBAKER",
    "BOUBAKERSERVICES@GMAIL.COM",
    null,
    0,
    2,
    0,
    "BOUBAKER TUNISAIR",
    "BOUBAKER TUNISAIR",
    null,
    0,
    0,
    "KZ01BOUBAKER",
    null,
    12684.75,
    12688.0,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8536,
    "VO IST 29/03/2021",
    0,
    0,
    "VO IST 29/03/2021",
    "FEDF",
    null,
    null,
    null,
    "VOIST29032021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 29/03/2021",
    "VO IST 29/03/2021",
    null,
    0,
    0,
    "VOIST29032021",
    null,
    17692.5,
    1726.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8537,
    "TIPLY25B9|QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    1,
    0,
    "QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    null,
    null,
    "2180213335536",
    null,
    "TIPLY25B9",
    null,
    null,
    0,
    2,
    1,
    "QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    "QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    null,
    0,
    0,
    null,
    "APTIP +218/021 3335536-0913223336 - QMIT ALALEM TRAVEL AND TOURIST SERVICE - A",
    214.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8538,
    "VO IST 04/04/2021",
    0,
    0,
    "VO IST 04/04/2021",
    null,
    null,
    null,
    null,
    "VOIST04042021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 04/04/2021",
    "VO IST 04/04/2021",
    null,
    0,
    0,
    "VOIST04042021",
    null,
    26539.5,
    3492.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8539,
    "VO IST 05/04/2021",
    0,
    0,
    "VO IST 05/04/2021",
    "FRGTR",
    null,
    null,
    null,
    "VOIST05042021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "VO IST 05/04/2021",
    "VO IST 05/04/2021",
    null,
    0,
    0,
    "VOIST05042021",
    null,
    21073.44,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8540,
    "TAYARA TRAVEL ",
    1,
    1,
    "TAYARA TRAVEL ",
    "SFFG",
    null,
    null,
    null,
    "KZTAY001",
    "tayaravoyages15@gmail.com;mohamedaminekhammari@gmail.com",
    null,
    0,
    2,
    0,
    "TAYARA TRAVEL ",
    "TAYARA TRAVEL ",
    null,
    0,
    0,
    "KZTAY001",
    null,
    27829.5,
    27355.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8541,
    "NEW LINES TRAVEL",
    1,
    1,
    "NEW LINES TRAVEL",
    null,
    null,
    null,
    null,
    "newlines",
    "newlinestravelagence@gmail.com",
    null,
    0,
    2,
    0,
    "NEW LINES TRAVEL",
    "NEW LINES TRAVEL",
    null,
    0,
    0,
    "newlines",
    null,
    17246.2,
    14582.16,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8542,
    "UMRAH 2021 GRP 09/04/2021 ",
    1,
    0,
    "UMRAH 2021 GRP 09/04/2021 ",
    null,
    null,
    null,
    null,
    "UMRAH09/04/2021",
    "fares8984@hotmail.fr",
    null,
    0,
    2,
    0,
    "UMRAH 2021 GRP 09/04/2021 ",
    "UMRAH 2021 GRP 09/04/2021 ",
    null,
    0,
    0,
    "UMRAH09/04/2021",
    null,
    87270.0,
    2791.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8543,
    "TAWMA VOYAGE",
    1,
    1,
    "TAWMA VOYAGE",
    null,
    null,
    null,
    null,
    "DJETS3108",
    "resa.tawma@yahoo.fr",
    null,
    0,
    2,
    0,
    "TAWMA VOYAGE",
    "TAWMA VOYAGE",
    null,
    0,
    0,
    "KZTAWMA01",
    null,
    21328.63,
    16525.44,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8544,
    "A2Z HOLIDAYS",
    1,
    1,
    "A2Z HOLIDAYS",
    "dfgreg",
    null,
    null,
    null,
    "KZAZZ001",
    "Emna.mbazaa@a2zholidays.tn;sami.bennasr@a2zholidays.t",
    null,
    0,
    2,
    0,
    "A2Z HOLIDAYS",
    "A2Z HOLIDAYS",
    null,
    0,
    0,
    "KZAZZ001",
    null,
    96930.11,
    92551.27,
    0,
    0,
    25000.0,
    0,
    0,
  ],
  [
    8545,
    "TIPLY2821|ALNADARA FOR TRAVEL AND TOURISM .",
    1,
    0,
    "ALNADARA FOR TRAVEL AND TOURISM .",
    null,
    null,
    "002180213700360",
    null,
    "TIPLY2821",
    null,
    null,
    0,
    2,
    1,
    "ALNADARA FOR TRAVEL AND TOURISM .",
    "ALNADARA FOR TRAVEL AND TOURISM .",
    null,
    0,
    0,
    null,
    "APTIP 00218/021 370 0360-091 3167799 - ALNADARA FOR TRAVEL AND TOURISM . - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8546,
    "ABRAJ ZAMZAM",
    1,
    0,
    "ABRAJ ZAMZAM",
    null,
    "tripoli",
    "00218924810489",
    null,
    "tiply",
    "tahher99@gmail.com",
    null,
    5850.0,
    2,
    1,
    "ABRAJ ZAMZAM",
    "ABRAJ ZAMZAM",
    null,
    0,
    0,
    "KZABRAJZMZ",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8547,
    "AL MOKHTARA TRAVEL",
    1,
    1,
    "AL MOKHTARA TRAVEL",
    null,
    null,
    null,
    null,
    "KZALM001",
    "ihebkhayech@gmail.com",
    null,
    0,
    2,
    0,
    "AL MOKHTARA TRAVEL",
    "AL MOKHTARA TRAVEL",
    null,
    0,
    0,
    "KZALM001",
    null,
    4484.93,
    4484.93,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8548,
    "SADINE TOURS",
    1,
    1,
    "SADINE TOURS",
    "FRGR",
    "MONASTIR ",
    null,
    null,
    "KZSAD001",
    "sadintours@gmail.com",
    null,
    0,
    2,
    0,
    "SADINE TOURS",
    "SADINE TOURS",
    null,
    0,
    0,
    "KZSAD001",
    null,
    111844.43,
    117029.23,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8549,
    "MIRTS3108|OK VOYAGES",
    1,
    0,
    "OK VOYAGES",
    null,
    null,
    "21673200030",
    null,
    "MIRTS3108",
    "EML-KHALEDSASSI1@GMAIL.COM",
    null,
    0,
    2,
    1,
    "OK VOYAGES",
    "OK VOYAGES",
    null,
    0,
    0,
    null,
    "APMIR +216 73 200 030 - OK VOYAGES - A//98439735//E-EML-KHALEDSASSI1@GMAIL.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8550,
    "GROUPE 6 JUN IST",
    1,
    0,
    "GROUPE 6 JUN IST",
    null,
    "kounouz ",
    "58313888",
    null,
    "TK06JU",
    "info.kounouz@gmail.com",
    null,
    0,
    2,
    1,
    "GROUPE 6 JUN IST",
    "GROUPE 6 JUN IST",
    null,
    0,
    0,
    "TK06JU",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8551,
    "LINA TRAVEL",
    1,
    1,
    "LINA TRAVEL",
    "FVEFF",
    null,
    null,
    null,
    "KZLINA001",
    "rby1967@gmail.com",
    null,
    0,
    2,
    0,
    "LINA TRAVEL",
    "LINA TRAVEL",
    null,
    0,
    0,
    "KZLINA001",
    null,
    527.72,
    527.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8552,
    "MIRTS314M|",
    1,
    0,
    "-",
    null,
    null,
    null,
    null,
    "MIRTS314M",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8553,
    "VO IST 06/06/2021",
    0,
    0,
    "VO IST 06/06/2021",
    "FZEF",
    null,
    null,
    null,
    "KZ06052021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 06/06/2021",
    "VO IST 06/06/2021",
    null,
    0,
    0,
    "KZ06052021",
    null,
    29242.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8554,
    "VO IST 13/06/2021",
    0,
    0,
    "VO IST 13/06/2021",
    null,
    null,
    null,
    null,
    "KZ13062021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 13/06/2021",
    "VO IST 13/06/2021",
    null,
    0,
    0,
    "KZ13062021",
    null,
    22086.0,
    785.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8555,
    "VO IST 19/06/2021",
    0,
    0,
    "VO IST 19/06/2021",
    null,
    null,
    null,
    null,
    "voist19062021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 19/06/2021",
    "VO IST 19/06/2021",
    null,
    0,
    0,
    "voist19062021",
    null,
    20288.5,
    851.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8556,
    "TIPLY25K8|REGENCY TOURISM SERVICES",
    0,
    0,
    "REGENCY TOURISM SERVICES",
    null,
    null,
    "218912139516",
    null,
    "TIPLY25K8",
    null,
    null,
    0,
    2,
    1,
    "REGENCY TOURISM SERVICES",
    "REGENCY TOURISM SERVICES",
    null,
    0,
    0,
    null,
    "APTIP + 218912139516 - REGENCY TOURISM SERVICES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8557,
    "TUNTU000T| AGENCE TUNISAIR",
    0,
    0,
    " AGENCE TUNISAIR",
    null,
    null,
    "0021671754000362723",
    null,
    "TUNTU000T",
    null,
    null,
    0,
    2,
    0,
    " AGENCE TUNISAIR",
    " AGENCE TUNISAIR",
    null,
    0,
    0,
    null,
    "APTUN 00216 71 754000 EXT 3627/23 -  AGENCE TUNISAIR - A//0021652608601",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8558,
    "VO IST 26/06/2021",
    0,
    0,
    "VO IST 26/06/2021",
    "fe",
    null,
    null,
    null,
    "voist26062021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/06/2021",
    "VO IST 26/06/2021",
    null,
    0,
    0,
    "voist26062021",
    null,
    28396.3,
    885.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8559,
    "VO IST 23/06/2021",
    0,
    0,
    "VO IST 23/06/2021",
    null,
    null,
    null,
    null,
    "voist23062021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 23/06/2021",
    "VO IST 23/06/2021",
    null,
    0,
    0,
    "voist23062021",
    null,
    27587.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8560,
    "MIRTS314M|SAVANA TOURS",
    0,
    0,
    "SAVANA TOURS",
    null,
    null,
    "21673374666",
    null,
    "MIRTS314M",
    "MAZEN.EL.GUESMI@GMAIL.COM//M",
    null,
    0,
    2,
    1,
    "SAVANA TOURS",
    "SAVANA TOURS",
    null,
    0,
    0,
    null,
    "APMIR +216 73 374 666 - SAVANA TOURS - A//E-MAZEN.EL.GUESMI@GMAIL.COM//M-0021623451897",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8561,
    "VO IST 28/06/2021",
    0,
    0,
    "VO IST 28/06/2021",
    null,
    null,
    null,
    null,
    "VOIST2806",
    null,
    null,
    0,
    2,
    0,
    "VO IST 28/06/2021",
    "VO IST 28/06/2021",
    null,
    0,
    0,
    "VOIST2806",
    null,
    29505.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8562,
    "MIRTS314M|SAVANA TOURS",
    0,
    0,
    "SAVANA TOURS",
    null,
    null,
    "21673374666",
    null,
    "MIRTS314M",
    "MAZEN.EL.GUESMI@GMAIL.COM",
    null,
    0,
    2,
    1,
    "SAVANA TOURS",
    "SAVANA TOURS",
    null,
    0,
    0,
    null,
    "APMIR +216 73 374 666 - SAVANA TOURS - A//E-MAZEN.EL.GUESMI@GMAIL.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8563,
    "KOUNOUZ NICE ",
    1,
    0,
    "KOUNOUZ NICE ",
    null,
    null,
    null,
    null,
    "KZNICE001",
    "Kounouztravel06@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ NICE ",
    "KOUNOUZ NICE ",
    null,
    0,
    0,
    "KZNICE001",
    null,
    13175.56,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8564,
    "SAVANA TOURS",
    1,
    0,
    "SAVANA TOURS",
    null,
    null,
    "21673374666",
    null,
    "",
    "MAZEN.EL.GUESMI@GMAIL.COM",
    null,
    0,
    2,
    1,
    "SAVANA TOURS",
    "SAVANA TOURS",
    null,
    0,
    0,
    null,
    "APMIR +216 73 374 666 - SAVANA TOURS - A//E-MAZEN.EL.GUESMI@GMAIL.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8565,
    "VO AYT IST 01/07/2021",
    0,
    0,
    "VO AYT IST 01/07/2021",
    null,
    null,
    null,
    null,
    "VOAYT01072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 01/07/2021",
    "VO AYT IST 01/07/2021",
    null,
    0,
    0,
    "VOAYT01072021",
    null,
    11835.0,
    2279.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8566,
    "VO IST 03/07/2021",
    0,
    0,
    "VO IST 03/07/2021",
    "dfef",
    null,
    null,
    null,
    "voist03072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 03/07/2021",
    "VO IST 03/07/2021",
    null,
    0,
    0,
    "voist03072021",
    null,
    15431.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8567,
    "VO AYT TUN 01/07/2021",
    0,
    0,
    "VO AYT TUN 01/07/2021",
    null,
    null,
    null,
    null,
    "VOAYTTUN01072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT TUN 01/07/2021",
    "VO AYT TUN 01/07/2021",
    null,
    0,
    0,
    "VOAYTTUN01072021",
    null,
    15318.45,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8568,
    "VO IST 04/07/2021",
    0,
    0,
    "VO IST 04/07/2021",
    "FGRTH",
    null,
    null,
    null,
    "VOIST04072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 04/07/2021",
    "VO IST 04/07/2021",
    null,
    0,
    0,
    "VOIST04072021",
    null,
    14392.5,
    4632.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8569,
    "VO IST 30/06/2021",
    0,
    0,
    "VO IST 30/06/2021",
    "FEFEF",
    null,
    null,
    null,
    "VOIST30062021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 30/06/2021",
    "VO IST 30/06/2021",
    null,
    0,
    0,
    "VOIST30062021",
    null,
    6184.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8570,
    "VO IST 05/07/2021",
    0,
    0,
    "VO IST 05/07/2021",
    "dffg",
    null,
    null,
    null,
    "voist05072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 05/07/2021",
    "VO IST 05/07/2021",
    null,
    0,
    0,
    "voist05072021",
    null,
    15502.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8571,
    "VO AYT TUN 05/07/2021",
    0,
    0,
    "VO AYT TUN 05/07/2021",
    "ZFRGRG",
    null,
    null,
    null,
    "VOAYTTUN05072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT TUN 05/07/2021",
    "VO AYT TUN 05/07/2021",
    null,
    0,
    0,
    "VOAYTTUN05072021",
    null,
    20821.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8572,
    "VO IST 07/07/2021",
    0,
    0,
    "VO IST 07/07/2021",
    null,
    null,
    null,
    null,
    "voist07072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 07/07/2021",
    "VO IST 07/07/2021",
    null,
    0,
    0,
    "voist07072021",
    null,
    12357.5,
    4987.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8573,
    "PETIT VOYAGE",
    1,
    1,
    "PETIT VOYAGE",
    null,
    null,
    null,
    null,
    "kzpetit001",
    "direction.petivoyage@gmail.com",
    null,
    0,
    2,
    0,
    "PETIT VOYAGE",
    "PETIT VOYAGE",
    null,
    0,
    0,
    "kzpetit001",
    null,
    116544.89,
    112662.0,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    8574,
    "VO IST 08/07/2021",
    0,
    0,
    "VO IST 08/07/2021",
    "VG",
    null,
    null,
    null,
    "VOIST08072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 08/07/2021",
    "VO IST 08/07/2021",
    null,
    0,
    0,
    "VOIST08072021",
    null,
    15457.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8575,
    "VO IST 10/07/2021",
    0,
    0,
    "VO IST 10/07/2021",
    null,
    null,
    null,
    null,
    "VOIST10072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 10/07/2021",
    "VO IST 10/07/2021",
    null,
    0,
    0,
    "VOIST10072021",
    null,
    18416.5,
    6968.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8576,
    "JEUNESSE AFRIC",
    1,
    1,
    "JEUNESSE AFRIC",
    "SDFZG",
    null,
    null,
    null,
    "kzjeune001",
    "jeunesse.afric.voyages@gmail.com",
    null,
    0,
    2,
    0,
    "JEUNESSE AFRIC",
    "JEUNESSE AFRIC",
    null,
    0,
    0,
    "kzjeune001",
    null,
    48652.16,
    42702.5,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8577,
    "VO AYT IST 08/07/2021",
    0,
    0,
    "VO AYT IST 08/07/2021",
    null,
    null,
    null,
    null,
    "voaytist08072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 08/07/2021",
    "VO AYT IST 08/07/2021",
    null,
    0,
    0,
    "voaytist08072021",
    null,
    18638.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8578,
    "VO AYT IST 12/07/2021",
    0,
    0,
    "VO AYT IST 12/07/2021",
    null,
    null,
    null,
    null,
    "voaytist12072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 12/07/2021",
    "VO AYT IST 12/07/2021",
    null,
    0,
    0,
    "voaytist12072021",
    null,
    19615.5,
    5490.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8579,
    "VO AYT TUN 12/07/2021",
    0,
    0,
    "VO AYT TUN 12/07/2021",
    null,
    null,
    null,
    null,
    "voaytttun12072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT TUN 12/07/2021",
    "VO AYT TUN 12/07/2021",
    null,
    0,
    0,
    "voaytttun12072021",
    null,
    15337.5,
    987.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8580,
    "MARIO TRAVEL",
    1,
    0,
    "MARIO TRAVEL",
    null,
    null,
    null,
    null,
    "kzmar001",
    "resa.mariotravel@gmail.com",
    null,
    0,
    2,
    0,
    "MARIO TRAVEL",
    "MARIO TRAVEL",
    null,
    0,
    0,
    "kzmar001",
    null,
    8873.69,
    8830.8,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8581,
    "SAVANA TOURS",
    1,
    0,
    "SAVANA TOURS",
    null,
    null,
    "21673374666",
    null,
    "",
    "MAZEN.EL.GUESMI@GMAIL.COM",
    null,
    0,
    2,
    1,
    "SAVANA TOURS",
    "SAVANA TOURS",
    null,
    0,
    0,
    null,
    "APMIR +216 73 374 666 - SAVANA TOURS - A//E-MAZEN.EL.GUESMI@GMAIL.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8582,
    "VO IST 21/07/2021",
    0,
    0,
    "VO IST 21/07/2021",
    "bgvfyf",
    null,
    null,
    null,
    "voist21072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 21/07/2021",
    "VO IST 21/07/2021",
    null,
    0,
    0,
    "voist21072021",
    null,
    15532.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8583,
    "VO IST 24/07/2021",
    0,
    0,
    "VO IST 24/07/2021",
    null,
    null,
    null,
    null,
    "voist24072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 24/07/2021",
    "VO IST 24/07/2021",
    null,
    0,
    0,
    "voist24072021",
    null,
    15356.5,
    1997.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8584,
    "AIGLE ROYALE ",
    1,
    1,
    "AIGLE ROYALE ",
    null,
    "sahloul avenue yaser arafat",
    null,
    "73821448",
    "MIRTS3146",
    "DIRECTION@AIGLE-ROYAL.TN;BOOKING@AIGLE-ROYAL.T",
    null,
    0,
    2,
    0,
    "AIGLE ROYALE ",
    "AIGLE ROYALE ",
    null,
    0,
    0,
    null,
    null,
    21142.0,
    21166.6,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8585,
    "VO IST 25/07/2021",
    0,
    0,
    "VO IST 25/07/2021",
    null,
    null,
    null,
    null,
    "voist25072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 25/07/2021",
    "VO IST 25/07/2021",
    null,
    0,
    0,
    "voist25072021",
    null,
    15162.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8586,
    "VO IST 26/07/2021",
    0,
    0,
    "VO IST 26/07/2021",
    null,
    null,
    null,
    null,
    "voist26072021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/07/2021",
    "VO IST 26/07/2021",
    null,
    0,
    0,
    "voist26072021",
    null,
    14707.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8587,
    "VO AYT TUN 26/07/2021",
    0,
    0,
    "VO AYT TUN 26/07/2021",
    null,
    null,
    null,
    null,
    "voayttun26072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT TUN 26/07/2021",
    "VO AYT TUN 26/07/2021",
    null,
    0,
    0,
    "voayttun26072021",
    null,
    26530.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8588,
    "VO AYT TUN 29/07/2021",
    1,
    0,
    "VO AYT TUN 29/07/2021",
    null,
    null,
    null,
    null,
    "voayttun29072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT TUN 29/07/2021",
    "VO AYT TUN 29/07/2021",
    null,
    0,
    0,
    "voayttun29072021",
    null,
    26326.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8589,
    "VO AYT IST 29/07/2021",
    1,
    0,
    "VO AYT IST 29/07/2021",
    null,
    null,
    null,
    null,
    "voayt29072021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 29/07/2021",
    "VO AYT IST 29/07/2021",
    null,
    0,
    0,
    "voayt29072021",
    null,
    24980.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8590,
    "Al WAFED MABROUK 2 ",
    1,
    1,
    "Al WAFED MABROUK 2 ",
    "FDEF",
    null,
    null,
    null,
    "kz1125440",
    "al.wafd.el.moubarak@gmail.com",
    null,
    0,
    2,
    0,
    "Al WAFED MABROUK 2 ",
    "Al WAFED MABROUK 2 ",
    null,
    0,
    0,
    "KZ2511",
    null,
    28127.3,
    19860.2,
    0,
    0,
    15000.0,
    0,
    0,
  ],
  [
    8591,
    "TUNUG0580|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "TUNUG0580",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8592,
    "ECOLE BEN HLIMA",
    1,
    1,
    "ECOLE BEN HLIMA",
    null,
    "SFAX",
    null,
    null,
    "ECOLIST",
    "comptataysir@gmail.com",
    null,
    0,
    2,
    0,
    "ECOLE BEN HLIMA",
    "ECOLE BEN HLIMA",
    null,
    0,
    0,
    "ECOLIST",
    null,
    14275.5,
    14254.125,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8593,
    "BOGOTA TRAVEL",
    1,
    0,
    "BOGOTA TRAVEL",
    null,
    null,
    "21629567447",
    null,
    "",
    null,
    null,
    0,
    2,
    1,
    "BOGOTA TRAVEL",
    "BOGOTA TRAVEL",
    null,
    0,
    0,
    null,
    "APTUN +21629567447 - BOGOTA TRAVEL - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8594,
    "ASLEMA TRAVEL",
    1,
    1,
    "ASLEMA TRAVEL",
    null,
    "sousse",
    null,
    null,
    "ASLEMA",
    "comptataysir@gmail.com;mhadhbiasma7592@gmail.com",
    null,
    0,
    2,
    0,
    "ASLEMA TRAVEL",
    "ASLEMA TRAVEL",
    null,
    0,
    0,
    "ASLEMA",
    null,
    6005.78,
    6006.0,
    0,
    0,
    3000.0,
    0,
    0,
  ],
  [
    8595,
    "VO AYT TUN 02/08/2021",
    0,
    0,
    "VO AYT TUN 02/08/2021",
    null,
    null,
    null,
    null,
    "voist02082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT TUN 02/08/2021",
    "VO AYT TUN 02/08/2021",
    null,
    0,
    0,
    "voist02082021",
    null,
    35753.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8596,
    "VO IST 08/08/2021",
    0,
    0,
    "VO IST 08/08/2021",
    null,
    null,
    null,
    null,
    "VOIST08082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 08/08/2021",
    "VO IST 08/08/2021",
    null,
    0,
    0,
    "VOIST08082021",
    null,
    21568.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8597,
    "VO IST 09/08/2021",
    0,
    0,
    "VO IST 09/08/2021",
    null,
    null,
    null,
    null,
    "VOIST09082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 09/08/2021",
    "VO IST 09/08/2021",
    null,
    0,
    0,
    "VOIST09082021",
    null,
    20446.5,
    954.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8598,
    "VO IST 07/08/2021",
    0,
    0,
    "VO IST 07/08/2021",
    null,
    null,
    null,
    null,
    "VOIST07082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 07/08/2021",
    "VO IST 07/08/2021",
    null,
    0,
    0,
    "VOIST07082021",
    null,
    15067.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8599,
    "VO IST 05/08/2021",
    0,
    0,
    "VO IST 05/08/2021",
    null,
    null,
    null,
    null,
    "VOIST05082021",
    null,
    null,
    0,
    2,
    1,
    "VO IST 05/08/2021",
    "VO IST 05/08/2021",
    null,
    0,
    0,
    "VOIST05082021",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8600,
    "VO AYT TUN 05/08/2021",
    0,
    0,
    "VO AYT TUN 05/08/2021",
    null,
    null,
    null,
    null,
    "VOAYTIST05082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT TUN 05/08/2021",
    "VO AYT TUN 05/08/2021",
    null,
    0,
    0,
    "VOAYTIST05082021",
    null,
    56531.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8601,
    "VO IST 05/08/2021",
    0,
    0,
    "VO IST 05/08/2021",
    null,
    null,
    null,
    null,
    "VOIST05082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 05/08/2021",
    "VO IST 05/08/2021",
    null,
    0,
    0,
    "VOIST05082021",
    null,
    10556.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8602,
    "VO IST 04/08/2021",
    0,
    0,
    "VO IST 04/08/2021",
    null,
    null,
    null,
    null,
    "VOIST04082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 04/08/2021",
    "VO IST 04/08/2021",
    null,
    0,
    0,
    "VOIST04082021",
    null,
    22984.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8603,
    "VO AYT IST 12/08/2021",
    0,
    0,
    "VO AYT IST 12/08/2021",
    null,
    null,
    null,
    null,
    "VOAYTIST12082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 12/08/2021",
    "VO AYT IST 12/08/2021",
    null,
    0,
    0,
    "VOAYTIST12082021",
    null,
    18137.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8604,
    "VO AYTTUN 09/08/2021",
    0,
    0,
    "VO AYTTUN 09/08/2021",
    null,
    null,
    null,
    null,
    "VOAYTTUN09082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYTTUN 09/08/2021",
    "VO AYTTUN 09/08/2021",
    null,
    0,
    0,
    "VOAYTTUN09082021",
    null,
    18382.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8605,
    "VO IST 11/08/2021",
    0,
    0,
    "VO IST 11/08/2021",
    null,
    null,
    null,
    null,
    "vost11082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 11/08/2021",
    "VO IST 11/08/2021",
    null,
    0,
    0,
    "vost11082021",
    null,
    29989.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8606,
    "VO IST 12/08/2021",
    0,
    0,
    "VO IST 12/08/2021",
    "FEFG",
    null,
    null,
    null,
    "VOIST12082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 12/08/2021",
    "VO IST 12/08/2021",
    null,
    0,
    0,
    "VOIST12082021",
    null,
    18800.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8607,
    "VO AYT 12/08/2021",
    0,
    0,
    "VO AYT 12/08/2021",
    null,
    null,
    null,
    null,
    "VOAYT12082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT 12/08/2021",
    "VO AYT 12/08/2021",
    null,
    0,
    0,
    "VOAYT12082021",
    null,
    36289.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8608,
    "VO AYT 16/08/2021",
    0,
    0,
    "VO AYT 16/08/2021",
    null,
    null,
    null,
    null,
    "VOAYT16082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT 16/08/2021",
    "VO AYT 16/08/2021",
    null,
    0,
    0,
    "VOAYT16082021",
    null,
    54415.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8609,
    "VO IST 18/08/2021",
    0,
    0,
    "VO IST 18/08/2021",
    null,
    null,
    null,
    null,
    "VOIST18082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 18/08/2021",
    "VO IST 18/08/2021",
    null,
    0,
    0,
    "VOIST18082021",
    null,
    21726.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8610,
    "VO ADB IST 19/08/2021",
    0,
    0,
    "VO ADB IST 19/08/2021",
    null,
    null,
    null,
    null,
    "VOADBIST19082021",
    null,
    null,
    0,
    2,
    0,
    "VO ADB IST 19/08/2021",
    "VO ADB IST 19/08/2021",
    null,
    0,
    0,
    "VOADBIST19082021",
    null,
    65492.0,
    62474.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8611,
    "VO AYT IST 19/08/2021",
    0,
    0,
    "VO AYT IST 19/08/2021",
    null,
    null,
    null,
    null,
    "VOAYTIST19082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 19/08/2021",
    "VO AYT IST 19/08/2021",
    null,
    0,
    0,
    "VOAYTIST19082021",
    null,
    18153.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8612,
    "VO IST 14/08/2021",
    0,
    0,
    "VO IST 14/08/2021",
    null,
    null,
    null,
    null,
    "VOIST14082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 14/08/2021",
    "VO IST 14/08/2021",
    null,
    0,
    0,
    "VOIST14082021",
    null,
    13435.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8613,
    "VO IST 22/08/2021",
    0,
    0,
    "VO IST 22/08/2021",
    null,
    null,
    null,
    null,
    "voist22082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 22/08/2021",
    "VO IST 22/08/2021",
    null,
    0,
    0,
    "voist22082021",
    null,
    29052.5,
    7512.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8614,
    "MIRTS3108|OK VOYAGES",
    0,
    0,
    "OK VOYAGES",
    null,
    null,
    "21673200030",
    null,
    "MIRTS3108",
    null,
    null,
    0,
    2,
    1,
    "OK VOYAGES",
    "OK VOYAGES",
    null,
    0,
    0,
    null,
    "APMIR +216 73 200 030 - OK VOYAGES - A//95031501",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8615,
    "VO IST 21/08/2021",
    0,
    0,
    "VO IST 21/08/2021",
    null,
    null,
    null,
    null,
    "voist21082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 21/08/2021",
    "VO IST 21/08/2021",
    null,
    0,
    0,
    "voist21082021",
    null,
    33153.0,
    3003.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8616,
    "VO AYT IST 23/08/2021",
    0,
    0,
    "VO AYT IST 23/08/2021",
    null,
    null,
    null,
    null,
    "voaytist23082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 23/08/2021",
    "VO AYT IST 23/08/2021",
    null,
    0,
    0,
    "voaytist23082021",
    null,
    17820.5,
    4408.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8617,
    "VO AYT  23/08/2021",
    0,
    0,
    "VO AYT  23/08/2021",
    null,
    null,
    null,
    null,
    "voayt23082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT  23/08/2021",
    "VO AYT  23/08/2021",
    null,
    0,
    0,
    "voayt23082021",
    null,
    32131.0,
    1119.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8618,
    "VO AYT IST 26/08/2021",
    0,
    0,
    "VO AYT IST 26/08/2021",
    null,
    null,
    null,
    null,
    "VOAYTIST26082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 26/08/2021",
    "VO AYT IST 26/08/2021",
    null,
    0,
    0,
    "VOAYTIST26082021",
    null,
    17803.5,
    2371.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8619,
    "VO AYT 26/08/2021",
    0,
    0,
    "VO AYT 26/08/2021",
    null,
    null,
    null,
    null,
    "voayt26082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT 26/08/2021",
    "VO AYT 26/08/2021",
    null,
    0,
    0,
    "voayt26082021",
    null,
    35760.0,
    2239.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8620,
    "VO AYT 28/08/2021",
    0,
    0,
    "VO AYT 28/08/2021",
    null,
    null,
    null,
    null,
    "VOAYT28082021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT 28/08/2021",
    "VO AYT 28/08/2021",
    null,
    0,
    0,
    "VOAYT28082021",
    null,
    19984.0,
    6891.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8621,
    "VO IST 28/08/2021",
    0,
    0,
    "VO IST 28/08/2021",
    null,
    null,
    null,
    null,
    "VOIST28082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 28/08/2021",
    "VO IST 28/08/2021",
    null,
    0,
    0,
    "VOIST28082021",
    null,
    16552.0,
    1001.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8622,
    "VO IST 30/08/2021",
    0,
    0,
    "VO IST 30/08/2021",
    null,
    null,
    null,
    null,
    "voist30082021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 30/08/2021",
    "VO IST 30/08/2021",
    null,
    0,
    0,
    "voist30082021",
    null,
    12863.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8623,
    "TIPLY25B9|QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    0,
    0,
    "QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    null,
    null,
    "2180213335536",
    null,
    "",
    "TKT.QA@HOTMAIL.COM",
    null,
    0,
    2,
    1,
    "QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    "QMIT ALALEM TRAVEL AND TOURIST SERVICE",
    null,
    0,
    0,
    null,
    "APTIP +218/021 3335536-0913223336 - QMIT ALALEM TRAVEL AND TOURIST SERVICE - A//E-TKT.QA@HOTMAIL.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8624,
    "VO AYT IST 02/09/2021",
    0,
    0,
    "VO AYT IST 02/09/2021",
    null,
    null,
    null,
    null,
    "VOAYTIST02092021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 02/09/2021",
    "VO AYT IST 02/09/2021",
    null,
    0,
    0,
    "VOAYTIST02092021",
    null,
    23057.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8625,
    "VO AYT 02/09/2021",
    0,
    0,
    "VO AYT 02/09/2021",
    null,
    null,
    null,
    null,
    "VOAYT02092021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT 02/09/2021",
    "VO AYT 02/09/2021",
    null,
    0,
    0,
    "VOAYT02092021",
    null,
    31964.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8626,
    "MIRTS314K|DHEYAFAH DE TOURISME",
    0,
    0,
    "DHEYAFAH DE TOURISME",
    null,
    null,
    "21673217244",
    null,
    "MIRTS314K",
    null,
    null,
    0,
    2,
    1,
    "DHEYAFAH DE TOURISME",
    "DHEYAFAH DE TOURISME",
    null,
    0,
    0,
    null,
    "APMIR +21673217244 - DHEYAFAH DE TOURISME - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8627,
    "VO IST 06/09/2021",
    0,
    0,
    "VO IST 06/09/2021",
    null,
    null,
    null,
    null,
    "VOIST06092021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 06/09/2021",
    "VO IST 06/09/2021",
    null,
    0,
    0,
    "VOIST06092021",
    null,
    19810.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8628,
    "VO IST 05/09/2021",
    0,
    0,
    "VO IST 05/09/2021",
    null,
    null,
    null,
    null,
    "VOIST05092021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 05/09/2021",
    "VO IST 05/09/2021",
    null,
    0,
    0,
    "VOIST05092021",
    null,
    9910.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8629,
    "VO AYT IST 06/09/2021",
    0,
    0,
    "VO AYT IST 06/09/2021",
    null,
    null,
    null,
    null,
    "VOAYTIST06092021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 06/09/2021",
    "VO AYT IST 06/09/2021",
    null,
    0,
    0,
    "VOAYTIST06092021",
    null,
    72015.0,
    1143.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8630,
    "MIRTS3157|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "MIRTS3157",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8631,
    "VO AYT IST 09/09/2021",
    0,
    0,
    "VO AYT IST 09/09/2021",
    null,
    null,
    null,
    null,
    "voaytist09092021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT IST 09/09/2021",
    "VO AYT IST 09/09/2021",
    null,
    0,
    0,
    "voaytist09092021",
    null,
    19573.0,
    5942.5,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8632,
    "VO AYT 09/09/2021",
    0,
    0,
    "VO AYT 09/09/2021",
    null,
    null,
    null,
    null,
    "VOAYT09092021",
    null,
    null,
    0,
    2,
    0,
    "VO AYT 09/09/2021",
    "VO AYT 09/09/2021",
    null,
    0,
    0,
    "VOAYT09092021",
    null,
    33263.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8633,
    "QZTLY26E4|TURINO TOURS",
    0,
    0,
    "TURINO TOURS",
    null,
    null,
    "218917780908",
    null,
    "QZTLY26E4",
    null,
    null,
    0,
    2,
    1,
    "TURINO TOURS",
    "TURINO TOURS",
    null,
    0,
    0,
    null,
    "APQZT + 218917780908 - TURINO TOURS - A//0913582219",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8634,
    "MIRTS3108|OK VOYAGES",
    0,
    0,
    "OK VOYAGES",
    null,
    null,
    "21673200030",
    null,
    "MIRTS3108",
    null,
    null,
    0,
    2,
    1,
    "OK VOYAGES",
    "OK VOYAGES",
    null,
    0,
    0,
    null,
    "APMIR +216 73 200 030 - OK VOYAGES - A//95031501",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8635,
    "MIRTS315H|TASNIME VOYAGES TOURISME",
    0,
    0,
    "TASNIME VOYAGES TOURISME",
    null,
    null,
    "21673270542",
    null,
    "",
    "AGENCE.TUNIS@TASNIMEVOYAGES.COM",
    null,
    0,
    2,
    1,
    "TASNIME VOYAGES TOURISME",
    "TASNIME VOYAGES TOURISME",
    null,
    0,
    0,
    null,
    "APMIR +21673270542 - TASNIME VOYAGES TOURISME - A//E-AGENCE.TUNIS@TASNIMEVOYAGES.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8636,
    "MIRTS315H|TASNIME VOYAGES TOURISME",
    0,
    0,
    "TASNIME VOYAGES TOURISME",
    null,
    null,
    "21673270542",
    null,
    "",
    "AGENCE.TUNIS@TASNIMEVOYAGES.COM",
    null,
    0,
    2,
    1,
    "TASNIME VOYAGES TOURISME",
    "TASNIME VOYAGES TOURISME",
    null,
    0,
    0,
    null,
    "APMIR +21673270542 - TASNIME VOYAGES TOURISME - A//E-AGENCE.TUNIS@TASNIMEVOYAGES.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8637,
    "VO IST 25/09/2021",
    0,
    0,
    "VO IST 25/09/2021",
    null,
    null,
    null,
    null,
    "voist25092021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 25/09/2021",
    "VO IST 25/09/2021",
    null,
    0,
    0,
    "voist25092021",
    null,
    13920.0,
    893.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8638,
    "TUNTS31EJ|TASNIME VOYAGES TOURISME",
    0,
    0,
    "TASNIME VOYAGES TOURISME",
    null,
    null,
    "21673270542",
    null,
    "",
    "AGENCE.TUNIS@TASNIMEVOYAGES.COM",
    null,
    0,
    2,
    1,
    "TASNIME VOYAGES TOURISME",
    "TASNIME VOYAGES TOURISME",
    null,
    0,
    0,
    null,
    "APMIR +21673270542 - TASNIME VOYAGES TOURISME - A//E-AGENCE.TUNIS@TASNIMEVOYAGES.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8639,
    "MIRTS3108|OK VOYAGES",
    0,
    0,
    "OK VOYAGES",
    null,
    null,
    "21673200030",
    null,
    "MIRTS3108",
    null,
    null,
    0,
    2,
    1,
    "OK VOYAGES",
    "OK VOYAGES",
    null,
    0,
    0,
    null,
    "APMIR +216 73 200 030 - OK VOYAGES - A//23262700",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8640,
    "GAFTS3100|TASNIME VOYAGES TOURISME",
    0,
    0,
    "TASNIME VOYAGES TOURISME",
    null,
    null,
    null,
    null,
    "GAFTS3100",
    null,
    null,
    0,
    2,
    1,
    "TASNIME VOYAGES TOURISME",
    "TASNIME VOYAGES TOURISME",
    null,
    0,
    0,
    null,
    "AP-0021690271784//MIR +21673270542 - TASNIME VOYAGES TOURISME - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8641,
    "GAFTS3100|WISSEM VOYAGES",
    0,
    0,
    "WISSEM VOYAGES",
    null,
    null,
    "21677414016",
    null,
    "",
    null,
    null,
    0,
    2,
    1,
    "WISSEM VOYAGES",
    "WISSEM VOYAGES",
    null,
    0,
    0,
    null,
    "APGAF +216 77 414 016 - WISSEM VOYAGES - A//55979380",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8642,
    "VO IST 09/10/2021",
    0,
    0,
    "VO IST 09/10/2021",
    null,
    null,
    null,
    null,
    "VOIST09102021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 09/10/2021",
    "VO IST 09/10/2021",
    null,
    0,
    0,
    "VOIST09102021",
    null,
    15660.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8643,
    "VO IST 16/10/2021",
    0,
    0,
    "VO IST 16/10/2021",
    null,
    null,
    null,
    null,
    "VOIST16102021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 16/10/2021",
    "VO IST 16/10/2021",
    null,
    0,
    0,
    "VOIST16102021",
    null,
    13821.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8644,
    "VO IST 23/10/2021",
    0,
    0,
    "VO IST 23/10/2021",
    null,
    null,
    null,
    null,
    "VOIST23102021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 23/10/2021",
    "VO IST 23/10/2021",
    null,
    0,
    0,
    "VOIST23102021",
    null,
    28830.0,
    1790.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8645,
    "VO IST 30/10/2021",
    0,
    0,
    "VO IST 30/10/2021",
    null,
    null,
    null,
    null,
    "voist30102021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 30/10/2021",
    "VO IST 30/10/2021",
    null,
    0,
    0,
    "voist30102021",
    null,
    35563.0,
    1970.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8646,
    "VO IST 31/10/2021",
    0,
    0,
    "VO IST 31/10/2021",
    null,
    null,
    null,
    null,
    "voist31102021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 31/10/2021",
    "VO IST 31/10/2021",
    null,
    0,
    0,
    "voist31102021",
    null,
    30488.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8647,
    "VO IST 01/11/2021",
    0,
    0,
    "VO IST 01/11/2021",
    null,
    null,
    null,
    null,
    "voist01112021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 01/11/2021",
    "VO IST 01/11/2021",
    null,
    0,
    0,
    "voist01112021",
    null,
    30346.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8648,
    "VO IST 02/11/2021",
    0,
    0,
    "VO IST 02/11/2021",
    null,
    null,
    null,
    null,
    "VO02112021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 02/11/2021",
    "VO IST 02/11/2021",
    null,
    0,
    0,
    "VO02112021",
    null,
    29131.0,
    2152.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8649,
    "MIRTS3106|EVASION VOYAGES SERVICES",
    0,
    0,
    "EVASION VOYAGES SERVICES",
    null,
    null,
    "21673494410",
    null,
    "MIRTS3106",
    null,
    null,
    0,
    2,
    1,
    "EVASION VOYAGES SERVICES",
    "EVASION VOYAGES SERVICES",
    null,
    0,
    0,
    null,
    "APMIR 216 73 494 410 - EVASION VOYAGES SERVICES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8650,
    "VO IST NAV  03/11/2021",
    0,
    0,
    "VO IST NAV  03/11/2021",
    null,
    null,
    null,
    null,
    "VOISTNAV03112021",
    null,
    null,
    0,
    2,
    0,
    "VO IST NAV  03/11/2021",
    "VO IST NAV  03/11/2021",
    null,
    0,
    0,
    "VOISTNAV03112021",
    null,
    43273.0,
    1082.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8651,
    "HOTRESA ",
    1,
    0,
    "HOTRESA ",
    "DFVREFG",
    null,
    null,
    null,
    "kz45457878",
    "HOTRESACONTACT@GMAIL.COM",
    null,
    0,
    2,
    0,
    "HOTRESA ",
    "HOTRESA ",
    null,
    0,
    0,
    "kz45457878",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8652,
    "MEILLEURS VOYAGES",
    1,
    0,
    "MEILLEURS VOYAGES",
    null,
    null,
    null,
    null,
    "KZ4220",
    "sales@meilleursvoyages.t",
    null,
    0,
    2,
    0,
    "MEILLEURS VOYAGES",
    "MEILLEURS VOYAGES",
    null,
    0,
    0,
    "KZ4220",
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8653,
    "VO IST 13/11/2021",
    0,
    0,
    "VO IST 13/11/2021",
    null,
    null,
    null,
    null,
    "voist13112021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 13/11/2021",
    "VO IST 13/11/2021",
    null,
    0,
    0,
    "voist13112021",
    null,
    13479.0,
    1534.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8654,
    "ASPIS TRAVEL ",
    1,
    0,
    "ASPIS TRAVEL ",
    null,
    "KLIBIA ",
    "29309300",
    null,
    "ASPIS",
    " ASPISTRAVELSERVICES@GMAIL.COM",
    null,
    0,
    2,
    0,
    "ASPIS TRAVEL ",
    "ASPIS TRAVEL ",
    null,
    0,
    0,
    "ASPIS ",
    null,
    6820.0,
    6822.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8655,
    "VO IST TZX  20/11/2021",
    0,
    0,
    "VO IST TZX  20/11/2021",
    null,
    null,
    null,
    null,
    "VOISTTXZ20112021",
    null,
    null,
    0,
    2,
    0,
    "VO IST TZX  20/11/2021",
    "VO IST TZX  20/11/2021",
    null,
    0,
    0,
    "VOISTTXZ20112021",
    null,
    26611.0,
    7854.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8656,
    "VO IST 22/11/2021",
    0,
    0,
    "VO IST 22/11/2021",
    null,
    null,
    null,
    null,
    "VOIST22112021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 22/11/2021",
    "VO IST 22/11/2021",
    null,
    0,
    0,
    "VOIST22112021",
    null,
    15308.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8657,
    "VO IST 27/11/2021",
    0,
    0,
    "VO IST 27/11/2021",
    null,
    null,
    null,
    null,
    "voist27112021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 27/11/2021",
    "VO IST 27/11/2021",
    null,
    0,
    0,
    "voist27112021",
    null,
    18515.0,
    770.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8658,
    "KOUNOUZ KASSERINE",
    1,
    1,
    "KOUNOUZ KASSERINE",
    null,
    null,
    null,
    null,
    "dds",
    "kounouz.kasserine@gmail.com",
    null,
    0,
    2,
    0,
    "KOUNOUZ KASSERINE",
    "KOUNOUZ KASSERINE",
    null,
    0,
    0,
    "ds;KZGASR001",
    null,
    18211.35,
    13900.0,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    8659,
    "VO IST 04/12/2021 TURKICH/TUNISAIR",
    0,
    0,
    "VO IST 04/12/2021 TURKICH/TUNISAIR",
    null,
    null,
    null,
    null,
    "voist04122021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 04/12/2021 TURKICH/TUNISAIR",
    "VO IST 04/12/2021 TURKICH/TUNISAIR",
    null,
    0,
    0,
    "voist04122021",
    null,
    18036.0,
    3860.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8660,
    "DJETU0201|TUNISAIR AVENUE HABIB BOURGUIBA",
    0,
    0,
    "TUNISAIR AVENUE HABIB BOURGUIBA",
    null,
    null,
    "0021675650159",
    null,
    "DJETU0201",
    null,
    null,
    0,
    2,
    1,
    "TUNISAIR AVENUE HABIB BOURGUIBA",
    "TUNISAIR AVENUE HABIB BOURGUIBA",
    null,
    0,
    0,
    null,
    "APDJE 00216 75 650159 - TUNISAIR AVENUE HABIB BOURGUIBA - A//TUN00216 29848370",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8661,
    "ROYAUME VOYAGE ",
    1,
    1,
    "ROYAUME VOYAGE ",
    null,
    null,
    null,
    null,
    ";KZROY1220;TUNTS3136",
    "rtvt.voyage@gmail.com",
    null,
    0,
    2,
    0,
    "ROYAUME VOYAGE ",
    "ROYAUME VOYAGE ",
    null,
    0,
    0,
    "KZROY1220",
    null,
    22199.5,
    18922.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8662,
    "CAIEG22S7|SOHBA TOURS",
    0,
    0,
    "SOHBA TOURS",
    null,
    null,
    "202257444253770",
    null,
    "CAIEG22S7",
    null,
    null,
    0,
    2,
    1,
    "SOHBA TOURS",
    "SOHBA TOURS",
    null,
    0,
    0,
    null,
    "APCAI +20225744425 / 37 / 70 - SOHBA TOURS - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8663,
    "AYA TRAVEL ",
    1,
    1,
    "AYA TRAVEL ",
    null,
    "RUE DE JAPON MONTPLAISIR ",
    "23505000",
    "71904416",
    "TUNTS313X",
    "IMEN@AYATRAVEL.T",
    null,
    0,
    2,
    0,
    "AYA TRAVEL ",
    "AYA TRAVEL ",
    null,
    0,
    0,
    null,
    null,
    11965.5,
    11818.1,
    0,
    0,
    0,
    0,
    0,
    1,
  ],
  [
    8664,
    "CLICNGO",
    1,
    0,
    "CLICNGO",
    "dfdfgd",
    null,
    null,
    null,
    "clic",
    "hdm.tn@clicngo.biz",
    null,
    0,
    2,
    0,
    "CLICNGO",
    "CLICNGO",
    null,
    0,
    0,
    "KZCLIC002",
    null,
    47595.82,
    34696.51,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8665,
    "18 dec tunisiar ",
    1,
    0,
    "18 dec tunisiar ",
    null,
    "RUE DE JAPON MONTPLAISIR ",
    "58313916",
    null,
    "dec18",
    null,
    null,
    0,
    2,
    1,
    "18 dec tunisiar ",
    "18 dec tunisiar ",
    null,
    0,
    0,
    "dec18",
    null,
    -1025.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8666,
    "VO IST 18/12/2021 TUNISAIR ",
    0,
    0,
    "VO IST 18/12/2021 TUNISAIR ",
    "HYYH5",
    null,
    null,
    null,
    "VOIST18/12/2021TUNISIAR ",
    null,
    null,
    0,
    2,
    0,
    "VO IST 18/12/2021 TUNISAIR ",
    "VO IST 18/12/2021 TUNISAIR ",
    null,
    0,
    0,
    "VOIST18/12/2021TUNISIAR ",
    null,
    25178.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8667,
    "VO IST 11/12/2021",
    0,
    0,
    "VO IST 11/12/2021",
    null,
    null,
    null,
    null,
    "VOIST11122021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 11/12/2021",
    "VO IST 11/12/2021",
    null,
    0,
    0,
    "VOIST11122021",
    null,
    16720.5,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8668,
    "VO IST 18/12/2021",
    0,
    0,
    "VO IST 18/12/2021",
    null,
    null,
    null,
    null,
    "VOIST18122021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 18/12/2021",
    "VO IST 18/12/2021",
    null,
    0,
    0,
    "VOIST18122021",
    null,
    32109.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8669,
    "VO IST 19/12/2021",
    0,
    0,
    "VO IST 19/12/2021",
    null,
    null,
    null,
    null,
    "VOIST19122021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 19/12/2021",
    "VO IST 19/12/2021",
    null,
    0,
    0,
    "VOIST19122021",
    null,
    32079.0,
    1710.0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8670,
    "CARTHAGE TRAVEL & EVENTS",
    1,
    0,
    "CARTHAGE TRAVEL & EVENTS",
    null,
    null,
    "21672266385",
    null,
    "TUNTS3185",
    ";compta3@carthagetravel.com.t",
    null,
    0,
    2,
    0,
    "CARTHAGE TRAVEL & EVENTS",
    "CARTHAGE TRAVEL & EVENTS",
    null,
    0,
    0,
    null,
    "APTUN (216)72266385 - CARTHAGE TRAVEL & EVENTS - A",
    74536.6,
    34458.8,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8671,
    "TUNTU0253|SERVICE GROUPES",
    0,
    0,
    "SERVICE GROUPES",
    null,
    null,
    "",
    null,
    "TUNTU0253",
    null,
    null,
    0,
    2,
    0,
    "SERVICE GROUPES",
    "SERVICE GROUPES",
    null,
    0,
    0,
    null,
    "APTUN TBA - SERVICE GROUPES - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8672,
    "NEJMA VOYAGE",
    1,
    1,
    "NEJMA VOYAGE",
    "DFGF",
    null,
    null,
    null,
    "Mirts3114",
    "nejmavoyages@hotmail.com",
    null,
    0,
    2,
    1,
    "NEJMA VOYAGE",
    "NEJMA VOYAGE",
    null,
    0,
    0,
    "",
    null,
    0,
    0,
    0,
    0,
    20000.0,
    0,
    0,
  ],
  [
    8673,
    "MIRTS311Z|",
    0,
    0,
    "-",
    null,
    null,
    null,
    null,
    "MIRTS311Z",
    null,
    null,
    0,
    2,
    1,
    null,
    "-",
    null,
    0,
    0,
    null,
    null,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8674,
    "NEJMA VOYAGES",
    1,
    1,
    "NEJMA VOYAGES",
    null,
    null,
    "21673477682",
    null,
    "MIRTS3114",
    null,
    null,
    0,
    2,
    0,
    "NEJMA VOYAGES",
    "NEJMA VOYAGES",
    null,
    0,
    0,
    "KZNEJMA002",
    "APMIR (216)73477682 - NEJMA VOYAGES - A//58632920-MAHMOUD",
    9433.12,
    8506.1,
    0,
    0,
    10000.0,
    0,
    0,
  ],
  [
    8675,
    "VO IST 26/12/2021",
    0,
    0,
    "VO IST 26/12/2021",
    null,
    null,
    null,
    null,
    "voist26122021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 26/12/2021",
    "VO IST 26/12/2021",
    null,
    0,
    0,
    "voist26122021",
    null,
    34505.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8676,
    "VO IST 27/12/2021",
    0,
    0,
    "VO IST 27/12/2021",
    null,
    null,
    null,
    null,
    "VOIST27122021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 27/12/2021",
    "VO IST 27/12/2021",
    null,
    0,
    0,
    "VOIST27122021",
    null,
    34852.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8677,
    "VO IST 25/12/2021",
    0,
    0,
    "VO IST 25/12/2021",
    null,
    null,
    null,
    null,
    "VOIST251222021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 25/12/2021",
    "VO IST 25/12/2021",
    null,
    0,
    0,
    "VOIST251222021",
    null,
    33370.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8678,
    "VO IST 20/12/2021 TUNISAIR",
    0,
    0,
    "VO IST 20/12/2021 TUNISAIR",
    null,
    null,
    null,
    null,
    "VO20122021",
    null,
    null,
    0,
    2,
    0,
    "VO IST 20/12/2021 TUNISAIR",
    "VO IST 20/12/2021 TUNISAIR",
    null,
    0,
    0,
    "VO20122021",
    null,
    22948.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8679,
    "VO IST 24/12/2021 TUNISAIR",
    0,
    0,
    "VO IST 24/12/2021 TUNISAIR",
    null,
    null,
    null,
    null,
    "VOIST24122021TUNISAIR",
    null,
    null,
    0,
    2,
    0,
    "VO IST 24/12/2021 TUNISAIR",
    "VO IST 24/12/2021 TUNISAIR",
    null,
    0,
    0,
    "VOIST24122021TUNISAIR",
    null,
    23210.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8680,
    "LTINERANCE VOYAGE",
    1,
    1,
    "LTINERANCE VOYAGE",
    null,
    null,
    "majda ben amor bziouech",
    null,
    "KZ0158LTI",
    "sassimejda07@gmail.com",
    null,
    0,
    2,
    0,
    "LTINERANCE VOYAGE",
    "LTINERANCE VOYAGE",
    null,
    0,
    0,
    "KZ0158LTI",
    null,
    1087.32,
    1085.0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8681,
    "TIBA VOYAGES",
    1,
    0,
    "TIBA VOYAGES",
    null,
    null,
    "21670731747",
    null,
    "TUNTS311P",
    "VOYAGETIBA@GMAIL.COM",
    null,
    0,
    2,
    1,
    "TIBA VOYAGES",
    "TIBA VOYAGES",
    null,
    0,
    0,
    null,
    "APTUN (216)70731747 - TIBA VOYAGES - A//E-VOYAGETIBA@GMAIL.COM",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8682,
    "NOUSSA TRAVEL",
    1,
    1,
    "NOUSSA TRAVEL",
    null,
    null,
    null,
    null,
    "KZNOUSSA01",
    "ines.saaf@gmail.com",
    null,
    0,
    2,
    0,
    "NOUSSA TRAVEL",
    "NOUSSA TRAVEL",
    null,
    0,
    0,
    "KZNOUSSA01",
    null,
    15005.17,
    5400.0,
    0,
    0,
    6000.0,
    0,
    0,
  ],
  [
    8683,
    "VO IST 02/01/2022",
    0,
    0,
    "VO IST 02/01/2022",
    null,
    null,
    null,
    null,
    "VOIST02012022",
    null,
    null,
    0,
    2,
    0,
    "VO IST 02/01/2022",
    "VO IST 02/01/2022",
    null,
    0,
    0,
    "VOIST02012022",
    null,
    17626.0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8684,
    "ALL TIME TOURS",
    1,
    1,
    "ALL TIME TOURS",
    "DVFDG",
    null,
    null,
    null,
    "KZALLT0010",
    "contact@alltimetours.com",
    null,
    0,
    2,
    0,
    "ALL TIME TOURS",
    "ALL TIME TOURS",
    null,
    0,
    0,
    "KZALLT0010",
    null,
    930.46,
    0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8685,
    " DESIRS DE REVE",
    1,
    1,
    " DESIRS DE REVE",
    null,
    "AVE H.BOURGUIBA KSAR HELLAL",
    "21626647570",
    "73452260",
    "MIRTS315D",
    "DESIRSDEREVE@GMAIL.COM;kounouzmonastir@gmail.com ",
    null,
    0,
    2,
    0,
    " DESIRS DE REVE",
    " DESIRS DE REVE",
    null,
    0,
    0,
    null,
    null,
    4392.5,
    5000.0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8686,
    "MOMENTO TRAVEL ",
    1,
    1,
    "MOMENTO TRAVEL ",
    "FGZER",
    null,
    null,
    null,
    "KZMOMENTO001",
    "commercial@momentobooking.com",
    null,
    0,
    2,
    0,
    "MOMENTO TRAVEL ",
    "MOMENTO TRAVEL ",
    null,
    0,
    0,
    "KZMOMENTO001",
    null,
    0,
    0,
    0,
    0,
    5000.0,
    0,
    0,
  ],
  [
    8687,
    "AFWAJ TRAVEL ",
    1,
    1,
    "AFWAJ TRAVEL ",
    null,
    null,
    null,
    null,
    "MIRTS311Z",
    null,
    null,
    0,
    2,
    0,
    "AFWAJ TRAVEL ",
    "AFWAJ TRAVEL ",
    null,
    0,
    0,
    null,
    null,
    12658.0,
    0,
    0,
    0,
    25000.0,
    0,
    0,
  ],
  [
    8688,
    "TUNTS2170|SKYLINE TRAVEL",
    0,
    0,
    "SKYLINE TRAVEL",
    null,
    null,
    "21671703890",
    null,
    "TUNTS2170",
    null,
    null,
    0,
    2,
    1,
    "SKYLINE TRAVEL",
    "SKYLINE TRAVEL",
    null,
    0,
    0,
    null,
    "APTUN (216)71 703890 - SKYLINE TRAVEL - A",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8689,
    "MAWAZINE TRAVEL",
    1,
    0,
    "MAWAZINE TRAVEL",
    "1638831G A M 000",
    null,
    null,
    null,
    "KZMT0264",
    null,
    null,
    0,
    2,
    0,
    "MAWAZINE TRAVEL",
    "MAWAZINE TRAVEL",
    null,
    0,
    0,
    "KZMT0264",
    null,
    1327.04,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
  [
    8690,
    "VO IST 27/12/2021 TUNISAIR",
    0,
    0,
    "VO IST 27/12/2021 TUNISAIR",
    null,
    null,
    null,
    null,
    "VO IST 27/12/2021 TUNISAIR",
    null,
    null,
    0,
    2,
    0,
    "VO IST 27/12/2021 TUNISAIR",
    "VO IST 27/12/2021 TUNISAIR",
    null,
    0,
    0,
    "VO IST 27/12/2021 TUNISAIR",
    null,
    20500.0,
    0,
    0,
    0,
    0,
    0,
    0,
    1,
  ],
];
console.log(clients.length);
