export class AgencyInvoiceModel {
  address;
  name;
  userName; // from local storage
  city;
  MF;
  phone;
  fax;
  pincode;
  logo; // file
}
